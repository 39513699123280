import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLibraryStore } from 'store/library';

const customStyle = { display: 'flex', gap: '.5em' };

export default function DynamicContentDetails({ config }) {
    const values = config?.config?.content;
    const defaults = config?.params?.[0]?.default;
    const getChildren = useLibraryStore(state => state.getChildren);
    const [path, setPath] = useState('');

    useEffect(() => {
        (async () =>
            await getChildren(values?.[defaults?.[0]?.field], ['folder']).then(
                res =>
                    setPath(
                        res?.data?.data?.hierarchy
                            ?.map(parent => parent?.title)
                            .join('/') + '/'
                    )
            ))();
    }, [values?.[defaults?.[0]?.field]]);

    return (
        <div style={{ ...customStyle, flexDirection: 'column' }}>
            <div style={customStyle}>
                <Typography.Text strong>
                    {defaults?.[0]?.label}:
                </Typography.Text>
                {path}
            </div>
            <div style={customStyle}>
                <Typography.Text strong>
                    {defaults?.[1]?.label}:
                </Typography.Text>
                {values?.[defaults?.[1]?.field]}
            </div>
        </div>
    );
}
