import { Table, Descriptions, Typography } from 'antd';
import useDFAStore from 'store/dataflowAuth';

const transitoryVariablesColumns = [
    {
        title: 'Variable',
        dataIndex: 'key',
        key: 'key',
        width: '50%',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: '50%',
    },
];

const pairingParameterColumns = [
    {
        title: 'Parameter',
        dataIndex: 'key',
        key: 'key',
        width: '50%',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: '50%',
    },
];

const sensorValuesColumns = [
    {
        title: 'Parameter',
        dataIndex: 0,
        key: 'key',
        width: '50%',
    },
    {
        title: 'Value',
        dataIndex: 1,
        key: 'value',
        render: value =>
            typeof value !== 'object' ? value : JSON.stringify(value),
        width: '50%',
    },
];

function CommonAutoTriggerDetails({
    config,
    showSensorValues = false,
    children,
}) {
    const nodeTemplates = useDFAStore(state => state.nodeTemplates);

    const automatic = { ...config?.config?.automatic?.config } || {};
    const sensor_values = config?.config?.automatic?.sensor_values;
    const dataTriggerNode = nodeTemplates?.data?.find(
        node => node?.node_type === 'DATA_TRIGGER'
    );

    return (
        <Descriptions
            layout="horizontal"
            bordered
            size="small"
            style={{ marginTop: 16, marginBottom: 16 }}
            labelStyle={{ width: '40%' }}
            contentStyle={{ width: '60%' }}
        >
            <Descriptions.Item span={12}>
                <Typography.Text strong>
                    Auto Trigger Configuration
                </Typography.Text>
            </Descriptions.Item>

            <Descriptions.Item label="Trigger Service" span={12}>
                {
                    dataTriggerNode?.sub_types?.find(
                        subtype =>
                            subtype?.sub_type ===
                            config?.config?.automatic?.trigger_service
                    ).name
                }
            </Descriptions.Item>
            {showSensorValues && (
                <Descriptions.Item label="Sensor Values" span={12}>
                    {typeof sensor_values === 'object' ? (
                        <Table
                            size="small"
                            dataSource={Object.entries(sensor_values)}
                            columns={sensorValuesColumns}
                            pagination={false}
                            style={{ width: '100%' }}
                            scroll={{ x: true }}
                            bordered
                        />
                    ) : sensor_values !== undefined ? (
                        sensor_values
                    ) : (
                        'N/A'
                    )}
                </Descriptions.Item>
            )}

            {children}
            <Descriptions.Item label="Transitory Variables" span={12}>
                {Array.isArray(automatic?.transitory_params) &&
                automatic?.transitory_params?.length > 0 ? (
                    <Table
                        size="small"
                        dataSource={automatic?.transitory_params}
                        columns={transitoryVariablesColumns}
                        pagination={false}
                        style={{ width: '100%' }}
                        scroll={{ x: true }}
                        bordered
                    />
                ) : (
                    'N/A'
                )}
            </Descriptions.Item>
            <Descriptions.Item label="Pairing Parameters" span={12}>
                {Array.isArray(automatic?.pairing_params) &&
                automatic?.pairing_params?.length > 0 ? (
                    <Table
                        size="small"
                        dataSource={automatic?.pairing_params}
                        columns={pairingParameterColumns}
                        pagination={false}
                        scroll={{ x: true }}
                        bordered
                    />
                ) : (
                    'N/A'
                )}
            </Descriptions.Item>
        </Descriptions>
    );
}

export default CommonAutoTriggerDetails;
