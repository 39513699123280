import { Descriptions, Table } from 'antd';
import CommonAutoTriggerDetails from './CommonAutoTriggerDetails';
import CustomCodeEditor from 'common/CustomCodeEditor';

const linesColumns = [
    {
        title: 'Regex',
        dataIndex: 'type',
        key: 'key',
    },
];

function StreamLogDetails({ config, showSensorValues }) {
    const automatic = { ...config?.config?.automatic?.config };
    const defaults = config?.params?.[0]?.default;

    return (
        <>
            <CommonAutoTriggerDetails
                config={config}
                showSensorValues={showSensorValues}
            >
                <Descriptions.Item label={defaults?.[0]?.label} span={12}>
                    {automatic?.[defaults?.[0]?.field]}
                </Descriptions.Item>
                <Descriptions.Item label={defaults?.[1]?.label} span={12}>
                    {automatic?.[defaults?.[1]?.field]}
                </Descriptions.Item>
                <Descriptions.Item label={defaults?.[4]?.label} span={12}>
                    {automatic?.[defaults?.[4]?.field]} seconds
                </Descriptions.Item>
                <Descriptions.Item label={defaults?.[2]?.label} span={12}>
                    {Array.isArray(automatic?.[defaults?.[2]?.field]) &&
                    automatic?.[defaults?.[2]?.field]?.length > 0 ? (
                        <Table
                            size="small"
                            dataSource={
                                Array.isArray(automatic?.[defaults?.[2]?.field])
                                    ? automatic?.[defaults?.[2]?.field]?.map(
                                          line => ({ type: line })
                                      )
                                    : []
                            }
                            columns={linesColumns}
                            pagination={false}
                            style={{ width: '100%' }}
                            scroll={{ x: true }}
                            bordered
                        />
                    ) : (
                        'N/A'
                    )}
                </Descriptions.Item>
                <Descriptions.Item label={defaults?.[3]?.label} span={12}>
                    {Array.isArray(automatic?.[defaults?.[3]?.field]) &&
                    automatic?.[defaults?.[3]?.field]?.length > 0 ? (
                        <Table
                            size="small"
                            dataSource={
                                Array.isArray(automatic?.[defaults?.[3]?.field])
                                    ? automatic?.[defaults?.[3]?.field]?.map(
                                          line => ({ type: line })
                                      )
                                    : []
                            }
                            columns={linesColumns}
                            pagination={false}
                            style={{ width: '100%' }}
                            scroll={{ x: true }}
                            bordered
                        />
                    ) : (
                        'N/A'
                    )}
                </Descriptions.Item>
                <Descriptions.Item label={defaults?.[8]?.label} span={12}>
                    <CustomCodeEditor
                        value={automatic?.[defaults?.[8]?.field]}
                        language={'python'}
                        readOnly={true}
                        fontSize={'1.5vh'}
                    />
                </Descriptions.Item>
            </CommonAutoTriggerDetails>
        </>
    );
}
export default StreamLogDetails;
