export default function DateTimeFilter({ date, onDateChange }) {
    return (
        <div className="ag-input-wrapper">
            <input
                type="datetime-local"
                step={1}
                onChange={event => {
                    onDateChange(
                        event?.target?.value
                            ? new Date(event?.target?.value)
                            : null
                    );
                }}
            />
        </div>
    );
}
