import React, { useEffect, useState } from 'react';
import useDFAStore from 'store/dataflowAuth';
import {
    Typography,
    Space,
    Tag,
    Button,
    Tooltip,
    Col,
    Popconfirm,
    Input,
    Table,
} from 'antd';
import HtmlParser from 'html-react-parser';
import { jobStatus } from 'utils/utils';
import PriorRunDetails from '../Data/PriorRuns/PriorRunDetails';
const { Search } = Input;
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    SyncOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const getSortDirecion = (sort = {}) => {
    const { order = 'descend', field = 'start_time' } = sort;
    if (order) {
        return order === 'descend' ? `desc_${field}` : `asc_${field}`;
    }
    return null;
};
import { useSearchParams } from 'react-router-dom';
export default function Jobs() {
    const {
        getDataflowJobs,
        getDataflowJob,
        dataflowjob,
        setDataflowJob,
        dataflowjobs,
        setDataflowJobs,
        reRunDataflow,
        abortDataflow,
    } = useDFAStore(state => ({
        getDataflowJobs: state.getDataflowJobs,
        getDataflowJob: state.getDataflowJob,
        dataflowjob: state.dataflowjob,
        setDataflowJob: state.setDataflowJob,
        dataflowjobs: state.dataflowjobs,
        setDataflowJobs: state.setDataflowJobs,
        reRunDataflow: state.reRunDataflow,
        abortDataflow: state.abortDataflow,
    }));

    const [searchParams, setSearchParams] = useSearchParams();

    const [sortOrder, setSortOrder] = useState(
        searchParams?.get('sort') ?? 'desc_start_time'
    );
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(async () => {
        await fetchDataflowJobs(searchParams.get('search_query') ?? '');
        return () => {
            setDataflowJobs({});
        };
    }, []);

    const secToTimeFormat = totalSeconds => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours} hours ${minutes} mins ${seconds} secs`;
    };

    const columns = [
        {
            title: 'Dataflow name',
            dataIndex: 'name',
            key: 'name',
            render: (job_name, dataflowjobs) => (
                <Space direction="vertical" size={0}>
                    <Typography.Link
                        onClick={() => {
                            setDataflowJob(dataflowjobs);
                            navigate(`./${dataflowjobs.job_id}`);
                        }}
                        style={{ fontSize: '1.1em' }}
                        strong
                    >
                        {dataflowjobs?.job_name}
                    </Typography.Link>
                    <Typography.Paragraph>
                        {HtmlParser(dataflowjobs.dataflow_name || '')}
                    </Typography.Paragraph>
                </Space>
            ),

            sorter: true,
            sortDirections: ['ascend', 'descend'],
            sortOrder: sortOrder?.includes('name')
                ? sortOrder?.includes('desc')
                    ? 'descend'
                    : 'ascend'
                : null,
            // width: '10%',
        },
        {
            title: 'Executed By',
            // width: '10%',
            dataIndex: 'executed_by',
            key: 'executed_by',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            sortOrder: sortOrder?.includes('executed_by')
                ? sortOrder?.includes('desc')
                    ? 'descend'
                    : 'ascend'
                : null,
            render: executed_by => <>{executed_by}</>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            // width: '18%',
            sortDirections: ['ascend', 'descend'],
            sortOrder: sortOrder?.includes('status')
                ? sortOrder?.includes('desc')
                    ? 'descend'
                    : 'ascend'
                : null,
            render: status => <>{jobStatus(status)}</>,
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            sortOrder: sortOrder?.includes('start_time')
                ? sortOrder?.includes('desc')
                    ? 'descend'
                    : 'ascend'
                : null,
            render: date =>
                new Date(date).toLocaleString(
                    {},
                    {
                        dateStyle: 'medium',
                        timeStyle: 'short',
                    }
                ),
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            key: 'end_time',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            sortOrder: sortOrder?.includes('end_time')
                ? sortOrder?.includes('desc')
                    ? 'descend'
                    : 'ascend'
                : null,
            render: date =>
                new Date(date).toLocaleString(
                    {},
                    {
                        dateStyle: 'medium',
                        timeStyle: 'short',
                    }
                ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            sortOrder: sortOrder?.includes('duration')
                ? sortOrder?.includes('desc')
                    ? 'descend'
                    : 'ascend'
                : null,
            render: duration => secToTimeFormat(duration),
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, dataflowjobs) => (
                <Space size={[8, 8]} direction="horizontal">
                    <Col>
                        <Tooltip title="Restart" placement="bottom">
                            <Button
                                icon={<SyncOutlined />}
                                type="text"
                                onClick={() => {
                                    reRunDataflow(dataflowjobs?.dataflow_id);
                                    fetchDataflowJobs();
                                }}
                            />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Popconfirm
                            title={`Confirm Deletion`}
                            description={`Are you sure to stop "${dataflowjobs?.name}"?`}
                            onConfirm={() => {
                                abortDataflow(dataflowjobs?.dataflow_id);
                                fetchDataflowJobs();
                            }}
                            okText="Yes"
                            cancelText="No"
                            placement="topLeft"
                        >
                            <Tooltip title="Stop" placement="bottom">
                                <Button
                                    icon={<CloseCircleOutlined />}
                                    danger
                                    type="text"
                                    disabled={
                                        dataflowjobs?.status === 'FAILED' ||
                                        dataflowjobs?.status === 'ABORTED'
                                    }
                                />
                            </Tooltip>
                        </Popconfirm>
                    </Col>
                </Space>
            ),
            align: 'center',
        },
    ];

    const fetchDataflowJobs = async (
        search_query = '',
        page = 1,
        limit = 10,
        sort = { order: 'descend', field: 'start_time' }
    ) => {
        setLoading(true);
        const sortOrder = getSortDirecion(sort);
        setSearchParams({
            search_query,
            page,
            limit,
            sort: sortOrder,
        });
        setSortOrder(sortOrder);
        try {
            const data = await getDataflowJobs(
                search_query,
                page,
                limit,
                sortOrder
            );
            setDataflowJobs(data);
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    };

    const onSearch = async value => {
        setLoading(true);
        await fetchDataflowJobs(
            value ?? '',
            searchParams.get('page') ?? 1,
            searchParams.get('limit') ?? 10,
            searchParams.get('sort') ?? 'desc_created'
        );
        setLoading(false);
    };
    return (
        <div>
            <div className="search-bar" style={{ marginBottom: '10px' }}>
                <Search
                    prefix={<SearchOutlined />}
                    size="large"
                    placeholder="Search Dataflow Jobs"
                    allowClear
                    onSearch={onSearch}
                />
            </div>
            {false ? (
                <PriorRunDetails
                    runId={dataflowjob?.dataflow_run_id}
                    dataflowId={dataflowjob?.dataflow_id}
                />
            ) : (
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={dataflowjobs?.data}
                    onChange={({ current, pageSize }, filters, sort) => {
                        // if (
                        //     (searchParams.get('query') || filters?.name?.[0]) &&
                        //     filters?.name?.[0] !== searchParams.get('query')
                        // )
                        //     return;
                        fetchDataflowJobs(
                            searchParams.get('search_query'),
                            current,
                            pageSize,
                            sort
                        );
                    }}
                    pagination={{
                        pageSize: dataflowjobs?.meta?.limit,
                        total: dataflowjobs?.meta?.total_records,
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                    }}
                />
            )}
        </div>
    );
}
