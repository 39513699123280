import create from 'zustand';
import axios from 'services/api';
import { convertParamsIntoParamString } from 'utils/utils';

export const useDashboardStore = create((set, get) => ({
    contentId: '',
    setContentId: contentId => set({ contentId }),
    contextImageFileName: '',
    setContextImageFileName: contextImageFileName =>
        set({ contextImageFileName }),
    dashboard: {},
    setDashboard: data => set({ dashboard: data }),
    dashboards: [],
    meta: { limit: 10 },

    setDashboards: response => {
        const { meta, data } = response;
        set({ meta, dashboards: data });
    },
    // NOTE: uncomment to enable drag sort
    // setDashboards: response => {
    //     const { meta, data } = response;
    //     if (meta.page == 1) {
    //         set({
    //             meta,
    //             dashboards: data,
    //         });
    //     } else {
    //         set(prev => ({
    //             meta,
    //             dashboards: [...prev.dashboards, ...data],
    //         }));
    //     }
    // },

    imageInterpreter: async (content, task, capturedImage) => {
        const formData = new FormData();
        formData.append('content_id', content?.content_id);
        formData.append('task', task);
        if (capturedImage) {
            formData.append('context_image_obj', capturedImage);
        }
        return await axios
            .post('/lucy/image-interpreter', formData)
            .then(res => {
                return res.data;
            })
            .catch(err => {
                console.error(err);
                return null;
            });
    },

    dashboardFormData: {},
    setDashboardFormData: data => set({ dashboardFormData: data }),

    getDashboards: async (
        dashboardType = '',
        search_query = '',
        page = 1,
        limit = 10,
        sort = 'desc_updated',

        created__gte = '',
        created__lte = '',
        updated__gte = '',
        updated__lte = ''
    ) =>
        await axios.get(
            `/dashboards/${convertParamsIntoParamString({
                page,
                limit,
                dashboard_type: dashboardType,
                sort,
                search_query,
                created__gte,
                created__lte,
                updated__gte,
                updated__lte,
            })}`
        ),
    fetchDashboardDetails: async dashboardId =>
        await axios.get(`/dashboards/${dashboardId}`),
    updateDashboard: async (dashboardId, dashboardData) =>
        await axios.put(`/dashboards/${dashboardId}`, dashboardData),
    addNewDashboard: async dashboardData =>
        await axios.post(`/dashboards/publish`, dashboardData),
    deleteDashboard: async dashboardId =>
        await axios.delete(`dashboards/${dashboardId}`),

    generateDashDashboard: async ({ code_json, name, description }) =>
        await axios.post(`dashboards/dash-playground/`, {
            code_json,
            name,
            description,
        }),
    updateDashDashboard: async (
        dashboardId,
        { code_json, name, description }
    ) =>
        await axios.put(`dashboards/dash-playground/${dashboardId}`, {
            code_json,
            name,
            description,
        }),
    generateDashDashboardPage: async (
        dashboardId,
        { code_json, name, description }
    ) =>
        await axios.post(`dashboards/dash-playground/${dashboardId}/pages`, {
            code_json,
            name,
            description,
        }),
    updateDashDashboardPage: async (
        dashboardId,
        dashboardPageId,
        { code_json, name, description }
    ) =>
        await axios.put(
            `dashboards/dash-playground/${dashboardId}/pages/${dashboardPageId}`,
            {
                code_json,
                name,
                description,
            }
        ),
    deleteDashDashboardPage: async (dashboardId, dashboardPageId) =>
        await axios.delete(
            `dashboards/${dashboardId}/pages/publish/${dashboardPageId}`
        ),
    publishDashDashboardPage: async (dashboardId, dashboardData) =>
        await axios.post(
            `dashboards/${dashboardId}/pages/publish`,
            dashboardData
        ),

    nativeSort: (indexToMove, newIndex) => {
        set(prev => {
            const clone = [...prev.dashboards];
            clone.splice(newIndex, 0, clone.splice(indexToMove, 1)[0]);
            return {
                dashboards: clone,
            };
        });
    },

    exportDashboards: async dashboardIds =>
        await axios.post('dashboards/export', { dashboard_ids: dashboardIds }),

    importDashboards: async dashboardFormData =>
        await axios.post('dashboards/import', dashboardFormData),
}));
