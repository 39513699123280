import React from 'react';
import { Descriptions, Divider } from 'antd';
import { renderKeyValue } from 'utils/utils';
import { removeUnderscore } from 'utils/utils';
export default function RowSort({ config }) {
    let rowSortConfig = { ...config?.row_sort };

    return (
        <>
            <Descriptions
                title=""
                bordered
                column={1}
                size={'small'}
                layout={'horizontal'}
            >
                {Object.entries(rowSortConfig)?.map(entry => {
                    return (
                        <>
                            {!(entry?.[1] === 'object') && (
                                <Descriptions.Item
                                    label={
                                        <b>{removeUnderscore(entry?.[0])}</b>
                                    }
                                >
                                    {Array.isArray(entry?.[1]) ? (
                                        entry?.[1]?.map((val, index) => {
                                            return (
                                                <>
                                                    {typeof val === 'object' ? (
                                                        <>
                                                            {index > 0 ? (
                                                                <Divider />
                                                            ) : null}
                                                            {renderKeyValue(
                                                                val
                                                            )}
                                                        </>
                                                    ) : (
                                                        <ul
                                                            style={{
                                                                paddingInlineStart:
                                                                    '15px',
                                                            }}
                                                        >
                                                            <li>{val}</li>
                                                        </ul>
                                                    )}
                                                </>
                                            );
                                        })
                                    ) : typeof entry?.[1] === 'object' &&
                                      entry?.[1] !== null ? (
                                        <></>
                                    ) : (
                                        <>{entry?.[1]}</>
                                    )}
                                </Descriptions.Item>
                            )}
                        </>
                    );
                })}
            </Descriptions>
        </>
    );
}
