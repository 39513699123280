import create from 'zustand';
import axios from 'services/api';
import { convertParamsIntoParamString } from 'utils/utils';

export const FIXED_LIMIT = 20;
export const INITIAL_LOAD_PAGECOUNT = 3;

export const useTrashStore = create((set, get) => ({
    contents: [],
    contentTypes: {},
    ancestors: [],
    limit: 0,
    page: 0,
    total: 0,
    filters: {},
    errorMessage: '',
    contentId: '',
    selectedId: '',

    setContentId: contentId => {
        set({ contentId });
    },

    setSelectedId: selectedId => {
        set({ selectedId });
    },

    resetTrash: () => {
        set({
            contents: [],
            ancestors: [],
        });
    },

    getContents: async (contentId = '', filters = {}) =>
        await axios
            .get(
                `/library/${
                    filters?.query ? 'search' : 'trash/' + contentId
                }${convertParamsIntoParamString({
                    page: 1,
                    limit: INITIAL_LOAD_PAGECOUNT * FIXED_LIMIT,
                    ...filters,
                })}`
            )
            .then(res => {
                set({
                    contents: res?.data?.data?.contents || res?.data?.data,
                    contentTypes: res?.data?.data?.content_types,
                    ancestors: res?.data?.data?.hierarchy,
                    page: INITIAL_LOAD_PAGECOUNT,
                    limit: FIXED_LIMIT,
                    total: res?.data?.meta?.total_records,
                    filters: filters,
                });
            })
            .catch(err => {
                set({ contents: [] });
                console.error(err?.response?.data?.error_message || err);
            }),

    loadMoreContent: async (contentId = '', pageRequested) => {
        const filters = get()?.filters;
        await axios
            .get(
                `/library/${
                    filters?.query ? 'search' : 'trash/' + contentId
                }${convertParamsIntoParamString({
                    page: pageRequested,
                    limit: FIXED_LIMIT,
                    ...filters,
                })}`
            )
            .then(res => {
                set(state => ({
                    contents: [
                        ...state?.contents,
                        ...(res?.data?.data?.contents || res?.data?.data),
                    ],
                    page: res?.data?.meta?.page,
                }));
            })
            .catch(err =>
                console.error(err?.response?.data?.error_message || err)
            );
    },

    setContents: contents => {
        set({ contents });
    },

    setErrorMessage: errorMessage => {
        set({ errorMessage });
    },

    removeFromTrash: async contentId =>
        await axios
            .delete(`/library/trash/${contentId}`)
            .then(() => get().getContents(get().ancestors?.at(-1)?.content_id)),

    moveContents: async (contentId, parentId, method) =>
        await axios
            .put(
                `/library/trash/${contentId}${
                    method ? `?method=${method}` : ''
                }`,
                parentId
                    ? {
                          parent_id: parentId,
                      }
                    : {}
            )
            .then(res => null)
            .catch(err => {
                return err?.response?.data?.error_message || err;
            }),

    getChildren: async (contentId = '', contentTypes = []) =>
        await axios
            .get(
                `/library/explore-contents/${contentId}?${contentTypes.reduce(
                    (acc, current) => acc + '&content_type=' + current,
                    ''
                )}`
            )
            .catch(err =>
                console.error(err?.response?.data?.error_message || err)
            ),
}));
