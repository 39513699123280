import {
    Button,
    Form,
    Input,
    Select,
    Space,
    Modal,
    Typography,
    Divider,
} from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import useDFAStore from 'store/dataflowAuth';
import { useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import useDataConnectionsStore from 'store/dataConnections';
import { useTSAuthoringStore } from 'store/tsAuthoring';

export const crudOptions = [
    { label: 'Create New / Add to Table', value: 'add' },
    { label: 'Replace Existing Table', value: 'replace' },
    { label: 'Update Data Table', value: 'update' },
];

const UpdateColumns = ({ fields, add, remove }) => {
    return (
        <>
            {fields?.map(({ key, name, ...restField }) => (
                <Space key={key} align="baseline">
                    <Form.Item
                        {...restField}
                        name={[name, 'table_column']}
                        rules={[
                            {
                                required: true,
                                message: 'Missing Table Column',
                            },
                        ]}
                    >
                        <Input placeholder="Table Column" />
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'dps_column']}
                        rules={[
                            {
                                required: true,
                                message: 'Missing DPS Column',
                            },
                        ]}
                    >
                        <Input placeholder="DPS Column" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
            ))}
            <Form.Item>
                <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                >
                    Add Filter Fields
                </Button>
            </Form.Item>
        </>
    );
};

export default function DataTableForm({ form, nodeSubtype, config }) {
    const [modal, contextHolder] = Modal.useModal();
    const updateDataflowNode = useDFAStore(state => state.updateDataflowNode);
    const onEditFunction = useDFAStore(state => state.onEditFunction);
    const addNode = useDFAStore(state => state.addNode);
    const setLoading = useDFAStore(state => state.setLoading);
    const getColumnTypes = useDFAStore(state => state.getColumnTypes);
    const dataflow = useDFAStore(state => state.dataflow);
    const getSourceNode = useDFAStore(state => state.getSourceNode);
    const { dataConnections, setDataConnections } = useDFAStore(state => ({
        dataConnections: state.dataConnections,
        setDataConnections: state.setDataConnections,
    }));
    const getAllDataConnections = useDataConnectionsStore(
        state => state.getAllDataConnections
    );
    const [dataTableOptions, setDataTableOptions] = useState([]);

    const getRegisteredDataTables = useTSAuthoringStore(
        state => state.getRegisteredDataTables
    );

    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const [columnOptions, setColumnOptions] = useState([]);

    const onNameChange = event => {
        setName(event.target.value);
    };
    const addItem = e => {
        e.preventDefault();
        setDataTableOptions(options => [
            ...options,
            { value: name, label: name },
        ]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    useEffect(() => {
        if (!config?.dataflow_node_id) return;
        form.setFieldsValue({
            name: config?.name,
            values: config?.config,
        });
        (async () =>
            await getDataTableOptions(
                config?.config?.datastore_config?.data_source,
                false
            ))();
    }, []);

    const onSubmit = async values => {
        if (!config?.dataflow_node_id?.length) {
            const nodeSubtypeCopy = { ...nodeSubtype };
            [
                '_id',
                'created',
                'updated',
                'node_type',
                'params_supported',
                'disabled',
                'node_alias',
            ].forEach(key => {
                delete nodeSubtypeCopy?.[key];
            });
            nodeSubtypeCopy['data_type'] = nodeSubtypeCopy?.['sub_type'];
            await addNode(config?.dataflow_id, {
                ...values,
                params: [nodeSubtypeCopy],
                node_type: config?.node_type,
                position: config?.position,
                source_node_id: config?.source_node_id,
            });
        } else {
            await updateDataflowNode(
                config?.dataflow_id,
                config?.dataflow_node_id,
                { ...values, node_type: config?.node_type }
            );
            onEditFunction(config?.dataflow_node_id, true, true);
        }
    };

    const handleSubmit = values => {
        const { crud_operation, data_table } = values?.values?.datastore_config;
        if (crud_operation === 'replace')
            return modal.warning({
                content: (
                    <>
                        Are you sure you want to replace{' '}
                        <Typography.Text strong>{data_table}</Typography.Text>?
                    </>
                ),
                centered: true,
                onOk: () => onSubmit(values),
                onCancel: () => setLoading(false),
                okCancel: true,
            });
        onSubmit(values);
    };

    useEffect(() => {
        if (!dataConnections?.data?.data?.connection_list?.length) {
            (async () =>
                await getAllDataConnections()
                    .then(setDataConnections)
                    .catch(e => console.log(e)))();
        }

        if (!columnOptions.length) {
            (async () =>
                await getColumnTypes(
                    config?.dataflow_id,
                    dataflow?.last_run_status?.dataflow_run_id,
                    config?.source_node_id ??
                        getSourceNode(config?.dataflow_node_id, dataflow?.edges)
                            .dataflow_node_id
                )
                    .then(res => {
                        let options = Object.keys(res?.data?.data)?.map(
                            column => ({
                                value: column,
                                label: column,
                            })
                        );
                        setColumnOptions(options);
                    })
                    .catch(e => console.error(e)))();
        }
    }, []);

    const dataConnectionOptions = useMemo(
        () =>
            dataConnections?.data?.data?.connection_list?.map(connection => ({
                label: connection?.data_connection_name,
                value: connection?._id,
            })),
        [dataConnections?.data?.data?.connection_list?.length]
    );

    const getDataTableOptions = async (
        dataConnectionId,
        resetFormItem = true
    ) => {
        const response = await getRegisteredDataTables(dataConnectionId);
        const options = await response?.data?.data?.registered_data_tables?.map(
            table => ({
                value: table?.data_table_name,
                label: table?.data_table_name,
            })
        );
        resetFormItem &&
            form.setFieldValue(
                ['values', 'datastore_config', 'data_table'],
                ''
            );
        setDataTableOptions(options);
    };

    return (
        <>
            <Form
                layout="vertical"
                form={form}
                autoComplete="off"
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name={['values', 'datastore_config']}>
                    <Form.Item
                        name={['values', 'datastore_config', 'data_source']}
                        label="Data Source"
                        rules={[
                            {
                                required: true,
                                message: 'Select a Source',
                            },
                        ]}
                    >
                        <Select
                            options={dataConnectionOptions}
                            onChange={value => getDataTableOptions(value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['values', 'datastore_config', 'crud_operation']}
                        label="Operation"
                        rules={[
                            {
                                required: true,
                                message: 'Select an Operation',
                            },
                        ]}
                    >
                        <Select options={crudOptions} />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues?.values?.datastore_config
                                ?.crud_operation !==
                            currentValues?.values?.datastore_config
                                ?.crud_operation
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue([
                                'values',
                                'datastore_config',
                                'crud_operation',
                            ]) === 'create' ? (
                                <>
                                    <Form.Item
                                        name={[
                                            'values',
                                            'datastore_config',
                                            'data_table',
                                        ]}
                                        label="Destination Table"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Enter Destination Table Name',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            ) : (
                                <Form.Item
                                    name={[
                                        'values',
                                        'datastore_config',
                                        'data_table',
                                    ]}
                                    label="Destination Table"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select Destination Table',
                                        },
                                    ]}
                                >
                                    <Select
                                        options={dataTableOptions}
                                        disabled={!dataTableOptions?.length}
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider
                                                    style={{
                                                        margin: '8px 0',
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        padding: '0 8px 4px',
                                                    }}
                                                >
                                                    <Input
                                                        placeholder="Please enter item"
                                                        ref={inputRef}
                                                        value={name}
                                                        onChange={onNameChange}
                                                        onKeyDown={e =>
                                                            e.stopPropagation()
                                                        }
                                                    />
                                                    <Button
                                                        type="text"
                                                        icon={<PlusOutlined />}
                                                        onClick={addItem}
                                                    >
                                                        Add Table
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                    />
                                </Form.Item>
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues?.values?.datastore_config
                                ?.crud_operation !==
                            currentValues?.values?.datastore_config
                                ?.crud_operation
                        }
                        noStyle
                    >
                        {({ getFieldValue }) =>
                            getFieldValue([
                                'values',
                                'datastore_config',
                                'crud_operation',
                            ]) === 'update' && (
                                <Form.Item label="Join on">
                                    <Form.List
                                        name={[
                                            'values',
                                            'datastore_config',
                                            'update_params',
                                        ]}
                                    >
                                        {(fields, { add, remove }) => (
                                            <UpdateColumns
                                                fields={fields}
                                                add={add}
                                                remove={remove}
                                            />
                                        )}
                                    </Form.List>
                                </Form.Item>
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues?.values?.datastore_config
                                ?.crud_operation !==
                            currentValues?.values?.datastore_config
                                ?.crud_operation
                        }
                        noStyle
                    >
                        {({ getFieldValue }) =>
                            getFieldValue([
                                'values',
                                'datastore_config',
                                'crud_operation',
                            ]) === 'update' && (
                                <Form.Item
                                    label="Columns to Update"
                                    name={[
                                        'values',
                                        'datastore_config',
                                        'columns_to_update',
                                    ]}
                                    initialValue={[]}
                                    help="If no columns are selected, all the columns are updated"
                                >
                                    <Select
                                        placeholder="Select Columns to Update"
                                        options={columnOptions}
                                        mode="multiple"
                                        maxLength="responsive"
                                        allowClear
                                    />
                                </Form.Item>
                            )
                        }
                    </Form.Item>
                </Form.Item>
            </Form>
            {contextHolder}
        </>
    );
}
