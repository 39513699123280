import { Descriptions, Space, Table, Tag } from 'antd';
import React, { useEffect, useMemo } from 'react';
import useDataConnectionsStore from 'store/dataConnections';
import useDFAStore from 'store/dataflowAuth';
import { crudOptions } from 'components/DFA/Dataflow/NodeForm/DataDestinationForm/DataTableForm';

const columns = [
    {
        title: 'Table Column',
        dataIndex: 'table_column',
        key: 'table_column',
    },
    {
        title: 'DPS Column',
        dataIndex: 'dps_column',
        key: 'dps_column',
    },
];

export default function DataTable({ config }) {
    const getAllDataConnections = useDataConnectionsStore(
        state => state.getAllDataConnections
    );
    const { setDataConnections, dataConnections } = useDFAStore(state => ({
        dataConnections: state.dataConnections,
        setDataConnections: state.setDataConnections,
    }));

    useEffect(() => {
        (() => {
            if (!dataConnections?.data?.data?.connection_list?.length) {
                getAllDataConnections()?.then(setDataConnections);
            }
        })();
    }, []);

    const dataConnectionName = useMemo(
        () =>
            dataConnections?.data?.data?.connection_list?.filter(
                connection =>
                    connection?._id ===
                    config?.config?.datastore_config?.data_source
            )?.[0]?.data_connection_name,
        [dataConnections?.data?.data?.connection_list?.length]
    );

    const operationName = useMemo(
        () =>
            crudOptions?.filter(
                operation =>
                    operation?.value ===
                    config?.config?.datastore_config?.crud_operation
            )?.[0]?.label,
        []
    );
    return (
        <>
            <Descriptions bordered>
                <Descriptions.Item label="Source" span={24}>
                    {dataConnectionName}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="Operation">
                    {operationName}
                </Descriptions.Item>
                <Descriptions.Item span={24} label="Table">
                    {config?.config?.datastore_config?.data_table}
                </Descriptions.Item>
                {!!config?.config?.datastore_config?.update_params?.length && (
                    <Descriptions.Item span={24} label="Updated Params">
                        <Table
                            size="small"
                            dataSource={
                                config?.config?.datastore_config?.update_params
                            }
                            columns={columns}
                            pagination={{
                                hideOnSinglePage: true,
                            }}
                            bordered
                        />
                    </Descriptions.Item>
                )}
                {!!config?.config?.datastore_config?.columns_to_update
                    ?.length && (
                    <Descriptions.Item span={24} label="Columns to Update">
                        <Space>
                            {config?.config?.datastore_config?.columns_to_update?.map(
                                columnName => (
                                    <Tag>{columnName}</Tag>
                                )
                            )}
                        </Space>
                    </Descriptions.Item>
                )}
            </Descriptions>
        </>
    );
}
