import create from 'zustand';
import axios from 'services/api';

const useGeneralStore = create(set => ({
    getContentMetadata: async (contentAndDocIds = []) =>
        await axios.put('/lucy/content-metadata', {
            source_content_ids: contentAndDocIds,
        }),

    finalHierarchy: [],
    setFinalHierarchy: hierarchy => {
        if (typeof hierarchy === 'function') {
            set(state => ({ finalHierarchy: hierarchy(state.finalHierarchy) }));
        } else {
            set({ finalHierarchy: hierarchy });
        }
    },
}));

export default useGeneralStore;
