import React, { useEffect, useMemo } from 'react';
import { Descriptions, Divider } from 'antd';
import { renderKeyValue } from 'utils/utils';
import useDataConnectionsStore from 'store/dataConnections';
import useDFAStore from 'store/dataflowAuth';
export default function ExternalApi({ config }) {
    let externalApiConfig = { ...config?.config?.external_api };

    const getAllDataConnections = useDataConnectionsStore(
        state => state.getAllDataConnections
    );
    const { setDataConnections, dataConnections } = useDFAStore(state => ({
        dataConnections: state.dataConnections,
        setDataConnections: state.setDataConnections,
    }));

    useEffect(() => {
        (() => {
            if (!dataConnections?.data?.data?.connection_list?.length) {
                getAllDataConnections()?.then(setDataConnections);
            }
        })();
    }, []);

    const dataConnectionName = useMemo(
        () =>
            dataConnections?.data?.data?.connection_list?.filter(
                connection =>
                    connection?._id === externalApiConfig?.api_connection
            )?.[0]?.data_connection_name,
        [dataConnections?.data?.data?.connection_list?.length]
    );

    externalApiConfig['api_connection'] = dataConnectionName;

    return (
        <>
            <Descriptions
                title=""
                bordered
                column={1}
                size={'small'}
                layout={'horizontal'}
            >
                {Object.entries(externalApiConfig)?.map(entry => {
                    return (
                        <>
                            {!(entry?.[1] === 'object') && (
                                <Descriptions.Item label={<b>{entry?.[0]}</b>}>
                                    {Array.isArray(entry?.[1]) ? (
                                        entry?.[1]?.map(val => {
                                            return (
                                                <>
                                                    {typeof val === 'object' ? (
                                                        <>
                                                            {renderKeyValue(
                                                                val
                                                            )}
                                                            <Divider />
                                                        </>
                                                    ) : (
                                                        <ul>
                                                            <li>{val}</li>
                                                        </ul>
                                                    )}
                                                </>
                                            );
                                        })
                                    ) : typeof entry?.[1] === 'object' &&
                                      entry?.[1] !== null ? (
                                        <></>
                                    ) : (
                                        <>{entry?.[1]}</>
                                    )}
                                </Descriptions.Item>
                            )}
                        </>
                    );
                })}
            </Descriptions>
        </>
    );
}
