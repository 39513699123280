import React from 'react';
import { Card, Table } from 'antd';

const columns = [
    {
        title: 'Filtered and Sorted Columns ',
        dataIndex: 'filter_columns',
        key: 'filter_columns',
    }
];

const cleanDataSource = config => {
    const configs = config?.filter_columns;
    if (!Array.isArray(configs)) return [];
    return configs?.reduce((acc, current) => {
        acc?.push({
            filter_columns: current
        });
        return acc;
    }, []);
};

export default function ColumnFilterAndSort({ config }) {
    return (
        <Card size="small">
            <Table
                bordered={true}
                style={{ width: '100%' }}
                dataSource={cleanDataSource(config)}
                columns={columns}
                size="small"
                pagination={{
                    hideOnSinglePage: true,
                }}
            />
        </Card>
    );
}
