import create from 'zustand';
import axios from 'services/api';

export const useUserStore = create((set, get) => ({
    username: localStorage.getItem('username') || '',
    userRole: localStorage.getItem('userRole') || '',
    firstName: localStorage.getItem('firstName') || '',
    lastName: localStorage.getItem('lastName') || '',
    name: localStorage.getItem('name') || '',
    userId: localStorage.getItem('userId') || '',
    expiry: localStorage.getItem('expiry') || '',
    projectName: localStorage.getItem('projectName') || '',
    releaseVersion: localStorage.getItem('releaseVersion') || '',
    releaseDate: localStorage.getItem('releaseDate') || '',
    token: localStorage.getItem('token') || '',
    gu: localStorage.getItem('gu') || '',
    gk: localStorage.getItem('gk') || '',
    release: JSON.parse(localStorage.getItem('release')) || {},
    experiment: {},
    backend: {},
    videoConfUrl: 'https://collaborate.lavorro.com/synopsys_shikar',
    login: async (username, password) =>
        await axios
            .post(`/lucy/users_api/v1/login/`, {
                username,
                password,
            })
            .then(res => {
                axios.defaults.headers.Authorization = res?.data?.data?.token;
                return res;
            }),
    getEnvVars: async () =>
        await axios.get(`/lucy/get-env-details/`).then(res => {
            const {
                grafana,
                release,
                experiment,
                backend,
                release_version: releaseVersion,
                release_date: releaseDate,
            } = res.data?.data;
            const { PASSWORD: gk, USER: gu } = grafana;
            localStorage.setItem('gk', gk);
            localStorage.setItem('gu', gu);

            localStorage.setItem('release', JSON.stringify(release));
            localStorage.setItem('experiment', JSON.stringify(experiment));
            localStorage.setItem('backend', JSON.stringify(backend));
            localStorage.setItem('releaseVersion', releaseVersion);
            localStorage.setItem('releaseDate', releaseDate);

            set({
                gk,
                gu,
                release,
                experiment,
                backend,
                releaseVersion,
                releaseDate,
            });
        }),
    grafanaLogin: async () => {
        await axios
            .post(`/grafana/login`, {
                user: get().gu,
                password: get().gk,
            })
            .catch(err => console.error(err));
    },
    resetUserStore: () =>
        set({
            username: '',
            userRole: '',
            firstName: '',
            lastName: '',
            name: '',
            userId: '',
            expiry: '',
            projectName: '',
            releaseVersion: '',
            releaseDate: '',
            token: '',
            gu: '',
            gk: '',
            release: {},
        }),
    logout: () => {
        localStorage.clear();
        get().resetUserStore();
    },
    handleLoginResponse: response => {
        const {
            token,
            expiry,
            project_name: projectName,
            user_id: userId,
            first_name: firstName,
            last_name: lastName,
            name,
            user_role: userRole,
            username,
        } = response.data?.data;
        localStorage.setItem('username', username);
        localStorage.setItem('userRole', userRole);
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('name', name);
        localStorage.setItem('userId', userId);
        localStorage.setItem('expiry', expiry);
        localStorage.setItem('projectName', projectName);
        localStorage.setItem('token', token);

        set({
            username,
            token,
            expiry,
            projectName,
            userId,
            firstName,
            lastName,
            name,
            userRole,
        });
    },

    userPersona: 'default',
    setUserPersona: userPersona => set({ userPersona }),

    fetchUserDetails: async userId =>
        await axios.get(`/users/${userId}`).catch(err => console.error(err)),
}));
