import create from 'zustand';
import axios from 'services/api';

export const useDataFlowStore = create((set, get) => ({
    id: '',
    dataFlow: {},
    dataFlowList: [],
    metaData: {},
    dropDownValue: '',
    dropDownLabel: '',
    statusModal: false,
    nodesNameStatus: [],
    isJobStartedRunning: false,
    dataflow_job_id: '',
    node_configs: [],
    node_status: 'INIT',
    lastRunStatus: '',
    run_summary_dashboard_id: '',
    dashboardInfo: {},
    fileUploadInfo: {},
    priorRunData: [],
    page: 1,
    limit: 20,
    total_records: 20,
    priorRunSelectedNode: '',
    versionsData: [],
    dataflow_version_id: '',
    versionSelectedNode: '',

    setVersionSelectedNode: value => set({ versionSelectedNode: value }),
    setPriorRunSelectedNode: value => set({ priorRunSelectedNode: value }),

    setFileUploadInfo: value => {
        set({
            fileUploadInfo: value,
        });
    },
    setJobStartedRunning: value => {
        set({
            isJobStartedRunning: value,
        });
    },
    setDataFlowJobId: value => {
        set({
            dataflow_job_id: value,
        });
    },
    setDataFlowVersionId: value => {
        set({
            dataflow_version_id: value,
        });
    },
    setDataFlowId: value => {
        set({
            id: value,
        });
    },
    setDropdownValue: value => {
        set({
            dropDownValue: value,
        });
    },
    setDropdownLabel: value => {
        set({
            dropDownLabel: value,
        });
    },
    setNodesNameStatus: value => {
        set({
            nodesNameStatus: value,
        });
    },
    setStatusModal: value => {
        set({ statusModal: value });
    },
    getDataFlowById: async _id => {
        await axios
            .get(`/dataflows/${_id}?include_node_subtypes=true`)
            .then(res => {
                console.log(res.data.data);
                set({
                    dataFlow: res?.data?.data || [],
                    dropDownValue: res?.data?.data?.name,
                    run_summary_dashboard_id:
                        res?.data?.data?.run_summary_dashboard_id,
                    lastRunStatus:
                        res?.data?.data?.last_run_status?.status || '',
                    dataflow_job_id:
                        res?.data?.data?.last_run_status?.dataflow_run_id || '',
                });
                if (res?.data?.data?.run_summary_dashboard_id)
                    get().getDashboardById(
                        res?.data?.data?.run_summary_dashboard_id
                    );
                return res.data?.data;
            })
            .catch(err => console.error('Dataflow not found'));
    },
    getDashboardById: async _id =>
        await axios
            .get(`/dashboards/${_id}/`)
            .then(res => {
                set({
                    dashboardInfo: res?.data?.data,
                });
                return res.data?.data;
            })
            .catch(err => console.error('Dashboard not found')),
    getDataFlowList: async () => {
        await axios
            .get(`/dataflows/`)
            .then(async res => {
                let tmpDataFlowList = res.data?.data?.map(dataFlow => {
                    return {
                        label: dataFlow.name,
                        value: dataFlow.dataflow_id,
                        meta: {
                            created: dataFlow.created,
                            created_by: dataFlow.created_by,
                            description: dataFlow.description,
                            run_summary_dashboard_id:
                                dataFlow.run_summary_dashboard_id,
                            is_editable: dataFlow.is_editable,
                            updated: dataFlow.updated,
                        },
                    };
                });

                set({
                    id: res?.data?.data?.at(0)?.dataflow_id,
                    dataFlowList: tmpDataFlowList,
                    dropDownLabel: tmpDataFlowList[0].label,
                    dropDownValue: tmpDataFlowList[0].value,
                });

                await get().getDataFlowById(res?.data?.data[0].dataflow_id);

                // return res.data?.data;
            })
            .catch(err => console.error('Dataflow list not found'));
    },
    setDataFlow: async value => {
        set({ dataFlow: value });
    },
    setDataFlowList: async value => {
        set({ dataFlowList: value });
    },
    setMetaData: async value => {
        set({ metaData: value });
    },

    runDataFlow: async payload => {
        await axios
            .post(`dataflows/${get().id}/run/`, payload)
            .then(res => {
                console.log(res);
                set({
                    dataflow_job_id: res.data?.data?.dataflow_job_id,
                });
            })
            .catch(err => console.error("Couldn't run the Dataflow"));
    },
    runHistory: async (page, limit) => {
        await axios
            .get(
                `dataflows/${get().id}/run-history/?page=${page || 1}&limit=${
                    limit || get().limit
                }`
            )
            .then(res => {
                set({
                    priorRunData: res?.data?.data,
                    limit: res?.data?.meta?.limit,
                    page: res?.data?.meta?.page,
                    total_records: res?.data?.meta?.total_records,
                });
            })
            .catch(err =>
                console.error(
                    "Couldn't show run histories of the Dataflow",
                    err
                )
            );
    },
    runHistoryId: async () => {
        return await axios
            .get(`dataflows/${get().id}/job/${get().dataflow_job_id}`)
            .then(res => {
                set({
                    node_status: res.data?.data?.status,
                    lastRunStatus: res.data?.data?.status,
                    node_configs: res.data?.data?.job_nodes?.map(
                        node => node.node_config
                    ),
                });
                localStorage.setItem(
                    'node_status',
                    JSON.stringify(res.data?.data?.status)
                );
                return res.data?.data;
            })
            .catch(err =>
                console.error(
                    "Couldn't show run histories of the specific Dataflow",
                    err
                )
            );
    },
    getVersions: async (page, limit) => {
        await axios
            .get(
                `dataflows/${get().id}/versions/?page=${page || 1}&limit=${
                    limit || get().limit
                }`
            )
            .then(res => {
                set({
                    versionsData: res?.data?.data,
                    limit: res?.data?.meta?.limit,
                    page: res?.data?.meta?.page,
                    total_records: res?.data?.meta?.total_records,
                });
            })
            .catch(err =>
                console.error('Unable to load Dataflow versions.', err)
            );
    },
    getVersionDetails: async () => {
        return await axios
            .get(`dataflows/${get().id}/versions/${get().dataflow_version_id}`)
            .then(res => {
                set({
                    node_configs: res.data?.data?.node_configs,
                });
                return res.data?.data;
            })
            .catch(err =>
                console.error(
                    'Unable to load version details of the specific Dataflow.',
                    err
                )
            );
    },
    getNodeTypes: async () => {
        await axios
            .get('dataflows/node-templates/')
            .then(res => {
                console.log(res, 'getNodeTypes');
                set({
                    node_subtypes: res.data,
                });
            })
            .catch(err =>
                console.error("Couldn't fetch dataflow node templates")
            );
    },
}));
