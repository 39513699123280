import React, { useEffect, useState } from 'react';
import { Row, Col, Collapse, Divider, Space, message } from 'antd';
import BookmarksWidget from 'common/Widgets/BookmarksWidget';
import ViewedHistoryWidget from 'common/Widgets/ViewedHistoryWidget';
import DashboardWidget from 'common/Widgets/DashboardWidget';
import RecentDashboards from 'common/Widgets/RecentDashboards';
import { useDashboardStore } from 'store/dashboards';
import { useUserStore } from 'store/user';

export default function Home() {
    const { getDashboards } = useDashboardStore();
    const { username } = useUserStore();
    const [collapseKeys, setCollapseKeys] = useState(['healthCheck']);
    const [healthCheckDashboards, setHealthCheckDashboards] = useState([]);
    const [metricDashboards, setMetricDashboards] = useState([]);
    const [vaDashboards, setVaDashboards] = useState([]);

    useEffect(() => {
        if (!username) return;
        const getHealthCheckDashboards = () => getDashboards('health_check');
        getHealthCheckDashboards()
            .then(res => setHealthCheckDashboards(res?.data?.data || []))
            .catch(() => message.error("Couldn't fetch dashboards!"));

        const getMetricDashboards = () => getDashboards('home');
        getMetricDashboards()
            .then(res => setMetricDashboards(res?.data?.data || []))
            .catch(() => message.error("Couldn't fetch dashboards!"));

        const getVaDashboards = () => getDashboards('va_health_check');
        getVaDashboards()
            .then(res => setVaDashboards(res?.data?.data || []))
            .catch(() => message.error("Couldn't fetch dashboards!"));
    }, []);

    return (
        <Space
            split={<Divider />}
            direction="vertical"
            style={{ width: '100%' }}
        >
            <Collapse activeKey={collapseKeys} onChange={setCollapseKeys}>
                {healthCheckDashboards.slice(0, 1).map(dashboard => (
                    <Collapse.Panel
                        key="healthCheck"
                        header={'Health Check Dashboard'}
                    >
                        <Col style={{ height: '100vh' }}>
                            <DashboardWidget dashboard={dashboard} />
                        </Col>
                    </Collapse.Panel>
                ))}
            </Collapse>
            {metricDashboards.slice(0, 2).map((dashboard, idx) => (
                <Collapse defaultActiveKey={['1']}>
                    <Collapse.Panel header={`Metrics ${idx + 1}`} key="1">
                        <Col
                            style={{ height: '50vh' }}
                            key={`metrics_${idx}`}
                            span={24}
                        >
                            <DashboardWidget dashboard={dashboard} />
                        </Col>
                    </Collapse.Panel>
                </Collapse>
            ))}
            <Row justify="center" align="stretch" gutter={16}>
                <Col span={8}>
                    <BookmarksWidget />
                </Col>
                <Col span={8}>
                    <ViewedHistoryWidget />
                </Col>
                <Col span={8}>
                    <RecentDashboards />
                </Col>
            </Row>
            <Collapse defaultActiveKey={['1']}>
                {vaDashboards.slice(0, 1).map(dashboard => (
                    <Collapse.Panel header={'VA Health Check'} key="1">
                        <Col style={{ height: '100vh' }}>
                            <DashboardWidget dashboard={dashboard} />
                        </Col>
                    </Collapse.Panel>
                ))}
            </Collapse>
        </Space>
    );
}
