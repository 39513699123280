import create from 'zustand';
import axios from 'services/api';
import { message } from 'antd';
import { convertParamsIntoParamString } from 'utils/utils';
export const useContentModuleStore = create((set, get) => ({
    title: '',
    id: '',
    content_id: '',
    content_doc_id: '',
    categories: [],
    order_num: 1,
    page: 1,
    limit: 10,
    selectedItem: -1,
    totalItems: 0,
    importInfo: {},
    exportInfo: {},
    exportModal: false,
    content_module_item_id: null,
    content_module_item_id_list: [],
    description: '',
    tag_options: [],
    loading: false,
    module,
    module_type: '',
    additional_info: '',
    author: '',
    editor: false,
    createUploadModule: false,
    moduleItems: [],
    module_items_metadata: [],
    moduleContentItems: [],
    fileItem: [],
    showSearchedFile: false,
    showCreateModuleButton: false,
    showModuleItemAction: '',
    searchValue: '',
    isNoteModal: false,
    createdNoteInfo: {},
    notestitle: '',
    isAvailableInModuleItemsMetadata: false,
    moduleInfo: {},
    titleChanged: false,
    isCreateModule: true,
    previousContentType: '',
    setPreviousContentType: previousContentType => {
        set({ previousContentType });
    },
    setTitleChanged: titleChanged => {
        set({ titleChanged });
    },
    setIsCreateModule: isCreateModule => {
        set({ isCreateModule });
    },
    setIsAvailableInModuleItemsMetadata: val => {
        set({
            isAvailableInModuleItemsMetadata: val,
        });
    },
    setCreatedNoteInfo: createdNoteInfo => {
        set({
            createdNoteInfo,
        });
    },
    setPage: async val => {
        set({
            page: val,
        });
        await get().moduleList();
    },
    setLimit: val => {
        set({
            limit: val,
        });
    },
    setExportInfo: val => {
        set({
            exportInfo: val,
        });
    },
    setExportModal: val => {
        set({
            exportModal: val,
        });
    },
    setCategories: val => {
        set({
            categories: val,
        });
    },
    setNoteModal: val => {
        set({
            isNoteModal: val,
        });
    },
    setShowCreateModuleButton: val => {
        set({
            showCreateModuleButton: val,
        });
    },
    setShowSearchedFile: val => {
        set({
            showSearchedFile: val,
        });
    },
    SetShowModuleItemAction: val => {
        set({
            showModuleItemAction: val,
        });
    },
    setLoading: val => {
        set({
            loading: val,
        });
    },
    toggleCreateModule: () => {
        set(state => ({
            createUploadModule: !state.createUploadModule,
        }));
    },
    toggleEditor: () => {
        set(state => ({ editor: !state.editor }));
    },
    setEditor: val => {
        set({ editor: val });
    },
    setDescription: description => {
        set({
            description,
        });
    },
    setSelectedItem: selectedItem => {
        set({
            selectedItem,
        });
    },
    setContentModuleItemId: value => {
        set({
            content_module_item_id: value,
        });
    },
    setContentModuleItemIdList: async value => {
        let tmp_content_module_item_id_list = get().content_module_item_id_list;
        if (value.checked) {
            tmp_content_module_item_id_list.push(value.id);
        } else {
            tmp_content_module_item_id_list =
                tmp_content_module_item_id_list.filter(item => {
                    return item != value.id;
                });
        }
        set({
            content_module_item_id_list: tmp_content_module_item_id_list,
        });
    },
    setModuleContentItems: value => {
        set({
            moduleContentItems: value,
        });
    },
    setTitle: title => {
        set({
            title,
        });
    },
    setNotesTitle: val => {
        set({
            notestitle: val,
        });
    },

    setModuleType: async module_type => {
        set({
            module_type,
        });
    },

    setModuleId: async id => {
        set({
            id,
        });
    },
    setModule: module => {
        set({
            module,
        });
    },
    moduleItemMetaDataConcat: async val => {
        const temp_module_items_metadata = get().module_items_metadata;

        set({
            module_items_metadata: temp_module_items_metadata
                ? [val].concat(temp_module_items_metadata)
                : [val],
        });
        await get().moduleContentItemsUpdate(get().module_items_metadata);
    },

    moduleContentItemsUpdate: async list => {
        const temp_module_content_items = list;

        set({
            moduleContentItems: temp_module_content_items.map((val, index) => {
                return {
                    content_id: val.content_id,
                    doc_id: val.doc_id,
                    order_num: index + 1,
                };
            }),
        });
    },
    moduleItemMetaData: async id =>
        await axios
            .get(`/modules/${id}`)
            .then(res => {
                set({
                    moduleInfo: res.data?.data?.module_metadata,
                    module_items_metadata:
                        res.data?.data?.module_items_metadata,
                    moduleContentItems:
                        res.data?.data?.module_items_metadata?.map(
                            (val, index) => {
                                return {
                                    content_id: val?.content_id,
                                    doc_id: val?.doc_id,
                                    order_num: val?.order_num || index,
                                };
                            }
                        ),
                    id: res.data?.data?.module_metadata?._id,
                    title: res.data?.data?.module_metadata?.title,
                    module_type: res.data?.data?.module_metadata?.module_type,
                    categories: res.data?.data?.module_metadata?.categories,
                    description: res.data?.data?.module_metadata?.description,
                    content_id: res.data?.data?.module_metadata?.content_id,
                });
                localStorage.setItem(
                    'module_note_content',
                    res.data?.data.module_metadata?.description
                );
            })
            .catch(err => console.error(err?.response?.data?.error_message)),

    moduleList: async (
        search_query = '',
        page = get().page,
        limit = get().limit,
        sort = 'desc_created',
        created__gte = '',
        created__lte = '',
        updated__gte = '',
        updated__lte = ''
    ) => {
        let params = {
            search_query,
            sort,
            page,
            limit,

            created__gte,
            created__lte,
            updated__gte,
            updated__lte,
        };

        await axios
            .get(`/modules/${convertParamsIntoParamString({ ...params })}`)
            .then(res => {
                set({
                    moduleItems: res.data?.data,
                    page: res.data?.meta.page,
                    limit: res.data?.meta.limit,
                    totalItems: res.data?.meta.total_records,
                    loading: false,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message));
    },
    createModule: async (categories, method) => {
        let data = {
            title: get().title,
            description: localStorage.getItem('module_note_content'),
            module_type: get().module_type,
            categories: categories,
        };
        if (method) {
            data['method'] = method;
        }
        await axios
            .post(`/modules/`, data)
            .then(res => {
                message.success(
                    `Module with title 
                    ${res.data?.data?.title}
                         created successfully`
                );
                get().moduleList();
                set({ title: '' });
                localStorage.removeItem('module_note_content');
                localStorage.removeItem('is_notes_edited');
            })
            .catch(err => console.error(err?.response?.data?.error_message));
    },
    saveModule: async (id, method) => {
        let data = {
            module_metadata: {
                description: localStorage.getItem('module_note_content'),
                title: get().title,
                module_type: get().module_type,
                categories: get().categories,
            },
            module_items: get().moduleContentItems,
        };
        if (method) {
            data['method'] = method;
        }
        await axios
            .put(`/modules/${id}`, data)
            .then(async res => {
                message.success('Modules saved successfully');
                set({ title: '', page: 1 });
                await get().moduleList();
            })
            .catch(err => console.error(err?.response?.data?.error_message));
    },
    deleteModule: async id =>
        await axios
            .delete(`/modules/${id}/`)
            .then(res => {
                message.success('Module deleted');
                get().moduleList();
                set({ title: '' });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    deleteContentItem: async () => {
        let tmp_content_module_item_id = get().content_module_item_id_list;
        let tmp_module_items_metadata = get().module_items_metadata.filter(
            item => !tmp_content_module_item_id.includes(item.content_id)
        );

        set({
            module_items_metadata: tmp_module_items_metadata,
            moduleContentItems: tmp_module_items_metadata.map(val => {
                return {
                    content_id: val.content_id,
                    doc_id: val.doc_id,
                    order_num: val.order_num,
                };
            }),
        });
    },
    createNote: async () =>
        await axios
            .post(`/notes/`, {
                content: localStorage.getItem('module_note_content'),
                title: get().notestitle,
            })
            .then(res => {
                message.success('Notes created');

                set({
                    notestitle: '',
                    createdNoteInfo: res.data.data,
                    isNoteModal: false,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    getModuleItems: async moduleId => await axios.get(`/modules/${moduleId}`),
    exportModule: async id =>
        await axios
            .post(`/modules/module-export/${id}/`)
            .then(res => {
                set({
                    exportInfo: res.data.data,
                });
            })
            .catch(err => {
                set({
                    exportInfo: err?.response?.data,
                });
                console.error(err?.response?.data?.error_message);
            }),
    importModule: async formData =>
        await axios
            .post(`/modules/module-import/`, formData)
            .then(res => {
                set({
                    importInfo: res.data.data,
                });
                message.success('Module imported successfully.');
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
}));
