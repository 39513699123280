import { Descriptions } from 'antd';
import CommonAutoTriggerDetails from './CommonAutoTriggerDetails';

export default function FileTriggerDetails({ config, showSensorValues }) {
    const automatic = { ...config?.config?.automatic?.config };
    const defaults = config?.params?.[0]?.default;
    return (
        <>
            <CommonAutoTriggerDetails
                config={config}
                showSensorValues={showSensorValues}
            >
                <Descriptions.Item label={defaults?.[0]?.label} span={12}>
                    {automatic?.[defaults?.[0]?.field]}
                </Descriptions.Item>
                <Descriptions.Item label={defaults?.[1]?.label} span={12}>
                    {automatic?.[defaults?.[1]?.field]}
                </Descriptions.Item>
                <Descriptions.Item label={defaults?.[2]?.label} span={12}>
                    {automatic?.[defaults?.[2]?.field]}
                </Descriptions.Item>
                <Descriptions.Item label={defaults?.[3]?.label} span={12}>
                    {Boolean(automatic?.[defaults?.[3]?.field]).toString()}
                </Descriptions.Item>
            </CommonAutoTriggerDetails>
        </>
    );
}
