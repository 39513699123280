import React from 'react';
import CustomCodeEditor from 'common/CustomCodeEditor';

export default function CustomVisualize({ config }) {
    return (
        <CustomCodeEditor
            value={config?.config?.custom_visualize?.codeblock}
            language={config?.config?.custom_visualize?.mode}
            readOnly={true}
            fontSize={'1.5vh'}
        />
    );
}
