import React from 'react';
import FileList from './FileList';
import DataStore from './DataStore';
import FTPSource from './FTPSource';
import ExternalApi from './ExternalApi';
import FilePath from './FilePath';
export default function DataSource({ config }) {
    const param = config?.params?.[0];
    switch (param?.sub_type) {
        case 'filelist':
        case 'library':
            return <FileList config={config} paramLabel={param?.label} />;
        case 'datastore':
            return <DataStore config={config} />;
        case 'ftp':
            return <FTPSource config={config} />;
        case 'external_api':
            return <ExternalApi config={config} />;
        case 'file_path':
            return <FilePath config={config} />;

        default:
            return <></>;
    }
}
