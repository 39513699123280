import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import './style.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { GridChartsModule } from '@ag-grid-enterprise/charts-enterprise';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { useState, useMemo, useCallback, useEffect, memo } from 'react';
import useContentViewerStore from 'store/contentViewer';
import { useThemeStore } from 'store/theme';
import useDataTablesStore from 'store/dataTables';
import SamplingToolPanel from './SamplingToolPanel';
import DateTimeFilter from './DateTimeFilter';
import useDFAStore from 'store/dataflowAuth';
import usePreview from 'components/DFA/Dataflow/usePreview';

ModuleRegistry.registerModules([
    ServerSideRowModelModule,
    GridChartsModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    RangeSelectionModule,
    RowGroupingModule,
    SetFilterModule,
    MultiFilterModule,
]);

const valueFormatter = params => {
    if (typeof params?.value === 'number' && params?.value % 1 !== 0) {
        return Math.round(params?.value * 10000) / 10000;
    }
    return params?.value;
};

const filterType = {
    text: 'agTextColumnFilter',
    number: 'agNumberColumnFilter',
    dateString: 'agDateColumnFilter',
};

function ServerSideDataTableViewer({
    content,
    isDataflowNodePreview = false,
    packetId,
}) {
    const { dataflow, selectedNodeId } = useDFAStore(state => ({
        dataflow: state.dataflow,
        selectedNodeId: state.selectedNodeId,
    }));
    const [setPreviewConfig] = usePreview(dataflow?.dataflow_id);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [gridVisible, setGridVisible] = useState(true);
    const [colDefs, setColDefs] = useState([]);
    const [samplingDetails, setSamplingDetails] = useState({
        method: { type: 'raw' },
    });
    const { open, dataTableViewerState, setDataTableViewerState } =
        useContentViewerStore(state => ({
            open: state.open,
            dataTableViewerState: state.dataTableViewerState,
            setDataTableViewerState: state.setDataTableViewerState,
        }));
    const currentTheme = useThemeStore(state => state.theme);
    const getDataTableView = useDataTablesStore(
        state => state.getDataTableView
    );

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
        };
    }, []);

    const onGridReady = useCallback(
        params => {
            if (params?.api?.getColumnDefs()?.length === 0) {
                params.api.closeToolPanel();
            }
        },
        [open]
    );

    const onGridStateUpdated = useCallback(params => {
        setDataTableViewerState(params.state);
    }, []);

    const handleColDefs = (details, dataTypes, params) => {
        if (params?.api?.getColumnDefs()?.length === 0) {
            setColDefs(
                Object.keys(details[0] ?? {}).map(column => {
                    const resultColDefs = {
                        cellDataType: dataTypes?.[column] ?? true,
                        valueFormatter: valueFormatter,
                        field: column,
                        headerName: column,
                        sortable: true,
                        filter:
                            filterType?.[dataTypes?.[column]] ??
                            'agTextColumnFilter',
                        filterParams: {
                            maxNumConditions: 1,
                        },
                        enableRowGroup: true,
                        enableValue: true,
                        enablePivot: true,
                    };

                    if (dataTypes?.[column] === 'boolean') {
                        resultColDefs['cellRenderer'] = params =>
                            params?.value ? 'true' : 'false';
                    }

                    return resultColDefs;
                })
            );
        }
    };

    const dataSource = useMemo(() => {
        return {
            getRows: params => {
                if (isDataflowNodePreview) {
                    const payload = {
                        is_ag_grid: true,
                        sampleModel: samplingDetails,
                        ...params.request,
                    };
                    setPreviewConfig(
                        dataflow?.last_run_status?.dataflow_run_id,
                        selectedNodeId,
                        payload
                    ).then(res => {
                        if (res) {
                            const details =
                                (res?.data?.packets ?? []).find(
                                    packet => packet?.packet_id === packetId
                                )?.packet_info ?? [];
                            const dataTypes = res?.data?.data_types ?? null;
                            const pivotResultFields =
                                (res?.data?.packets ?? []).find(
                                    packet => packet?.packet_id === packetId
                                )?.meta?.pivot_cols ?? null;
                            handleColDefs(details, dataTypes, params);
                            params.success({
                                rowData: details,
                                pivotResultFields,
                            });
                        } else {
                            params.fail();
                        }
                    });
                } else {
                    getDataTableView({
                        tableId: content?.doc_id ?? content?._id,
                        payload: {
                            is_ag_grid: true,
                            sampleModel: samplingDetails,
                            ...params.request,
                        },
                    })
                        .then(res => {
                            const details = res?.data?.data ?? [];
                            const dataTypes =
                                res?.data?.meta?.data_types ?? null;
                            const pivotResultFields =
                                res?.data?.meta?.pivot_cols ?? null;

                            handleColDefs(details, dataTypes, params);
                            params.success({
                                rowData: details,
                                pivotResultFields,
                            });
                        })
                        .catch(err => {
                            console.error(err);
                            params.fail();
                        });
                }
            },
        };
    }, [samplingDetails]);

    const handleSampling = useCallback(values => {
        setSamplingDetails({
            method:
                values?.value != null
                    ? {
                          type: values?.type,
                          value: values?.value,
                      }
                    : {
                          type: values?.type,
                      },
        });
    }, []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'sampling',
                    labelDefault: 'Sampling',
                    labelKey: 'sampling',
                    iconKey: 'chart',
                    toolPanel: SamplingToolPanel,
                    toolPanelParams: {
                        handleSampling: handleSampling,
                    },
                },
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
            defaultToolPanel: 'sampling',
        };
    }, []);

    useEffect(() => {
        setDataTableViewerState(null);
    }, []);

    useEffect(() => {
        setGridVisible(false);
        setTimeout(() => {
            setGridVisible(true);
        });
    }, [open]);

    const components = useMemo(() => ({ agDateInput: DateTimeFilter }), []);

    return (
        <div
            className={
                currentTheme === 'light'
                    ? 'ag-theme-quartz'
                    : 'ag-theme-quartz-dark'
            }
            style={gridStyle}
        >
            {gridVisible && (
                <AgGridReact
                    rowModelType={'serverSide'}
                    components={components}
                    serverSideDatasource={dataSource}
                    initialState={dataTableViewerState}
                    onGridReady={onGridReady}
                    onStateUpdated={onGridStateUpdated}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    enableRangeSelection={true}
                    enableCharts={true}
                    alwaysMultiSort={true}
                    sideBar={sideBar}
                    groupDisplayType={'multipleColumns'}
                    suppressFieldDotNotation={true}
                    autoGroupColumnDef={{
                        sortable: false,
                    }}
                    reactiveCustomComponents={true}
                    serverSidePivotResultFieldSeparator={'___'}
                />
            )}
        </div>
    );
}

export default memo(ServerSideDataTableViewer);
