import { Space, Typography } from 'antd';
import React from 'react';
import useContentViewerStore from 'store/contentViewer';

export default function FileList({ config, paramLabel }) {
    const viewContent = useContentViewerStore(state => state.viewContent);
    return (
        <Space direction="vertical">
            {config?.config?.[paramLabel]?.map(file => {
                return (
                    <Typography.Link onClick={() => viewContent(file)}>
                        {file?.title}
                    </Typography.Link>
                );
            })}
            {config?.config?.shared_file_list?.map(file => {
                return <Typography.Text>{file?.title}</Typography.Text>;
            })}
        </Space>
    );
}
