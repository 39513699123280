import React, { useEffect, useRef, useState } from 'react';
import {
    Divider,
    Button,
    Typography,
    Tabs,
    Skeleton,
    Descriptions,
    Checkbox,
} from 'antd';
import { ArrowsAltOutlined } from '@ant-design/icons';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import HtmlParser from 'html-react-parser';
import useDFAStore from 'store/dataflowAuth';
import { useUserStore } from 'store/user';
import {
    jobStatus,
    removeUnderscore,
    convertSecondsToTimeString,
} from 'utils/utils';
import ViewOnlyWorkflow from './ViewOnly';
import NodeDetails from './NodeDetails';
import { useLocation } from 'react-router-dom';

const PriorRunDetails = ({ dataflowId, runId }) => {
    const fetchUserDetails = useUserStore(state => state.fetchUserDetails);
    const {
        getCustomRuntimeById,
        getRunHistoryById,
        priorRunSelectedNode,
        setPriorRunSelectedNode,
        getDataflowJob,
    } = useDFAStore(state => ({
        getCustomRuntimeById: state.getCustomRuntimeById,
        getRunHistoryById: state.getRunHistoryById,
        priorRunSelectedNode: state.priorRunSelectedNode,
        setPriorRunSelectedNode: state.setPriorRunSelectedNode,
        getDataflowJob: state.getDataflowJob,
    }));

    const [reducedDatafalowDetails, setReducedDataflowDetails] = useState({
        nodes: [],
        edges: [],
    });
    const [loading, setLoading] = useState(false);
    const panelGroupRef = useRef(null);
    const location = useLocation();
    const locationHasJob = location?.pathname?.includes('job');
    const tabItems = [
        {
            key: 'run_details',
            tab: 'Run Details',
            label: 'Run Details',
            children: (
                <Descriptions
                    bordered
                    column={1}
                    size={'small'}
                    layout={'horizontal'}
                >
                    {[
                        { key: 'description' },
                        { key: 'created', label: 'Run At' },
                        { key: 'created_by', label: 'Run By' },
                        { key: 'run_duration' },
                        { key: 'env', label: 'Environment' },
                        { key: 'runtime_data_store' },
                        { key: 'run_on_save' },
                        { key: 'is_editable' },
                        { key: 'is_enabled', label: 'Auto Triggers' },
                        { key: 'event_pairing', label: 'Pair Trigger Events' },
                        { key: 'tags' },
                    ]
                        .filter(
                            ({ key }) =>
                                reducedDatafalowDetails[key] !== undefined
                        )
                        .map(({ key, label }) => {
                            switch (typeof reducedDatafalowDetails[key]) {
                                case 'boolean':
                                    return (
                                        <Descriptions.Item
                                            label={
                                                label || removeUnderscore(key)
                                            }
                                        >
                                            <Checkbox
                                                checked={
                                                    reducedDatafalowDetails[key]
                                                }
                                                disabled
                                            />
                                        </Descriptions.Item>
                                    );

                                default:
                                    return (
                                        <Descriptions.Item
                                            label={
                                                label || removeUnderscore(key)
                                            }
                                        >
                                            {/* {HTMLReactParser(
                                                reducedDatafalowDetails[key]
                                            )} */}
                                            {HtmlParser(
                                                reducedDatafalowDetails[key] ||
                                                    ''
                                            )}
                                        </Descriptions.Item>
                                    );
                            }
                        })}
                </Descriptions>
            ),
        },
        {
            key: 'node_details',
            tab: 'Node Level Details',
            label: 'Node Level Details',
            children: (
                <>
                    <NodeDetails
                        dataflowId={reducedDatafalowDetails.dataflow_id}
                        nodes={reducedDatafalowDetails.nodes?.map(
                            node => node.data
                        )}
                        selectedNodeId={priorRunSelectedNode}
                        setSelectedNodeId={setPriorRunSelectedNode}
                    />
                </>
            ),
        },
    ];

    useEffect(async () => {
        if (runId) {
            setLoading(true);
            setPriorRunSelectedNode('');
            const runHistoryResponse = await getRunHistoryById(
                dataflowId,
                runId
            );
            if (runHistoryResponse) {
                const resArr = await Promise.allSettled([
                    runHistoryResponse.created_by &&
                        fetchUserDetails(runHistoryResponse.created_by),
                    runHistoryResponse.custom_runtime_id &&
                        getCustomRuntimeById(
                            runHistoryResponse.custom_runtime_id
                        ),
                ]);
                const [
                    userDetails = { firstname: 'Run Automatically' },
                    envDetails = {
                        name: 'Default',
                        python_version: '3.9',
                        id: 0,
                        action: 'install',
                        status: 'successful',
                    },
                ] = resArr.map(res => res.value?.data?.data);
                setReducedDataflowDetails({
                    ...runHistoryResponse,
                    env: `${envDetails.name} (${envDetails.python_version})`,
                    run_duration: convertSecondsToTimeString(
                        runHistoryResponse.run_duration
                    ),
                    created_by: [
                        userDetails.firstname,
                        userDetails.lastname,
                    ].join(' '),
                    created: new Date(
                        runHistoryResponse.created
                    )?.toLocaleString(
                        {},
                        {
                            dateStyle: 'medium',
                            timeStyle: 'short',
                        }
                    ),
                    is_enabled:
                        runHistoryResponse.is_enabled ??
                        (runHistoryResponse.has_triggers ? false : 'N/A'),
                    tags: runHistoryResponse.tags?.join(', ') || 'none',
                    nodes:
                        runHistoryResponse.job_nodes?.map(node => ({
                            id: node.dataflow_node_id,
                            type: node.node_type,
                            data: {
                                ...node,
                                viewOnly: true,
                            },
                            position: {
                                x: node.position?.at(0),
                                y: node.position?.at(1),
                            },
                        })) || [],
                    edges:
                        runHistoryResponse.job_edges
                            ?.filter(
                                edge =>
                                    edge.source_node_id && edge.target_node_id
                            )
                            ?.map(edge => ({
                                id: edge.dataflow_edge_id,
                                source: edge.source_node_id,
                                target: edge.target_node_id,
                                type: 'CUSTOM_EDGE',
                                data: edge,
                            })) || [],
                });
                setPriorRunSelectedNode(
                    runHistoryResponse.job_nodes?.[0]?.dataflow_node_id
                );
            }
            setLoading(false);
        }
    }, [runId]);

    return (
        <>
            <Skeleton loading={loading}>
                {locationHasJob && (
                    <>
                        <div style={{ float: 'right', marginBottom: '0.5rem' }}>
                            {jobStatus(reducedDatafalowDetails?.status)}
                        </div>
                        <Divider style={{ margin: '0.6rem 0' }} />
                    </>
                )}
                <PanelGroup direction="horizontal" ref={panelGroupRef}>
                    <Panel
                        minSize={0}
                        order={1}
                        defaultSize={50}
                        style={{
                            overflow: 'scroll',
                        }}
                    >
                        {!locationHasJob && (
                            <Typography.Title
                                level={5}
                                style={{ margin: '0.5rem 1rem' }}
                                ellipsis={{ rows: 1, tooltip: true }}
                            >
                                {reducedDatafalowDetails?.run_name}
                            </Typography.Title>
                        )}
                        <ViewOnlyWorkflow
                            dataflow={reducedDatafalowDetails}
                            setSelectedNodeId={setPriorRunSelectedNode}
                        />
                    </Panel>
                    <PanelResizeHandle style={{ position: 'relative' }}>
                        <Divider
                            type="vertical"
                            style={{ height: '100%', width: '4px' }}
                        />
                        <Button
                            style={{
                                position: 'absolute',
                                top: '60%',
                                left: '-2px',
                                zIndex: 10,
                            }}
                            shape="circle"
                            size="small"
                            icon={<ArrowsAltOutlined rotate={45} />}
                            onDoubleClick={() =>
                                panelGroupRef.current.setLayout([50, 50])
                            }
                        />
                    </PanelResizeHandle>
                    <Panel
                        minSize={0}
                        defaultSize={50}
                        order={2}
                        style={{
                            overflow: 'scroll',
                        }}
                    >
                        <Tabs defaultActiveKey="run_logs" items={tabItems} />
                    </Panel>
                </PanelGroup>
            </Skeleton>
        </>
    );
};
export default PriorRunDetails;
