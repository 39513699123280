import { Table, Descriptions, Typography } from 'antd';

const transitoryVariablesColumns = [
    {
        title: 'Variable',
        dataIndex: 'key',
        key: 'key',
        width: '50%',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: '50%',
    },
];

const pairingParameterColumns = [
    {
        title: 'Parameter',
        dataIndex: 'key',
        key: 'key',
        width: '50%',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: '50%',
    },
];

function ManualRunDetails({ config }) {
    const { manual } = config?.config || {};
    return (
        <Descriptions
            layout="horizontal"
            bordered
            size="small"
            style={{ marginTop: 16 }}
            labelStyle={{ width: '40%' }}
            contentStyle={{ width: '60%' }}
        >
            <Descriptions.Item span={12}>
                <Typography.Text strong>
                    Manual Run Configuration
                </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Transitory Variables" span={12}>
                {Array.isArray(manual?.transitory_params) &&
                manual?.transitory_params?.length > 0 ? (
                    <Table
                        size="small"
                        dataSource={manual?.transitory_params}
                        columns={transitoryVariablesColumns}
                        pagination={false}
                        style={{ width: '100%' }}
                        scroll={{ x: true }}
                        bordered
                    />
                ) : (
                    'N/A'
                )}
            </Descriptions.Item>

            <Descriptions.Item label="Pairing Parameters" span={12}>
                {Array.isArray(manual?.pairing_params) &&
                manual?.pairing_params?.length > 0 ? (
                    <Table
                        size="small"
                        dataSource={manual?.pairing_params}
                        columns={pairingParameterColumns}
                        pagination={false}
                        scroll={{ x: true }}
                        bordered
                    />
                ) : (
                    'N/A'
                )}
            </Descriptions.Item>
        </Descriptions>
    );
}

export default ManualRunDetails;
