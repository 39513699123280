import { Typography } from 'antd';
import React from 'react';

const customStyle = { display: 'flex', gap: '.5em' };

const capitalize = text =>
    text?.slice(0, 1).toUpperCase() + text.slice(1)?.toLowerCase();

export default function DataResampling({ config }) {
    const data_resampling_config = config?.data_resampling;
    return (
        <div style={{ ...customStyle, flexDirection: 'column' }}>
            <div style={customStyle}>
                <Typography.Text strong>Index Column:</Typography.Text>
                {data_resampling_config?.index_column}
            </div>
            <div style={customStyle}>
                <Typography.Text strong>Time Interval:</Typography.Text>
                {Object.keys(data_resampling_config?.time_interval)
                    ?.map(
                        timeUnit =>
                            `${
                                data_resampling_config?.time_interval?.[
                                    timeUnit
                                ]
                            } ${capitalize(timeUnit?.slice(0, -1) + '(s)')}`
                    )
                    .join(', ')}
            </div>
            <div style={customStyle}>
                <Typography.Text strong>Sampling Type:</Typography.Text>
                {capitalize(data_resampling_config?.sampling_type)}
            </div>
            <div style={customStyle}>
                <Typography.Text strong>Sampling Method:</Typography.Text>
                {capitalize(data_resampling_config?.sampling_method)}
            </div>
        </div>
    );
}
