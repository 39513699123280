import create from 'zustand';
import axios from 'services/api';
import { message } from 'antd';

export const useWorkbenchStore = create((set, get) => ({
    workbenchList: [],
    workbenchListMeta: {},
    getWorkbenchList: async (query = '', page = 1, limit = 20) => {
        return await axios
            .get(`/workbench?query=${query}&page=${page}&limit=${limit}`)
            .then(res => res.data)
            .catch(e => message.error("Couldn't load workbench list"));
    },
    setWorkbenchList: (list, meta) =>
        set({ workbenchList: list, workbenchListMeta: meta }),

    workbench: { nodes: [], edges: [] },
    getWorkbench: async id => {
        return await axios
            .get(`/workbench/${id}`)
            .then(res => res.data?.data)
            .catch(e => message.error("Couldn't load workbench details"));
    },
    setWorkbench: data =>
        set({
            workbench: {
                nodes: [],
                edges: [],
                ...data,
            },
        }),
    addWorkbench: async workbenchData =>
        await axios
            .post('/workbench', {
                ...workbenchData,
            })
            .then(res => res.data?.data)
            .catch(e => message.error("Couldn't create workbench")),
    deleteWorkbench: async id => {
        return await axios
            .delete(`/workbench/${id}`)
            .then(res => res.data?.data)
            .catch(e => message.error("Couldn't delete workbench"));
    },

    isUpdatingWorkbench: false,
    setIsUpdatingWorkbench: flag => set({ isUpdatingWorkbench: flag }),

    nodeTemplates: {},
    getNodeTemplates: async () =>
        await axios
            .get('/workbench/node-templates/')
            .then(res => {
                set({
                    nodeTemplates: Array.isArray(res.data?.data)
                        ? res.data.data.reduce(
                              (acc, curr) => ({
                                  ...acc,
                                  [`${curr.type}_${curr.sub_type}`]: curr,
                              }),
                              {}
                          )
                        : {},
                });
            })
            .catch(e => message.error("Couldn't load node templates")),
    toolTemplates: [],
    getToolTemplates: async () =>
        await axios
            .get('/workbench/tools/')
            .then(res => {
                set({
                    toolTemplates: res.data?.data.map(template => ({
                        ...template,
                        consolidatedType: `${template.name}`,
                    })),
                });
            })
            .catch(e => message.error("Couldn't load tool templates")),

    addNode: async (workbench_id, nodeDetails, customMessage) => {
        // const { workbench, setWorkbench } = get();
        // setWorkbench({
        //     ...workbench,
        //     nodes: [...workbench.nodes, nodeDetails],
        // });
        return await axios
            .post(`/workbench/${workbench_id}/node`, { ...nodeDetails })
            .then(res => {
                message.success(customMessage || 'Created node successfully');
                return res.data?.data;
            })
            .catch(e => message.error("Couldn't add node"));
    },
    deleteNode: async (workbench_id, _id) =>
        await axios
            .delete(`/workbench/${workbench_id}/node/${_id}`)
            .then(res => {
                message.success('Deleted node successfully');
                return res.data?.data;
            })
            .catch(e => message.error("Couldn't delete node")),
    updateNode: async (workbench_id, _id, newDetails) => {
        return await axios
            .put(`/workbench/${workbench_id}/node/${_id}`, {
                ...newDetails,
            })
            .then(res => {
                message.success('Updated node successfully');
                return res.data?.data;
            })
            .catch(e =>
                message.error(
                    e.message || e.errorMessage || "Couldn't update node"
                )
            );
    },

    registerEdge: async (workbench_id, edgeDetails) => {
        return await axios
            .post(`/workbench/${workbench_id}/edge`, {
                ...edgeDetails,
            })
            .then(res => {
                message.success('Added edge successfully');
                return res.data?.data;
            })
            .catch(e => message.error("Couldn't add edge"));
    },
    viewSideDrawer: false,
    toggleSideDrawer: flag =>
        set({ viewSideDrawer: flag || !get().viewSideDrawer }),
    viewingWorkbench: false,
    toggleWorkbenchView: flag =>
        set({ viewingWorkbench: flag || !get().viewingWorkbench }),

    sideDrawer: { content: [], concept: [], tool: [] },
    getSideDrawer: async workbench_id => {
        return axios
            .get(`workbench/${workbench_id}/side-drawer/`)
            .then(res => res.data.data)
            .then(res => {
                set({
                    sideDrawer: res.nodes.reduce(
                        (acc, node) => {
                            switch (node.type) {
                                case 'CONTENT':
                                    acc.content.push(node);
                                    break;
                                case 'CONCEPT':
                                    acc.concept.push(node);
                                    break;
                                case 'TOOL':
                                    acc.tool.push(node);
                                    break;
                            }
                            return acc;
                        },
                        { content: [], concept: [], tool: [] }
                    ),
                    // {
                    //     content: res.nodes.filter(
                    //         node => node.type == 'CONTENT'
                    //     ),
                    //     concept: res.nodes.filter(
                    //         node => node.type == 'CONCEPT'
                    //     ),
                    // },
                });
            });
    },

    placeNewNodesOnCanvas: true,
    setPlaceNewNodesOnCanvas: flag => set({ placeNewNodesOnCanvas: flag }),

    searchResults: [],
    searchMeta: { query: '', page: 1, limit: 20 },
    searchContent: async (
        workbench_id,
        {
            query,
            page = 1,
            limit = 20,
            content_id,
            sortBy = 'dec_relevance',
            categories,
            contentTypes,
        }
    ) => {
        set({ searchMeta: { query, page, limit } });
        if (page == 1) {
            set({ searchResults: [] });
        }
        await axios
            .post(
                `workbench/operations/${workbench_id}/add-content?page=${page}&limit=${limit}&sort=${sortBy}`,
                {
                    query,
                    username: 'admin',
                    filter_facets: {
                        content_id: content_id ? [content_id] : [],
                        content_type: contentTypes || [],
                        category: categories || [],
                    },
                }
            )
            .then(res => {
                const { meta, data: { reply = [] } = {} } = res.data || {};
                console.log('search res', [res.data, reply, meta]);
                set(prev => ({
                    searchResults: [...prev.searchResults, ...reply],
                    searchMeta: { ...meta, query },
                }));
            });
    },
    clearSearchResults: () =>
        set({
            searchResults: [],
            searchMeta: { query: '', page: 1, limit: 20 },
        }),

    parentDetails: {},
    setParentDetails: details => {
        set({ parentDetails: details });
    },

    selectedNodeId: null,
    setSelectedNodeId: id => set({ selectedNodeId: id }),
    selectedNodes: [],
    setSelectedNodes: nodes => set({ selectedNodes: nodes }),
    allNodes: [],
    setAllNodes: nodes => set({ allNodes: nodes }),
    updateTool: async (workbench_id, tool_id, data) => {
        return await axios
            .put(`/workbench/${workbench_id}/node/${tool_id}`, {
                ...data,
            })
            .then(res => {
                message.success('Tool updated');
                // set({ workbench: res.data?.data });
                return res.data?.data;
            })
            .catch(e => console.error("Couldn't update tool"));
    },
    getLatestJobInfo: async (workbench_id, tool_id) => {
        return await axios
            .get(`/workbench/${workbench_id}/node/${tool_id}/latest-job-info`)
            .then(res => {
                // set({ latestJobs: res.data?.data });

                return res.data?.data;
            })
            .catch(e => console.error("Couldn't get tool jobs"));
    },
    previousQuery: null,
    setPreviousQuery: query => set({ previousQuery: query }),
    previousResponse: null,
    setPreviousResponse: response => set({ previousResponse: response }),
    toolEdit: false,
    setToolEdit: flag => set({ toolEdit: flag }),
    tool: {},
    setTool: tool => set({ tool: tool }),
    latestJobs: {},
    setLatestJobs: jobs => set({ latestJobs: jobs }),

    setNodes: null, // Placeholder for the setNodes function

    // Function to set `setNodes` in the store
    setSetNodes: setNodesFn => set(() => ({ setNodes: setNodesFn })),

    enableNodeSelection: false,
    setEnableNodeSelection: flag => set({ enableNodeSelection: flag }),

    subsectionDetails: {
        isSubsection: false,
        selectedText: '',
        quads: [],
        startPage: null,
        endPage: null,
        content: {},
    },

    setSubsectionDetails: ({
        isSubsection,
        selectedText = '',
        quads = [],
        startPage = null,
        endPage = null,
        content = {},
    }) =>
        set({
            subsectionDetails: {
                isSubsection,
                selectedText,
                quads,
                startPage,
                endPage,
                content,
            },
        }),
}));
