import React, { useEffect, useMemo } from 'react';
import { Descriptions } from 'antd';
import useDataConnectionsStore from 'store/dataConnections';
import useDFAStore from 'store/dataflowAuth';
export default function DynamicDestinationTable({ config }) {
    let dataTableNodeConfig = { ...config?.config?.datastore_config };

    const getAllDataConnections = useDataConnectionsStore(
        state => state.getAllDataConnections
    );
    const { setDataConnections, dataConnections } = useDFAStore(state => ({
        dataConnections: state.dataConnections,
        setDataConnections: state.setDataConnections,
    }));

    useEffect(() => {
        (() => {
            if (!dataConnections?.data?.data?.connection_list?.length) {
                getAllDataConnections().then(setDataConnections);
            }
        })();
    }, []);

    const dataConnectionName = useMemo(
        () =>
            dataConnections?.data?.data?.connection_list?.filter(
                connection =>
                    connection._id === dataTableNodeConfig?.data_source
            )?.[0]?.data_connection_name,
        [dataConnections?.data?.data?.connection_list?.length]
    );

    dataTableNodeConfig['data_source'] = dataConnectionName;
    const keyToLabelMapper = {
        ['data_source']: 'Data connection',
        ['crud_operation']: 'Operation',
        ['data_table']: 'Table name',
    };

    return (
        <>
            <Descriptions
                title=""
                bordered
                column={1}
                size={'small'}
                layout={'horizontal'}
            >
                {Object.entries(dataTableNodeConfig).map(entry => {
                    return (
                        <>
                            {!(entry?.[1] === 'object') && (
                                <Descriptions.Item
                                    label={
                                        <b>{keyToLabelMapper?.[entry?.[0]]}</b>
                                    }
                                >
                                    <>{entry?.[1]}</>
                                </Descriptions.Item>
                            )}
                        </>
                    );
                })}
            </Descriptions>
        </>
    );
}
