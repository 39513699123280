export const DEFAULT_ROUTE = '/';
export const HOME_ROUTE = '/home';
export const SEARCH_ROUTE = '/search';
export const ESCALATE_ROUTE = '/escalate';
export const HEALTH_CHECK_ROUTE = '/health-check';
export const EXPLORE_ROUTE = '/explore';
export const HISTORY_ROUTE = '/history';
export const RESOURCES_ROUTE = '/resources';
export const INBOUND_RESOURCES_PATH = '/inbound-resources';
export const UPLOAD_FILES_PATH = '/upload-files';
export const DASHBOARDS_PATH = '/dashboards';
export const NOTES_PATH = '/notes';
export const GLOSSARY_PATH = '/glossary';
export const MODULES_PATH = '/modules';
export const FAQ_PATH = '/faq';
export const GUIDED_SOP_PATH = '/guided-sop';
export const DATA_FLOW_PATH = '/data-flow';
export const DATAFLOW_PATH = '/dataflow';
export const CONNECTIONS_PATH = '/connections';
export const DATA_TABLES_PATH = '/data-tables';
export const RECIPE_GEN_PATH = '/recipe-gen';
export const KEYWORD_MANAGEMENT_ROUTE = '/keyword-management';
export const CATEGORY_MANAGEMENT_ROUTE = '/category-management';
export const BOOKMARKS_ROUTE = '/search/bookmarks';
export const TRASH_PATH = '/trash';
export const WORKBENCH_PATH = '/workbench';
