import React, { useEffect, useState, useRef } from 'react';
import { ArrowsAltOutlined, RightCircleOutlined } from '@ant-design/icons';
import {
    Space,
    Table,
    Typography,
    Badge,
    Tooltip,
    Select,
    Divider,
} from 'antd';
import DataSource from '../NodeDetails/DataSource';
import DataType from '../NodeDetails/DataType';
import DataTransform from '../NodeDetails/DataTransform';
import DataDestination from '../NodeDetails/DataDestination';
import DataVisualize from '../NodeDetails/DataVisualize';
import DataTriggers from '../NodeDetails/DataTriggers';
import DynamicSource from '../NodeDetails/DynamicSource';
import DynamicDestination from '../NodeDetails/DynamicDestination';
import CustomTransform from '../NodeDetails/DataTransform/CustomTransform';
import useRunLogs from '../../useRunLogs';
import usePreview from '../../usePreview';
import { removeUnderscore } from 'utils/utils';

export default function PriorRunNodeDetails({
    dataflowId,
    nodes,
    selectedNodeId,
    setSelectedNodeId,
}) {
    const viewOptions = ['node_configuration', 'preview', 'run_logs'];
    const [selectedSubTab, setSelectedSubTab] = useState('node_configuration');
    const [
        setRunLogsConfig,
        loadMoreRunLogs,
        logsData,
        { loading: runLogsLoading, config: runLogsConfig },
        RunLogs,
    ] = useRunLogs(dataflowId);
    const [
        setPreviewConfig,
        previewData,
        previewMessage,
        previewLoading,
        Preview,
    ] = usePreview(dataflowId);

    useEffect(() => {
        if (!dataflowId || !selectedNodeId) return;
        const selectedNode = nodes.find(
            node => node.dataflow_node_id == selectedNodeId
        );
        // if (selectedSubTab == 'preview')
        setPreviewConfig(
            selectedNode.dataflow_job_id,
            selectedNode.dataflow_job_node_id
        );
        // if (selectedSubTab == 'run_logs')
        setRunLogsConfig({
            jobId: selectedNode.dataflow_job_id,
            jobNodeId: selectedNode.dataflow_job_node_id,
        });
    }, [
        selectedNodeId,
        // selectedSubTab
    ]);

    const NodeDetail = ({ nodeConfig }) => {
        const nodeMap = {
            DATA_SOURCE: () => <DataSource config={nodeConfig} />,
            DATA_TYPE: () => <DataType config={nodeConfig} />,
            DATA_TRANSFORM: () => <DataTransform config={nodeConfig} />,
            DATA_DESTINATION: () => <DataDestination config={nodeConfig} />,
            DATA_INSPECT: () => <DataVisualize config={nodeConfig} />,
            DATA_TRIGGER: () => (
                <DataTriggers config={nodeConfig} showSensorValues={true} />
            ),
            DYNAMIC_SOURCE: () => <DynamicSource config={nodeConfig} />,
            DYNAMIC_DESTINATION: () => (
                <DynamicDestination config={nodeConfig} />
            ),
            CUSTOM_TRANSFORM: () => <CustomTransform config={nodeConfig} />,
        };

        return nodeMap[nodeConfig.node_type]?.() ?? <></>;
    };
    return (
        <>
            <Space split={<Divider type="vertical" />}>
                <div>
                    Selected Node:
                    <Select
                        style={{ minWidth: '150px', marginLeft: '8px' }}
                        value={selectedNodeId}
                        onChange={setSelectedNodeId}
                    >
                        {nodes?.map(node => (
                            <Select.Option value={node.dataflow_node_id}>
                                {node.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div>
                    Viewing:
                    <Select
                        style={{ minWidth: '150px', marginLeft: '8px' }}
                        value={selectedSubTab}
                        onChange={setSelectedSubTab}
                    >
                        {viewOptions?.map(tabKey => (
                            <Select.Option value={tabKey}>
                                {removeUnderscore(tabKey)}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </Space>
            <div style={{ marginTop: '24px' }}>
                {selectedSubTab == 'run_logs' && (
                    <RunLogs
                        data={logsData}
                        loading={runLogsLoading}
                        loadMore={loadMoreRunLogs}
                        setConfig={setRunLogsConfig}
                        config={runLogsConfig}
                    />
                )}
                {selectedSubTab == 'preview' && (
                    <Preview
                        previewData={previewData}
                        loading={previewLoading}
                        message={previewMessage}
                    />
                )}
                {selectedSubTab == 'node_configuration' && (
                    <NodeDetail
                        nodeConfig={
                            [
                                nodes.find(
                                    node =>
                                        node.dataflow_node_id == selectedNodeId
                                ),
                            ]?.map(node => ({
                                ...node,
                                ...node.node_config,
                            }))?.[0]
                        }
                    />
                )}
            </div>
        </>
    );
}
