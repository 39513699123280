import React from 'react';
import { Table } from 'antd';
import CustomCodeEditor from 'common/CustomCodeEditor';

export default function CustomTransform({ config }) {
    return (
        <>
            {!!config?.config?.custom_codeblock?.variables?.length && (
                <Table
                    size="small"
                    columns={[
                        {
                            title: 'Variable Name',
                            key: 'name',
                            dataIndex: 'name',
                        },
                        { title: 'Type', key: 'type', dataIndex: 'type' },
                        { title: 'Value', key: 'value', dataIndex: 'value' },
                    ]}
                    dataSource={config?.config?.custom_codeblock?.variables}
                    pagination={false}
                />
            )}
            <CustomCodeEditor
                value={config?.config?.custom_codeblock?.codeblock}
                language={config?.config?.custom_codeblock?.mode}
                readOnly={true}
                fontSize={'1.5vh'}
            />
        </>
    );
}
