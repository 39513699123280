import React from 'react';
import ExternalApi from './ExternalApi';
import FilePath from './FilePath';

export default function DynamicSource({ config }) {
    const subType = config?.params?.[0]?.sub_type;
    const configs = config?.config;

    switch (subType) {
        case 'external_api':
            return <ExternalApi config={configs} />;
        case 'file_path':
            return <FilePath config={configs} />;

        default:
            return <></>;
    }
}
