import React from 'react';
import { Descriptions } from 'antd';
import { removeUnderscore } from 'utils/utils';

import CustomVisualize from './CustomVisualize';

const VisualizationInfo = ({ configs }) => {
    const vizConfig =
        configs?.visualize_histogram ??
        configs?.visualize_scatter ??
        configs?.visualize_line ??
        configs?.visualize_box;

    return (
        <Descriptions
            title=""
            bordered
            column={1}
            size={'small'}
            layout={'horizontal'}
        >
            {Object.entries(vizConfig || {})?.map(
                entry => (
                    console.log(typeof entry?.[1], entry?.[1]),
                    (
                        <Descriptions.Item
                            label={<b>{removeUnderscore(entry?.[0])}</b>}
                        >
                            {typeof entry?.[1] === 'object'
                                ? entry?.[1]?.map(val => val + ' ')
                                : entry?.[1]}
                        </Descriptions.Item>
                    )
                )
            )}
        </Descriptions>
    );
};

export default function DataVisualize({ config }) {
    const subType = config?.params?.[0]?.sub_type;
    const configs = config?.config;

    if (subType?.startsWith('visualize')) {
        return <VisualizationInfo configs={configs} />;
    } else if (subType === 'insight_report') {
        return <>insight_report</>;
    } else if (subType === 'custom_visualize') {
        return <CustomVisualize config={config} />;
    }
    return null;
}
