import React from 'react';
import FileName from './FileName';
import DataTable from './DataTable';
import FTPDestination from './FTPDestination';
import ExternalApi from './ExternalApi';

export default function DataDestination({ config }) {
    const subType = config?.params?.[0]?.sub_type;
    const configs = config?.config;

    switch (subType) {
        case 'filename':
            return <FileName config={config} />;
        case 'destination_table':
            return <DataTable config={config} />;
        case 'ftp':
            return <FTPDestination config={config} />;
        case 'external_api':
            return <ExternalApi config={configs} />;
        default:
            return <></>;
    }
}
