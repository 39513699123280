import { Button, Layout, Menu, theme as AntTheme } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
    HomeOutlined,
    IssuesCloseOutlined,
    UploadOutlined,
    HeartOutlined,
    LineChartOutlined,
    HistoryOutlined,
    FormOutlined,
    CompassOutlined,
    DashboardOutlined,
    TeamOutlined,
    MonitorOutlined,
    ApiOutlined,
    TableOutlined,
    ToolOutlined,
    BookOutlined,
    ProfileOutlined,
    BranchesOutlined,
    ApartmentOutlined,
    QuestionCircleOutlined,
    FolderOutlined,
    ForkOutlined,
    SearchOutlined,
    LeftOutlined,
    RightOutlined,
    ImportOutlined,
    DeleteOutlined,
    PartitionOutlined,
} from '@ant-design/icons';
import { useUserStore } from 'store/user';
import { useNotesStore } from 'store/notes';
import { useMenuStore } from 'store/menu';
import { useRouteStore } from 'store/routes';

const { Sider } = Layout;

const aliasMenuNameMap = {
    'guided-sop': 'Guided SOP',
    'recipe-gen': 'Dataflow',
    dataflow: 'Dataflows',
};

const expandedMenuItems = new Set();

const capitalize = key =>
    aliasMenuNameMap[key] ||
    key
        .split('-')
        .reduce(
            (prev, current) =>
                `${prev} ${current[0].toUpperCase() + current.slice(1)}`,
            ''
        );

const getSubMenuItems = (subFeatureFlags, featureFlag) => {
    if (subFeatureFlags instanceof Object) {
        expandedMenuItems.add(featureFlag);
        return Object.keys(subFeatureFlags).reduce(
            (subMenuItems, subFeature) => {
                if (!subFeatureFlags[subFeature]) return subMenuItems;
                const subKey = subFeature.toLowerCase().replaceAll('_', '-');
                if (!menuIcons?.[subKey]) return subMenuItems;
                return [
                    ...subMenuItems,
                    {
                        label: capitalize(subKey),
                        key: [featureFlag, subKey].join('/'),
                        icon: menuIcons[subKey],
                    },
                ];
            },
            []
        );
    }
    return false;
};

const menuIcons = {
    home: <HomeOutlined />,
    search: <SearchOutlined />,
    escalate: <IssuesCloseOutlined />,
    'upload-files': <UploadOutlined />,
    'health-check': <HeartOutlined />,
    analytics: <LineChartOutlined />,
    // performance: <RiseOutlined />,
    history: <HistoryOutlined />,
    notes: <FormOutlined />,
    glossary: <BookOutlined />,
    modules: <ApartmentOutlined />,
    faq: <QuestionCircleOutlined />,
    resources: <FolderOutlined />,
    library: <FolderOutlined />,
    connections: <ApiOutlined />,
    'data-tables': <TableOutlined />,
    'guided-sop': <ToolOutlined />,
    'data-flow': <BranchesOutlined />,
    dataflow: <BranchesOutlined />,
    'data-flow-authoring': <ForkOutlined />,
    dashboards: <DashboardOutlined />,
    explore: <CompassOutlined />,
    'user-management': <TeamOutlined />,
    'data-job-management': <MonitorOutlined />,
    nlpq: <ProfileOutlined />,
    dashboards: <DashboardOutlined />,
    'recipe-gen': <BranchesOutlined />,
    'inbound-resources': <ImportOutlined />,
    trash: <DeleteOutlined />,
    workbench: <PartitionOutlined />,
};

const adminFeatureFlags = {
    USER_MANAGEMENT: true,
};

export default function SideMenu() {
    const navigate = useNavigate();
    const location = useLocation();
    const userRole = useUserStore(state => state.userRole);
    const featureFlags =
        JSON.parse(localStorage.getItem('release'))?.MENU ||
        JSON.parse(localStorage.getItem('release'));
    const toggleConfirmModal = useNotesStore(state => state.toggleConfirmModal);
    const setMenuItem = useMenuStore(state => state.setMenuItem);
    const regenerateRouteKey = useRouteStore(state => state.regenerateRouteKey);
    const [collapsed, setCollapsed] = useState(false);
    const [collapsedWidth, setCollapsedWidth] = useState(80);
    const [openKeys, setOpenKeys] = useState([
        location.pathname.slice(1).split('/')?.[0],
        ...expandedMenuItems.keys(),
    ]);
    const [selectedKeys, setSelectedKeys] = useState(() => {
        const path = location.pathname;
        if (path === '/') return ['home'];
        const pathKeys = path.replace('/', '').split('/');
        return !!pathKeys.length
            ? [pathKeys[0], pathKeys.slice(0, 2).join('/')]
            : ['home'];
    });

    const handleTitleClick = ({ key }) => {
        if (location.pathname.slice(1) === key) regenerateRouteKey();
        navigate(key);
        // setOpenKeys([key]);
        setSelectedKeys([key]);
    };

    const getMenuItems = useCallback(
        featureFlags =>
            Object.keys(featureFlags)?.reduce((menuItems, featureFlag) => {
                const key = featureFlag.toLowerCase().replaceAll('_', '-');
                if (!featureFlags[featureFlag]) return menuItems;
                if (!menuIcons?.[key]) return menuItems;
                const subMenuItems = getSubMenuItems(
                    featureFlags[featureFlag],
                    key
                );
                return [
                    ...menuItems,
                    {
                        label: capitalize(key),
                        key,
                        icon: menuIcons[key],
                        children: subMenuItems,
                        onTitleClick: handleTitleClick,
                    },
                ];
            }, []),
        [expandedMenuItems.size]
    );

    useEffect(() => {
        if (collapsed) return;
        setOpenKeys(previousKeys => {
            const finalKeys = new Set();
            [...previousKeys, ...expandedMenuItems.keys()].forEach(key => {
                finalKeys.add(key);
            });
            return [...finalKeys.keys()];
        });
    }, [openKeys.length, expandedMenuItems.size]);

    const items = getMenuItems(featureFlags ?? {});
    const adminItems = getMenuItems(adminFeatureFlags);

    function navigateTo(value, keyPath) {
        setMenuItem(value);
        if (localStorage.getItem('is_notes_edited') == 'true') {
            toggleConfirmModal();
        } else {
            setSelectedKeys(keyPath);
            location.pathname.slice(1) === value
                ? regenerateRouteKey()
                : navigate(`/${value}`);
        }
    }

    const handleOpenChange = openedMenu => {
        if (!collapsed) return;
        setOpenKeys(openedMenu);
    };

    const handleSelect = ({ key, keyPath }) => {
        // if (!keyPath?.[0].split('/').includes(openKeys?.[0])) setOpenKeys([]);
        // setSelectedKeys(keyPath);
        navigateTo(key, keyPath);
    };

    const handleCollapse = () => {
        setCollapsed(collapsed => {
            if (!collapsed) setOpenKeys([]);
            else setOpenKeys([...expandedMenuItems.keys()]);
            // if (collapsed) {
            //     const path = location.pathname.slice(1).split('/');
            //     setOpenKeys([path?.[0]]);
            // }
            return !collapsed;
        });
    };

    useEffect(() => {
        if (!location.pathname.slice(1)) return setSelectedKeys(['home']);
        const currentPathKeys = location.pathname.slice(1).split('/');
        const currentPathArray = currentPathKeys.reduce((keys, key, index) => {
            keys[index] = [...keys, key].join('/');
            return keys;
        }, []);
        if (JSON.stringify(currentPathArray) !== JSON.stringify(selectedKeys)) {
            setSelectedKeys(currentPathArray);
            // !collapsed && setOpenKeys(currentPathArray);
        }
    }, [location.pathname]);

    return (
        <>
            <Sider
                style={{
                    overflowY: 'auto',
                    overflowX: 'clip',
                    height: 'calc(100vh - 60px)',
                }}
                theme="light"
                breakpoint="sm"
                collapsible
                trigger={null}
                collapsedWidth={collapsedWidth}
                collapsed={collapsed}
                width={200}
                onBreakpoint={broken =>
                    broken ? setCollapsedWidth(0) : setCollapsedWidth(50)
                }
            >
                {userRole === 'admin' && location.pathname.includes('admin') ? (
                    <Menu
                        mode="inline"
                        selectedKeys={[
                            location.pathname.split('/')?.[2] ||
                                'user-management',
                        ]}
                        items={adminItems}
                        triggerSubMenuAction="click"
                        onClick={e => navigate(`/admin/${e.key}`)}
                        expandIcon={subMenuProps =>
                            !collapsed ? (
                                <RightOutlined
                                    style={{
                                        fontSize: '10px',
                                        strokeWidth: '50',
                                        stroke: subMenuProps?.isOpen
                                            ? AntTheme.useToken().token
                                                  .colorInfo
                                            : AntTheme.useToken().token
                                                  .colorText,
                                        position: 'absolute',
                                        right: 10,
                                    }}
                                    rotate={subMenuProps?.isOpen ? 90 : 0}
                                />
                            ) : null
                        }
                    />
                ) : (
                    <Menu
                        mode="inline"
                        selectedKeys={selectedKeys}
                        items={items}
                        triggerSubMenuAction="hover"
                        onOpenChange={handleOpenChange}
                        openKeys={openKeys}
                        onClick={handleSelect}
                        expandIcon={subMenuProps =>
                            !collapsed ? (
                                <RightOutlined
                                    style={{
                                        fontSize: '10px',
                                        strokeWidth: '50',
                                        stroke: subMenuProps?.isOpen
                                            ? AntTheme.useToken().token
                                                  .colorInfo
                                            : AntTheme.useToken().token
                                                  .colorText,
                                        position: 'absolute',
                                        right: 10,
                                    }}
                                    rotate={subMenuProps?.isOpen ? 90 : 0}
                                />
                            ) : null
                        }
                    />
                )}
            </Sider>
            <Button
                size="small"
                shape="circle"
                onClick={handleCollapse}
                style={{
                    position: 'relative',
                    left: 0,
                    right: 0,
                    transform: `translate(-13px, 25vh)`,
                    fontSize: '.75em',
                    fontWeight: 'bold',
                    zIndex: 100,
                }}
                icon={!collapsed ? <LeftOutlined /> : <RightOutlined />}
            />
        </>
    );
}
