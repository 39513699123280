import InboundAPIDetails from './InboundAPIDetails';
import FileTriggerDetails from './FileTriggerDetails';
import FtpTriggerDetails from './FtpTriggerDetails';
import SchedulerDetails from './SchedulerDetails';
import SharedContentTriggerDetails from './SharedContentTriggerDetails';
import StreamLogDetails from './StreamLogDetails';
import ManualRunDetails from './ManualRunDetails';

export default function DataTriggers({ config, showSensorValues = false }) {
    // const subType = config?.params?.[0]?.sub_type;
    const subType = config?.config?.automatic?.trigger_service;
    const subTypeMap = {
        inbound_api: (
            <InboundAPIDetails
                config={config}
                showSensorValues={showSensorValues}
            />
        ),
        file_trigger: (
            <FileTriggerDetails
                config={config}
                showSensorValues={showSensorValues}
            />
        ),
        ftp_trigger: (
            <FtpTriggerDetails
                config={config}
                showSensorValues={showSensorValues}
            />
        ),
        scheduler: (
            <SchedulerDetails
                config={config}
                showSensorValues={showSensorValues}
            />
        ),
        shared_content_trigger: (
            <SharedContentTriggerDetails
                config={config}
                showSensorValues={showSensorValues}
            />
        ),
        logstream: (
            <StreamLogDetails
                config={config}
                showSensorValues={showSensorValues}
            />
        ),
    };

    return (
        <>
            <ManualRunDetails config={config} />
            {subTypeMap?.[subType]}
        </>
    );
}
