import {
    Modal,
    Space,
    Typography,
    Dropdown,
    Button,
    Tooltip,
    theme,
} from 'antd';
import React, { useState } from 'react';
import { Handle } from 'reactflow';
import useDFAStore from 'store/dataflowAuth';
import { useDataFlowStore } from 'store/dataflow';
import {
    PlusCircleTwoTone,
    ExclamationCircleOutlined,
} from '@ant-design/icons';

const getNodeStatusColor = status => {
    switch (status) {
        case 'FAILED':
            return 'orangered';
        case 'COMPLETED':
            return 'limegreen';
        case 'RUNNING':
            return 'dodgerblue';
        case 'INIT':
        default:
            return '';
    }
};


export const DeleteConfirmationModal = ({ onConfirm }) => {
    Modal.confirm({
        title: 'Delete Node',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete this node?',
        okText: 'Yes',
        cancelText: 'No',
        centered: true,
        onOk: onConfirm,
    });
};

const TemplateNode = ({
    source = false,
    target = false,
    title,
    children,
    dragging,
    nodeData,
}) => {
    const { token } = theme.useToken();
    const [open, setOpen] = useState(false);
    const { nodeTemplates, removeNodeFunction } = useDFAStore(state => ({
        nodeTemplates: state.nodeTemplates,
        removeNodeFunction: state.removeNodeFunction,
    }));
    const setActiveTab = useDFAStore(state => state.setActiveTab);
    const addNodeFunction = useDFAStore(state => state.addNodeFunction);
    const onEditFunction = useDFAStore(state => state.onEditFunction);
    const setSourceNode = useDFAStore(state => state.setSourceNode);
    const activeTab = useDFAStore(state => state.activeTab);
    const addNode = useDFAStore(state => state.addNode);
    const setSelectedNodeId = useDFAStore(state => state.setSelectedNodeId);
    const { priorRunSelectedNode, versionSelectedNode } = useDataFlowStore(
        state => ({
            priorRunSelectedNode: state.priorRunSelectedNode,
            versionSelectedNode: state.versionSelectedNode,
        })
    );

    const handleClick = async (nodeType, subType, forceSave = false) => {
        if (!forceSave) {
            setActiveTab('data');
            const newNode = {
                ...nodeType,
                ...(nodeType?.node_type === 'SHARED_NODE'
                    ? { values: { [subType?.sub_type]: subType?.default } }
                    : {}),
                node_type: subType?.node_type,
                params: [{ ...subType }],
                source_node_id: nodeData?.dataflow_node_id,
                position: nodeData?.position,
            };
            addNodeFunction(newNode);
        } else {
            if (nodeType?.node_type === 'SHARED_NODE') {
                let {
                    name,
                    description,
                    node_type,
                    position = [
                        nodeData?.position?.[0] + 200,
                        nodeData?.position?.[1],
                    ],
                    sub_types,
                    sub_type,
                } = subType;
                let newNode = {
                    name,
                    description,
                    node_type,
                    position,
                    source_node_id: nodeData?.dataflow_node_id,
                };
                newNode.values = sub_types?.[sub_types.length - 1]?.config;
                newNode.params = [
                    {
                        sub_type,
                        data_type: sub_type,
                        name: '',
                        label: '',
                        description: '',
                    },
                ];

                return await addNode(nodeData?.dataflow_id, newNode);
            }

            const newNode = {
                ...nodeType,
                ...(nodeType?.node_type === 'SHARED_NODE'
                    ? { values: { [subType?.sub_type]: subType?.default } }
                    : {}),
                node_type: subType?.node_type,
                params: [{ ...subType }],
                source_node_id: nodeData?.dataflow_node_id,
                position: [
                    nodeData?.position?.[0] + 200,
                    nodeData?.position?.[1],
                ],
            };
            [
                '_id',
                'created',
                'disabled',
                'node_type',
                'params_supported',
                'updated',
                'meta',
                'parent_sub_type_id',
                'user_id',
            ]?.forEach(key => {
                delete newNode?.['params']?.[0]?.[key];
            });
            ['node_template_id', 'sub_types']?.forEach(key => {
                delete newNode?.[key];
            });
            newNode['params'][0] = {
                ...newNode?.params?.[0],
                data_type: newNode?.params?.[0]?.sub_type,
            };
            await addNode(nodeData?.dataflow_id, newNode);
        }
    };

    const addNodeItems = nodeTemplates?.data?.map(node => {
        if (!['DATA_TRIGGER'].includes(node?.node_type))
            return {
                key: node?.node_template_id,
                label:
                    node?.name === 'Shared Node'
                        ? 'Published Node'
                        : node?.name,
                disabled: node?.disabled,
                children: node?.sub_types?.map(subTypeLevel1 => ({
                    key: subTypeLevel1?._id,
                    label: subTypeLevel1?.name,
                    disabled: subTypeLevel1?.disabled,
                    onClick: () => {
                        if (!subTypeLevel1?.sub_types?.length) {
                            setSourceNode(nodeData);
                            handleClick(node, subTypeLevel1, true);
                        }
                        setOpen(false);
                    },
                    children: !!subTypeLevel1?.sub_types?.length
                        ? subTypeLevel1?.sub_types?.map(subTypeLevel2 => ({
                              key: subTypeLevel2?._id,
                              label: subTypeLevel2?.name,
                              disabled: subTypeLevel2?.disabled,
                              nodeData: subTypeLevel2,
                              onClick: () => {
                                  setSourceNode(nodeData);
                                  handleClick(node, subTypeLevel2, true);
                                  setOpen(false);
                              },
                          }))
                        : null,
                })),
            };
    });

    const items = [
        {
            label: 'Configure',
            key: '1',
            meta: nodeData,
            // disabled: true,
            onClick: () => {
                setActiveTab('data');
                onEditFunction(nodeData?.dataflow_node_id);
                setOpen(false);
            },
        },
        {
            label: 'Insert Node',
            key: '2',
            children: addNodeItems,
            // disabled: nodeData?.node_type === 'DATA_DESTINATION',
        },
        {
            label: 'Preview',
            key: '3',
            onClick: () => {
                setOpen(false);
                setTimeout(() => {
                    setSelectedNodeId(nodeData?.dataflow_node_id);
                    setActiveTab('data');
                }, 100);
            },
        },
        {
            label: 'Delete',
            key: '4',
            onClick: async () => {
                DeleteConfirmationModal({
                    onConfirm: () => {
                        removeNodeFunction(
                            nodeData?.dataflow_id,
                            nodeData?.dataflow_node_id
                        );
                        setOpen(false);
                    },
                });
            },
            danger: true,
        },
    ];

    return (
        <>
            {target && (
                <Handle
                    type="target"
                    position="left"
                    isConnectable={!nodeData?.viewOnly}
                />
            )}
            {!nodeData?.dataflow_node_config_id && (
                <Tooltip title="Node needs to be configured" placement="right">
                    <Button
                        icon={<ExclamationCircleOutlined />}
                        shape="circle"
                        size="small"
                        type="text"
                        danger
                        style={{
                            position: 'absolute',
                            zIndex: 2,
                            top: -10,
                            left: 25,
                            background: 'white',
                        }}
                    />
                </Tooltip>
            )}
            <Space direction="vertical" align="center" size={[0]}>
                <Dropdown
                    placement="topCenter"
                    menu={{ items, style: { textAlign: 'center' } }}
                    open={!dragging && open && !nodeData?.viewOnly}
                    onOpenChange={setOpen}
                >
                    <Button
                        size="large"
                        style={{
                            height: '4.25em',
                            fontSize: '.8em',
                            borderColor: getNodeStatusColor(nodeData?.status),
                            borderWidth: '.25em',
                            boxShadow:
                                nodeData?.viewOnly &&
                                ((nodeData?.dataflow_node_id ==
                                    priorRunSelectedNode &&
                                    activeTab === 'prior-runs') ||
                                    (nodeData?.dataflow_node_id ==
                                        versionSelectedNode &&
                                        activeTab === 'versions'))
                                    ? `0 0 0 12px ${token.controlOutline}`
                                    : 'none',
                        }}
                        icon={children}
                    />
                </Dropdown>
                {title && (
                    <Typography.Text
                        style={{
                            position: 'absolute',
                            width: 'max-content',
                            textAlign: 'center',
                            fontSize: '.75em',
                            transform: 'translateX(-50%)',
                            fontWeight:
                                nodeData?.viewOnly &&
                                ((nodeData?.dataflow_node_id ==
                                    priorRunSelectedNode &&
                                    activeTab === 'prior-runs') ||
                                    (nodeData?.dataflow_node_id ==
                                        versionSelectedNode &&
                                        activeTab === 'versions'))
                                    ? '600'
                                    : 'initial',
                        }}
                    >
                        {title}
                    </Typography.Text>
                )}
            </Space>
            {source && (
                <Handle
                    type="source"
                    position="right"
                    isConnectable={!nodeData?.viewOnly}
                />
            )}
            {source && nodeData?.is_tail_node && !nodeData?.viewOnly && (
                <Dropdown menu={{ items: addNodeItems }}>
                    <Button
                        icon={<PlusCircleTwoTone />}
                        size="small"
                        type="text"
                        shape="circle"
                        style={{
                            position: 'absolute',
                            transform: 'translate(20%, 50%)',
                        }}
                    />
                </Dropdown>
            )}
        </>
    );
};

export default TemplateNode;
