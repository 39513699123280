import React, { useEffect } from 'react';
import ReactFlow, {
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    ReactFlowProvider,
} from 'reactflow';
import {
    DataJoin,
    DataNormalize,
    DataDestination,
    DataSource,
    DataTransform,
    CustomTransform,
    DataType,
    CustomEdge,
    DataInspect,
    DataTrigger,
    DynamicSource,
    DynamicDestination,
} from '../../Workflow/NodeTypes';

const nodeTypes = {
    DATA_SOURCE: DataSource,
    DATA_TYPE: DataType,
    DATA_NORMALIZE: DataNormalize,
    DATA_TRANSFORM: DataTransform,
    CUSTOM_TRANSFORM: CustomTransform,
    DATA_JOIN: DataJoin,
    DATA_DESTINATION: DataDestination,
    DATA_INSPECT: DataInspect,
    DATA_TRIGGER: DataTrigger,
    DYNAMIC_SOURCE: DynamicSource,
    DYNAMIC_DESTINATION: DynamicDestination,
};

const edgeTypes = {
    CUSTOM_EDGE: CustomEdge,
};

export default function Workflow({
    dataflow,
    setSelectedNodeId,
    height = 'calc(100vh - 360px)',
}) {
    const [nodes, setNodes] = useNodesState(dataflow?.nodes);
    const [edges, setEdges] = useEdgesState(dataflow?.edges);

    useEffect(() => {
        if (!dataflow) return;
        setNodes(dataflow?.nodes);
        setEdges(dataflow?.edges);
    }, [dataflow?.nodes?.length, dataflow?.updated]);

    return (
        <div
            style={{
                height,
                width: '100%',
            }}
        >
            <ReactFlowProvider>
                <ReactFlow
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    defaultNodes={nodes}
                    defaultEdges={edges}
                    fitView
                    edgesUpdatable={false}
                    edgesFocusable={false}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    nodesFocusable={false}
                    draggable={true}
                    panOnDrag={true}
                    elementsSelectable={true}
                    onNodeClick={(_event, node) => {
                        setSelectedNodeId(node.id);
                    }}
                >
                    <Controls showInteractive={false} />
                    <Background color="#aaa" gap={16} />
                </ReactFlow>
            </ReactFlowProvider>
        </div>
    );
}
