import React, { useEffect } from 'react';
import PriorRunDetails from '../Data/PriorRuns/PriorRunDetails';
import useDFAStore from 'store/dataflowAuth';
import { useLocation } from 'react-router-dom';
export default function JobDetails() {
    const { getDataflowJobs, getDataflowJob, dataflowjob, setDataflowJob } =
        useDFAStore(state => ({
            getDataflowJobs: state.getDataflowJobs,
            getDataflowJob: state.getDataflowJob,
            dataflowjob: state.dataflowjob,
            setDataflowJob: state.setDataflowJob,
        }));
    const location = useLocation();
    const job_id = location?.pathname?.split('/').pop();

    useEffect(async () => {
        const res = await getDataflowJob(job_id);
        setDataflowJob(res?.data);
    }, []);
    return (
        <div>
            <PriorRunDetails
                runId={dataflowjob?.job_id || dataflowjob?.dataflow_run_id}
                dataflowId={dataflowjob?.dataflow_id}
            />
        </div>
    );
}
