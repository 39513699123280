import create from 'zustand';
import axios from 'services/api';
import { message } from 'antd';
import { useViewedHistoryStore } from './viewedHistory';

export const useGlossaryStore = create((set, get) => ({
    glossaries: [],
    glossaryCollection: {},
    activeKey: '',
    glossaryItems: [],
    glossaryData: {},
    // View Glossary Items:
    open: false,
    currentGlossaryItem: {},
    titleChanged: false,
    isCreateGlossary: true,
    setIsCreateGlossary: isCreateGlossary => {
        set({ isCreateGlossary });
    },
    setTitleChanged: titleChanged => {
        set({ titleChanged });
    },
    setGlossaryData: glossaryData => {
        set({ glossaryData });
    },
    setActiveKey: key => set({ activeKey: key }),

    displayGlossaryItem: async glossaryItem => {
        set({
            currentGlossaryItem: glossaryItem,
            open: true,
        });
        useViewedHistoryStore
            .getState()
            .createViewHistory(
                glossaryItem.content_id,
                glossaryItem.doc_id,
                glossaryItem.content_type
            );
    },

    closeGlossaryItem: () => set({ open: false, currentGlossaryItem: {} }),

    // Glossaries

    getGlossary: async glossaryId =>
        axios
            .get(`/info/glossary-collection/${glossaryId}/`)
            .then(async res => set({ glossaryCollection: res?.data?.data }))
            .catch(err => console.error(err)),

    resetGlossary: () => set({ glossaryCollection: {} }),

    getGlossaries: async () =>
        await axios
            .get('/info/glossary-collection/')
            .then(res => set({ glossaries: res?.data?.data }))
            .catch(err => {
                message.error("Couldn't Fetch Glossaries");
                console.error(err);
            }),

    addGlossary: async glossaryDetails =>
        await axios
            .post('/info/glossary-collection/', {
                ...glossaryDetails,
                content_type: 'glossary',
            })
            .then(async res => {
                await get().getGlossaries();
                message.success(`Glossary with title 
                ${res.data?.data?.title}
                     created successfully`);
            })
            .catch(() => message.error("Couldn't Add Glossary")),

    deleteGlossary: async glossaryId =>
        await axios
            .delete(`/info/glossary-collection/${glossaryId}/`)
            .then(async () => await get().getGlossaries())
            .catch(() => message.error("Couldn't Delete Glossary")),

    editGlossary: async glossary => {
        const glossaryId = glossary.glossary_id;
        delete glossary.glossary_id;
        await axios
            .put(`/info/glossary-collection/${glossaryId}/`, {
                ...glossary,
                content_type: 'glossary_group',
            })
            .then(async () => await get().getGlossaries())
            .catch(() => message.error("Couldn't Edit Glossary"));
    },

    // Glossary Items
    getGlossaryItems: async (glossaryId, alphabet) => {
        await axios
            .get(
                `info/glossary-collection/${glossaryId}/glossaries/${
                    alphabet?.replace('#', '%23') || '%23'
                }`
            )
            .then(res =>
                set({
                    glossaryItems:
                        [...res?.data?.data?.glossary_anchors?.content_items] ||
                        [],
                })
            )
            .catch(err => console.error(err));
    },

    addGlossaryItem: async (glossaryId, newGlossaryItem) =>
        await axios
            .post(`/info/glossary-collection/${glossaryId}`, {
                data: [newGlossaryItem],
            })
            .then(async () => {
                await get().getGlossary(glossaryId);
                const alphabet = newGlossaryItem?.term?.at(0)?.match(/[A-Za-z]/)
                    ? newGlossaryItem?.term?.at(0)
                    : '%23';
                await get().getGlossaryItems(
                    glossaryId,
                    alphabet.toUpperCase()
                );
                set({
                    activeKey: alphabet.toUpperCase(),
                });
            })
            .catch(() => message.error("Couldn't Add Glossary Item")),

    deleteGlossaryItem: async glossaryItemId =>
        await axios
            .delete(`/info/glossary-collection/glossaries/${glossaryItemId}`)
            .then(async () => {
                await get().getGlossary(
                    get().glossaryCollection?.glossary_group_id
                );
                set({
                    glossaryItems: get().glossaryItems.filter(
                        item => item.glossary_id !== glossaryItemId
                    ),
                });
            })
            .catch(e => console.error(e)),

    editGlossaryItem: async (glossaryId, glossaryItemId, glossaryItem) =>
        await axios
            .put(`/info/glossary-collection/glossaries/${glossaryItemId}`, {
                ...glossaryItem,
                content_type: 'glossary',
            })
            .then(
                async () =>
                    await get().getGlossaryItems(
                        glossaryId,
                        glossaryItem?.term?.at(0)?.match(/[A-Za-z]/)
                            ? glossaryItem?.term?.at(0)
                            : '%23'
                    )
            )
            .catch(() => message.error("Couldn't Edit Glossary Item")),
}));
