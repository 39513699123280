import {
    Dropdown,
    Button,
    Spin,
    Tabs,
    Typography,
    Modal,
    message as toastMessage,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { useChatStore } from 'store/chat';
import useDFAStore from 'store/dataflowAuth';
import './previewStyle.css';
import Chart from 'common/Chart';
import { copyText } from 'utils/utils';
import { ArrowsAltOutlined } from '@ant-design/icons';
import ServerSideDataTableViewer from 'common/ContentViewer/DataTableViewer/ServerSideDataTableViewer';

export default function usePreview(dataflowId) {
    const [previewData, setPreviewData] = useState({});
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const getPreview = useDFAStore(state => state.getPreview);

    const setPreviewConfig = async (newJobId, newNodeId, payload) => {
        if (!(newJobId && newNodeId)) return setPreviewData({});
        setMessage('');
        setLoading(true);
        const response = await getPreview(
            dataflowId,
            newJobId,
            newNodeId,
            payload ?? {}
        )
            .then(res => {
                setPreviewData(res?.data?.data);
                if (res?.data?.message) {
                    setMessage(res?.data?.message);
                }
                return res?.data?.data ?? null;
            })
            .catch(e => {
                setPreviewData({});
                setMessage(e?.response?.data?.error_message);
                return null;
            });
        setLoading(false);
        return response;
    };

    return [setPreviewConfig, previewData, message, loading, Preview];
}

function Preview({
    previewData,
    loading,
    message,
    runtime_data_store,
    minified = false,
    customHeight,
    customWidth,
    dataflowName,
    nodeName,
}) {
    const setCustomChatIntent = useChatStore(
        state => state.setCustomChatIntent
    );

    const [selectedTab, setSelectedTab] = useState('');
    const [modal, contextHolder] = Modal.useModal();
    useEffect(
        () => setSelectedTab(previewData?.data?.packets?.[0]?.packet_id),
        [previewData]
    );

    const items = previewData?.data?.packets?.map(packet => {
        const chart = previewData?.compute_info?.chart_data?.find(
            chart => chart?.packet_id === packet?.packet_id
        );
        const chart_font = chart?.chart_layout?.font_data || {};
        delete chart_font['height'];

        const ChartComponent = ({ width = 800, height = 400 }) => (
            <Chart
                data={chart?.data}
                layout={{
                    ...chart?.chart_layout?.layout,
                    ...chart_font,
                    width,
                    height,
                }}
                config={{
                    scrollZoom: true,
                    displayModeBar: false,
                    displaylogo: false,
                    autosize: true,
                }}
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
        );

        return {
            label: packet?.name,
            key: packet?.packet_id,
            children: (
                <div
                    style={{
                        height: customHeight || 'calc( 100vh - 200px )',
                        wisth: customWidth || 'auto',
                        overflowY: 'auto',
                    }}
                >
                    {!!chart && (
                        <div>
                            {contextHolder}
                            {!minified && (
                                <Button
                                    style={{
                                        float: 'right',
                                        zIndex: 5,
                                        marginRight: '1em',
                                    }}
                                    icon={<ArrowsAltOutlined />}
                                    onClick={() =>
                                        modal.info({
                                            icon: null,
                                            centered: true,
                                            content: (
                                                <ChartComponent
                                                    width={
                                                        document
                                                            ?.documentElement
                                                            ?.clientWidth * 0.7
                                                    }
                                                    height={
                                                        document
                                                            ?.documentElement
                                                            ?.clientHeight *
                                                        0.65
                                                    }
                                                />
                                            ),
                                            width: 'fit-content',
                                        })
                                    }
                                />
                            )}
                            <ChartComponent />
                        </div>
                    )}
                    <ServerSideDataTableViewer
                        key={previewData?.dataflow_node_id + packet?.packet_id}
                        isDataflowNodePreview={true}
                        packetId={packet?.packet_id}
                    />
                </div>
            ),
        };
    });

    const QueryDPS = () => (
        <Typography.Text
            onClick={() => {
                const { dataflow_id, dataflow_job_id, dataflow_node_id } =
                    previewData || {};
                const selectedPacket =
                    previewData?.data?.packets?.find(
                        packet => packet.packet_id == selectedTab
                    ) || {};
                setCustomChatIntent(
                    {
                        label: 'Dataflow Output DPS Query',
                        value: 'output_dps_chat',
                    },
                    {
                        runtime_data_store,
                        dataflow_id,
                        dataflow_job_id,
                        dataflow_node_id,
                        data_packet_id: selectedPacket.packet_id,
                        data_packet_name: selectedPacket.name,
                        additional_info:
                            dataflowName &&
                            nodeName &&
                            `(${dataflowName} - ${nodeName})`,
                    }
                );
            }}
        >
            Query on this data packet
        </Typography.Text>
    );
    const CopyPythonSnippet = () => (
        <Typography.Text
            type="link"
            onClick={() => {
                copyText(`from common.interfaces.dataflow_manager.dataflow_manager import DataflowManager\n
        from uuid import UUID\n
        df_manager = DataflowManager()\n
        node_id = UUID("${previewData.dataflow_node_id}")\n
        df = df_manager.get_dps(node_id=node_id)`);
                toastMessage.success(`Python snippet copied to clipboard`);
            }}
        >
            Copy python code for dataset
        </Typography.Text>
    );
    const menuItems = [
        { key: '1', label: <QueryDPS /> },
        { key: '2', label: <CopyPythonSnippet /> },
    ];

    return (
        <Spin spinning={loading}>
            {!!message ? (
                message
            ) : (
                <Tabs
                    tabPosition={'top'}
                    // className="preview-tabs"
                    items={items}
                    size="small"
                    activeKey={selectedTab}
                    onTabClick={key => setSelectedTab(key)}
                    tabBarExtraContent={{
                        right: (
                            <Dropdown
                                menu={{
                                    items: menuItems,
                                    onClick: () => {},
                                }}
                            >
                                <MoreOutlined />
                            </Dropdown>
                        ),
                    }}
                />
            )}
        </Spin>
    );
}
