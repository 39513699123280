import { Descriptions } from 'antd';
import CustomCodeEditor from 'common/CustomCodeEditor';
import React, { useMemo, useEffect } from 'react';
import useDataConnectionsStore from 'store/dataConnections';
import useDFAStore from 'store/dataflowAuth';

export default function DataStore({ config }) {
    const getAllDataConnections = useDataConnectionsStore(
        state => state.getAllDataConnections
    );
    const { setDataConnections, dataConnections } = useDFAStore(state => ({
        dataConnections: state.dataConnections,
        setDataConnections: state.setDataConnections,
    }));

    const dataConnectionName = useMemo(
        () =>
            dataConnections?.data?.data?.connection_list?.filter(
                connection =>
                    connection?._id ===
                    config?.config?.datastore_config?.data_source
            )?.[0]?.data_connection_name,
        [dataConnections?.data?.data?.connection_list?.length]
    );

    useEffect(() => {
        (() => {
            if (!dataConnections?.data?.data?.connection_list?.length) {
                getAllDataConnections()?.then(setDataConnections);
            }
        })();
    }, []);

    return (
        <Descriptions bordered layout="horizontal">
            <Descriptions.Item label="Source" span={24}>
                {dataConnectionName}
            </Descriptions.Item>
            {config?.config?.datastore_config?.data_list?.map(
                (query, index) => (
                    <Descriptions.Item
                        span={24}
                        label={`Query ${index + 1}`}
                        key={`query ${index}`}
                    >
                        <CustomCodeEditor
                            language="sql"
                            value={query?.['raw_sql_query']}
                            readOnly={true}
                            fontSize={14}
                        />
                    </Descriptions.Item>
                )
            )}
        </Descriptions>
    );
}
