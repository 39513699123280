import create from 'zustand';
import axios from 'services/api';
export const useTSAuthoringStore = create((set, get) => ({
    scenarios: {},
    getScenarios: async ({ searchTerm = '', page = 1, limit = 10 } = {}) =>
        axios
            .get('/lucy/scenarios/', {
                params: {
                    page,
                    limit,
                    sort: 'desc_created',
                    search_query: searchTerm,
                },
            })
            .then(res => set({ scenarios: res.data })),

    scenario: {},
    getScenario: async scenarioId =>
        await axios
            .get(`lucy/scenarios/${scenarioId}/`)
            .then(res => set({ scenario: res.data })),

    exportScenario: async scenarioIds =>
        await axios.post(`/lucy/scenario_export/`, {
            _id: scenarioIds,
        }),

    importScenario: async scenarioFormData =>
        await axios.post('/lucy/scenario_import/', scenarioFormData),

    copyScenario: async scenarioId =>
        await axios.post('/lucy/scenario_copy/', { _id: scenarioId }),

    enableScenario: async (scenarioId, isEnabled) =>
        await axios.put(`/lucy/scenarios/${scenarioId}`, {
            _id: scenarioId,
            is_enabled: isEnabled,
        }),

    deleteScenario: async scenarioId =>
        await axios.delete(`/lucy/scenarios/${scenarioId}/`),

    addScenario: async ({ title, description, additional_info }) =>
        await axios.post('/lucy/scenarios/', {
            title,
            description,
            additional_info,
        }),

    updateScenario: async (
        scenarioId,
        { title, description, additional_info }
    ) =>
        await axios.put(`/lucy/scenarios/${scenarioId}/`, {
            title,
            description,
            additional_info,
            _id: scenarioId,
        }),

    scenarioTree: {},
    nodeData: [],
    setNodeData: callback => {
        if (callback instanceof Function)
            set(state => ({ nodeData: callback(state.nodeData) }));
        else set({ nodeData: callback });
    },
    getScenarioTree: async scenarioId =>
        await axios
            .get(`/lucy/scenario_authoring/${scenarioId}/`)
            .then(res => set({ scenarioTree: res.data })),

    dataConnections: [],
    setDataConnections: dataConnections => set({ dataConnections }),

    getRegisteredDataTables: async dataConnectionId =>
        await axios.get('/data-connections/connection-table-form-info/', {
            params: {
                data_connection_id: dataConnectionId,
                to_retrieve: 'registered_data_tables',
            },
        }),

    getDataFields: async dataTableId =>
        await axios.get('/data-connections/connection-table-form-info/', {
            params: {
                data_table_id: dataTableId,
                to_retrieve: 'registered_data_fields',
            },
        }),

    getDistinctValues: async dataFieldId =>
        await axios.get('/data-connections/connection-table-form-info/', {
            params: {
                data_field_id: dataFieldId,
                to_retrieve: 'distinct_field_value',
            },
        }),

    saveScenarioTree: async scenario =>
        await axios.put(`/lucy/scenario_authoring/${scenario?.scenario_id}/`, {
            ...scenario,
            status: 'SAVED',
        }),

    publishScenarioTree: async (scenario, forcePublish = false) =>
        await axios.put(`/lucy/scenario_authoring/${scenario?.scenario_id}/`, {
            ...scenario,
            status: 'PUBLISHED',
            force_publish: forcePublish,
        }),

    revertScenarioTree: async scenario =>
        await axios
            .put(`/lucy/scenario_authoring/${scenario?.scenario_id}/`, {
                ...scenario,
                status: 'REVERT',
            })
            .then(res => set({ scenarioTree: res.data })),

    getPreviewScenarioTree: async (scenarioId, previewType) =>
        await axios
            .get(`/lucy/scenario_authoring/${scenarioId}/`, {
                params: {
                    preview: previewType,
                },
            })
            .then(res => res.data),

    getLogValidation: async logCheckNodeData =>
        await axios.post('/lucy/ts_log_validation/', { ...logCheckNodeData }),
}));
