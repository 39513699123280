import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Button, Card, Spin } from 'antd';
import { useThemeStore } from '../../store/theme';
import Config from '../../config/Config';
import LazyLoad from 'react-lazy-load';
import IframeNavigation from 'common/IframeNavigation';
import { MessageOutlined } from '@ant-design/icons';
import { useChatStore } from 'store/chat';
import { useDashboardStore } from 'store/dashboards';
import html2canvas from 'html2canvas';
export default function DashboardWidget({ dashboard }) {
    const {
        isChatOpen,
        toggleChat,
        setCustomChatIntent,
        customIntent,
        customIntentLabel,
    } = useChatStore(state => ({
        isChatOpen: state.isChatOpen,
        toggleChat: state.toggleChat,
        setCustomChatIntent: state.setCustomChatIntent,
        customIntent: state.customIntent,
        customIntentLabel: state.customIntentLabel,
    }));
    const { contentId, setContentId, imageInterpreter } = useDashboardStore(
        state => ({
            contentId: state.contentId,
            setContentId: state.setContentId,
            imageInterpreter: state.imageInterpreter,
        })
    );
    const iframeRef = useRef(null);
    const theme = useThemeStore(state => state.theme);
    const dashboardURL = useMemo(
        () =>
            dashboard?.url?.includes('?')
                ? `${Config.GRAFANA}${dashboard?.url}&theme=${theme}`
                : `${Config.GRAFANA}${dashboard?.url}?theme=${theme}`,
        [theme, dashboard?.url]
    );

    useEffect(() => {
        setContentId(dashboard.id);
    }, []);
    const [loading, setLoading] = useState(true);

    const interpretDahsboard = async () => {
        setLoading(true);
        let data = {};
        let task = '';
        if (dashboard?.url?.includes('grafana')) {
            task = 'get_dashboard_screenshot';
            data = await imageInterpreter(dashboard, task, '');
        } else {
            task = 'save_dashboard_screenshot';
            const capturedImage = await captureIframe();
            data = await imageInterpreter(dashboard, task, capturedImage);
        }
        if (data) {
            toggleChat();
            setLoading(false);
            setCustomChatIntent(
                {
                    value: 'image_interpreter',
                    label: 'Interpret Image',
                },
                {
                    ...dashboard,
                    ...data?.data,
                    task,
                }
            );
        } else {
            setLoading(false);
            message.error('Failed to interpret dashboard. Please try again.');
        }
    };

    const captureIframe = async () => {
        const element = iframeRef.current; // Reference to the iframe element
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/png'); // Convert canvas to base64-encoded image
        return imgData;
    };

    return (
        <LazyLoad
            style={{ width: '100%', overflow: 'hidden' }}
            height={'100%'}
            threshold={0.6}
        >
            <Spin
                size="large"
                style={{
                    top: 200,
                    height: '100%',
                }}
                spinning={loading}
                wrapperClassName="health-check-spinner"
            >
                <div style={{ top: '1%', position: 'absolute', zIndex: 1 }}>
                    <IframeNavigation iframeRef={iframeRef} />
                </div>
                <Card
                    bodyStyle={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        paddingTop: '20px',
                    }}
                    hoverable
                    key={dashboard?.url}
                    style={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        paddingTop: '20px',
                    }}
                >
                    <Button
                        icon={<MessageOutlined />}
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            paddingBottom: '0px',
                        }}
                        size="small"
                        onClick={() => {
                            interpretDahsboard();
                        }}
                    >
                        Interpret Dashboard
                    </Button>
                    <iframe
                        ref={iframeRef}
                        key={dashboard?.url}
                        src={dashboardURL}
                        style={{
                            position: 'relative',
                            left: `-5.9rem`,
                            width: 'calc(100% + 8rem)',
                            height: '100%',
                        }}
                        onLoad={() => setLoading(false)}
                    />
                </Card>
            </Spin>
        </LazyLoad>
    );
}
