import React from 'react';
import { Card, Table } from 'antd';

const columns = [
    {
        title: 'Column',
        dataIndex: 'column',
        key: 'column',
    },
    {
        title: 'Operator',
        dataIndex: 'operator',
        key: 'operator',
    },
    {
        title: 'Previous Value',
        dataIndex: 'old_value',
        key: 'old_value',
    },
    {
        title: 'Replaced With',
        dataIndex: 'replace_method',
        key: 'replace_method',
    },
];

const cleanDataSource = config => {
    const configs = config?.find_n_replace_columns;
    if (!Array.isArray(configs)) return [];
    return configs?.reduce((acc, current) => {
        let replace_method = current?.new_value
            ? current?.new_value
            : current?.replace_method;
        acc?.push({
            column: current?.column,
            operator: current?.operator,
            old_value: current?.old_value,
            replace_method: replace_method,
        });
        return acc;
    }, []);
};

export default function ColumnFindAndReplace({ config }) {
    return (
        <Card size="small">
            <Table
                bordered={true}
                style={{ width: '100%' }}
                dataSource={cleanDataSource(config)}
                columns={columns}
                size="small"
                pagination={{
                    hideOnSinglePage: true,
                }}
            />
        </Card>
    );
}
