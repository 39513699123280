import create from 'zustand';
import axios from 'services/api';
import { convertParamsIntoParamString } from 'utils/utils';

const initialStoreState = {
    openConnectionsForm: false,
    connectionFormType: 'add',
    dataConnections: [],
    dataConnectionsCompact: [],
    currentDataConnection: null,
    services: [],
    addConnectionFormTemplateFields: [],
    pagination: {
        current: 1,
        pageSize: 15,
        total: 0,
    },
    dataConnectionData: [],
    isCreateDataConnection: true,
};

const useDataConnectionsStore = create((set, get) => ({
    ...initialStoreState,

    setOpenConnectionsForm: openConnectionsForm => {
        set({ openConnectionsForm: openConnectionsForm });
    },

    setConnectionFormType: connectionFormType => {
        set({ connectionFormType: connectionFormType });
    },

    setCurrentDataConnection: dataConnection => {
        set({ currentDataConnection: dataConnection });
    },

    setDataConnectionData: dataConnectionData => {
        set({ dataConnectionData });
    },
    setIsCreateDataConnection: isCreateDataConnection => {
        set({ isCreateDataConnection });
    },

    getDataConnectionDetails: connection_id =>
        axios
            .get(`/data-connections/connection/${connection_id}`)
            .then(res => res?.data?.data?.connection_list)
            .catch(e => console.log(e)),

    getAllDataConnections: (
        page = get()?.pagination?.current,
        limit = get()?.pagination?.pageSize,
        service_type,
        sort = 'desc_created',
        search_query = '',
        healthy,
        created__gte = '',
        created__lte = '',
        updated__gte = '',
        updated__lte = ''
    ) => {
        const params = {
            search_query,
            page,
            limit,
            sort,
            healthy,
            created__gte,
            created__lte,
            updated__gte,
            updated__lte,
        };

        const response = axios
            .get(
                `/data-connections/connection${convertParamsIntoParamString({
                    ...params,
                    service_type,
                })}`
            )
            .then(res => {
                if (Array.isArray(res?.data?.data?.connection_list)) {
                    set({
                        dataConnections: res?.data?.data?.connection_list,
                        pagination: {
                            ...get()?.pagination,
                            current: page,
                            pageSize: limit,
                            total: res?.data?.meta?.total_records ?? 0,
                        },
                    });
                }
                return res;
            })
            .catch(err => err);
        return response;
    },

    getAllDataConnectionsCompact: (service_type = '') => {
        const response = axios
            .get(
                `/data-connections/connection-table-form-info/?to_retrieve=data_connection&service_type=${service_type}`
            )
            .then(res => {
                if (Array.isArray(res?.data?.data?.data_connections)) {
                    set({
                        dataConnectionsCompact: res.data.data.data_connections,
                    });
                }
                return res;
            })
            .catch(err => err);
        return response;
    },

    getAllServices: () => {
        const response = axios
            .get(
                '/data-connections/connection-table-form-info/?to_retrieve=service_types&sort=desc_updated'
            )
            .then(res => {
                set({
                    services: Array.isArray(res?.data?.data?.service_types)
                        ? res.data.data.service_types
                        : [],
                });
                return res;
            })
            .catch(err => err);
        return response;
    },

    getAddConnectionFormTemplate: service_type => {
        const response = axios
            .get(
                `/data-connections/connection-table-form-info/?service_type=${service_type}&to_retrieve=form_fields`
            )
            .then(res => res)
            .catch(err => err);
        return response;
    },

    deleteDataConnection: dataConnectionId => {
        const response = axios
            .delete(`/data-connections/connection/${dataConnectionId}`)
            .then(res => res)
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },

    addDataConnection: payload => {
        const response = axios
            .post('/data-connections/connection/', payload)
            .then(res => res)
            .catch(err => err);
        return response;
    },

    updateDataConnection: payload => {
        const response = axios
            .put(
                `/data-connections/connection/${
                    get().currentDataConnection?._id
                }`,
                payload
            )
            .then(res => res)
            .catch(err => err);
        return response;
    },

    generateSecretForPeer: async () => {
        const response = await axios
            .get(`/data-broker/generate-secret/`)
            .then(res => res?.data?.data?.secret)
            .catch(err => {
                console.error(err);
                return null;
            });
        return response;
    },

    syncAllHealthCheckStatus: async () => {
        const response = axios
            .get(`/health/connections/`)
            .then(res => res)
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },

    syncHealthCheckStatus: async dataConnectionId => {
        const response = axios
            .get(`/health/connections/${dataConnectionId}`)
            .then(res => res)
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },
}));

export default useDataConnectionsStore;
