import { Form, InputNumber, Select, Button } from 'antd';

function SamplingToolPanel(props) {
    const [form] = Form.useForm();
    const fieldValues = Form.useWatch([], form);

    return (
        <Form
            form={form}
            autoComplete="off"
            style={{ margin: '16px 8px' }}
            layout="vertical"
            onFinish={props?.handleSampling}
            className="sampling-tool-panel"
        >
            <Form.Item
                name="type"
                label="Sample Type"
                rules={[
                    {
                        required: true,
                        message: 'Please Select a Sample Type"',
                    },
                ]}
                initialValue={'raw'}
            >
                <Select
                    onChange={null}
                    options={[
                        { key: 'raw', label: 'Raw', value: 'raw' },
                        {
                            key: 'first_N',
                            label: 'First N records',
                            value: 'first_N',
                        },
                        {
                            key: 'last_N',
                            label: 'Last N records',
                            value: 'last_N',
                        },
                        {
                            key: 'random_N',
                            label: 'Random N records',
                            value: 'random_N',
                        },
                        {
                            key: 'first_%',
                            label: 'First N % records',
                            value: 'first_%',
                        },
                        {
                            key: 'last_%',
                            label: 'Last N % records',
                            value: 'last_%',
                        },
                        {
                            key: 'random_%',
                            label: 'Random N % records',
                            value: 'random_%',
                        },
                    ]}
                    placeholder="Select a Sample Type"
                    size="small"
                />
            </Form.Item>

            {fieldValues?.type !== 'raw' && (
                <Form.Item
                    name="value"
                    label={
                        fieldValues?.type.includes('%')
                            ? 'Sample %'
                            : 'Sample Number'
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter a Number',
                        },
                    ]}
                >
                    <InputNumber
                        placeholder="Enter a Number"
                        size="small"
                        min={0}
                        max={
                            fieldValues?.type.includes('%')
                                ? 100
                                : Number.MAX_SAFE_INTEGER
                        }
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            )}

            <Form.Item style={{ float: 'right' }}>
                <Button htmlType="submit">Apply Sampling</Button>
            </Form.Item>
        </Form>
    );
}

export default SamplingToolPanel;
