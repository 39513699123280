import { Table, Descriptions, Tag, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import useDataConnectionsStore from 'store/dataConnections';
import useDFAStore from 'store/dataflowAuth';
import CommonAutoTriggerDetails from './CommonAutoTriggerDetails';

const typeMap = {
    int: 'Integer',
    str: 'String',
    bool: 'Boolean',
    object: 'Object',
    float: 'Float',
};

const requestBodyQueryValuesColumns = [
    {
        title: 'Field',
        dataIndex: 'field',
        key: 'field',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: type => typeMap?.[type] || type,
    },
];

export default function InboundAPIDetails({ config, showSensorValues }) {
    const automatic = { ...config?.config?.automatic?.config } || {};

    const getAllDataConnections = useDataConnectionsStore(
        state => state.getAllDataConnections
    );
    const { setDataConnections, dataConnections } = useDFAStore(state => ({
        dataConnections: state.dataConnections,
        setDataConnections: state.setDataConnections,
    }));

    const nodeTemplates = useDFAStore(state => state.nodeTemplates);
    const basePath = useMemo(
        () =>
            nodeTemplates?.data
                ?.find(
                    nodeTemplate => nodeTemplate?.node_type === 'DATA_TRIGGER'
                )
                ?.sub_types?.find(
                    subType => subType?.sub_type === 'inbound_api'
                )?.configuration?.base_path,
        [config?.node_config?.dataflow_job_node_id]
    );

    const requestBodyType = useMemo(
        () => config?.config?.automatic?.config?.request_body_type,
        [config?.node_config?.dataflow_job_node_id]
    );

    useEffect(() => {
        (() => {
            if (!dataConnections?.data?.data?.connection_list?.length) {
                getAllDataConnections().then(setDataConnections);
            }
        })();
    }, []);

    const dataConnectionName = useMemo(
        () =>
            dataConnections?.data?.data?.connection_list?.filter(
                connection => connection._id === automatic?.api_connection_id
            )?.[0]?.data_connection_name,
        [dataConnections?.data?.data?.connection_list?.length]
    );
    return (
        <CommonAutoTriggerDetails
            config={config}
            showSensorValues={showSensorValues}
        >
            <Descriptions.Item label="Connection" span={12}>
                {dataConnectionName}
            </Descriptions.Item>
            <Descriptions.Item label="Request Body Type" span={12}>
                {automatic?.request_body_type}
            </Descriptions.Item>
            <Descriptions.Item label="Request Body Params" span={12}>
                {Array.isArray(automatic?.request_body_query_values) &&
                automatic?.request_body_query_values?.length > 0 ? (
                    <Table
                        size="small"
                        dataSource={automatic?.request_body_query_values}
                        columns={requestBodyQueryValuesColumns}
                        pagination={false}
                        style={{ width: '100%' }}
                        scroll={{ x: true }}
                        bordered
                    />
                ) : (
                    'N/A'
                )}
            </Descriptions.Item>
            <Descriptions.Item label="Webhook API" span={12}>
                {requestBodyType === 'QueryParam' ? (
                    <Tag color="blue">GET</Tag>
                ) : (
                    <Tag color="green">POST</Tag>
                )}
                <Typography.Text copyable>
                    {basePath?.replace(
                        '{dataflowNodeId}',
                        config?.dataflow_node_id
                    )}
                </Typography.Text>
            </Descriptions.Item>
        </CommonAutoTriggerDetails>
    );
}
