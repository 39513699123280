import create from 'zustand';
import axios from 'services/api';
import { produce } from 'immer';
import { convertParamsIntoParamString } from 'utils/utils';

const useDFAStore = create((set, get) => ({
    showDetailsForm: false,
    setShowDetailsForm: showDetailsForm => set({ showDetailsForm }),
    executionPatterns: [],
    setExecutionPatterns: executionPatterns => set({ executionPatterns }),
    dataflowList: [],
    dataflowListMeta: {},
    dataflow: {},
    nodeTemplates: [],
    setNodeTemplates: nodeTemplates => set({ nodeTemplates }),
    setDataflowList: (list, meta) =>
        set({ dataflowList: list, dataflowListMeta: meta }),
    setDataflow: dataflow => set({ dataflow }),
    sourceNode: {},
    setSourceNode: sourceNode => set({ sourceNode }),

    selectedNodeId: '',
    setSelectedNodeId: nodeId => set({ selectedNodeId: nodeId }),

    activeTab: 'data',
    setActiveTab: tab => set({ activeTab: tab }),

    loading: false,
    setLoading: loadingState => set({ loading: loadingState }),

    editingNodeId: '',
    setEditingNodeId: callback => {
        if (callback instanceof Function)
            set(state => ({
                editingNodeId: callback(state.editingNodeId),
            }));
        else set({ editingNodeId: callback });
    },

    replace: false,
    setReplace: replaceSate => set({ replace: replaceSate }),
    dataflowjob: {},
    setDataflowJob: dataflowjob => set({ dataflowjob }),
    dataflowjobs: {},
    setDataflowJobs: dataflowjobs => set({ dataflowjobs }),
    showCompareVersions: false,
    setShowCompareVersions: showCompareVersions => set({ showCompareVersions }),
    isCompareMode: false,
    setIsCompareMode: isCompareMode => set({ isCompareMode }),
    isComparing: false,
    setIsComparing: isComparing => set({ isComparing }),
    compareSelectedKeys: [],
    setCompareSelectedKeys: compareSelectedKeys => set({ compareSelectedKeys }),
    destinationVersionId: '',
    setDestinationVersionId: value => set({ destinationVersionId: value }),
    getDataflowJobs: async (
        search_query = '',
        page = 1,
        limit = 10,
        sort = 'desc_created'
    ) => {
        let params = {
            search_query,
            sort,
            page,
            limit,
        };

        try {
            const { data } = await axios.get(
                `/dataflows/jobs/${convertParamsIntoParamString({ ...params })}`
            );

            set(state => ({
                ...state,
                dataflowJobs: data,
            }));

            return data;
        } catch (error) {
            throw error;
        }
    },

    getDataflowJob: async (jobId = '') => {
        try {
            const { data } = await axios.get(`/dataflows/job/${jobId}`);

            return data;
        } catch (error) {
            throw error;
        }
    },
    priorRunData: [],
    priorRunMeta: {
        limit: 20,
        page: '',
        total_records: '',
    },

    priorRunExpanded: '',
    setPriorRunExpanded: runId => set({ priorRunExpanded: runId }),

    priorRunSelectedNode: '',
    setPriorRunSelectedNode: nodeId => set({ priorRunSelectedNode: nodeId }),

    priorRunData: [],
    priorRunMeta: {
        limit: 20,
        page: '',
        total_records: '',
    },

    priorRunExpanded: '',
    setPriorRunExpanded: runId => set({ priorRunExpanded: runId }),

    priorRunSelectedNode: '',
    setPriorRunSelectedNode: nodeId => set({ priorRunSelectedNode: nodeId }),

    getDataflows: async (
        search_query = '',
        page = 1,
        limit = 10,
        sort = 'desc_created',
        is_enabled = '',
        created__gte = '',
        created__lte = '',
        updated__gte = '',
        updated__lte = ''
    ) => {
        let params = {
            search_query,
            sort,
            page,
            limit,
            is_enabled,
            created__gte,
            created__lte,
            updated__gte,
            updated__lte,
        };

        if (is_enabled) {
            params.is_enabled = [];
            params.has_triggers = [];
            is_enabled?.forEach(value => {
                if (value === 'enable') {
                    params.is_enabled.push('true');
                    params.has_triggers.push('true');
                } else if (value === 'disable') {
                    params.is_enabled.push('false');
                    params.has_triggers.push('true');
                } else if (value === 'na') {
                    params.has_triggers.push('false');
                } else {
                    delete params.is_enabled;
                    delete params.has_triggers;
                }
            });
        }

        params.is_enabled = [...new Set(params.is_enabled)];
        params.has_triggers = [...new Set(params.has_triggers)];

        try {
            const response = await axios.get(
                `/dataflows/${convertParamsIntoParamString({ ...params })}`
            );

            return response.data;
        } catch (error) {
            console.error('API Error:', error);
            throw error;
        }
    },

    addDataflow: async newDataflow =>
        await axios.post('/dataflows', newDataflow),

    editDataflow: async (dataflowId, updatedDataflow) =>
        await axios.put(`/dataflows/${dataflowId}`, updatedDataflow),

    cloneDataflow: async (dataflowId, newDataflow) =>
        await axios.post(`/dataflows/${dataflowId}/clone`, newDataflow),

    deleteDataflow: async dataflowId =>
        await axios.delete(`/dataflows/${dataflowId}`),

    getDataflow: async dataflowId =>
        await axios.get(`/dataflows/${dataflowId}`),

    getExecutionPatterns: async () =>
        await axios
            .get(`/dataflows/exec_pattern`)
            .then(res => {
                const executionPatterns = res?.data?.data
                    ?.map(value => {
                        if (value?.enabled) {
                            return {
                                label: value?.pattern,
                                value: value?.pattern_id,
                                meta: value,
                            };
                        }
                    })
                    .filter(Boolean);
                set({
                    executionPatterns: executionPatterns || [],
                });
            })
            .catch(err => {
                console.error(err);
            }),

    exportDataflows: async dataflowIds =>
        await axios.post('/dataflows/export', { dataflow_ids: dataflowIds }),

    importDataflows: async dataflowFormData =>
        await axios.post('/dataflows/import', dataflowFormData),

    getNodeTemplates: async () => await axios.get('dataflows/node-templates/'),

    getPreview: async (dataflowId, jobId, nodeId, payload) =>
        await axios.post(
            `/dataflows/${dataflowId}/job/${jobId}/node/${nodeId}/preview`,
            payload
        ),
    getAnalysis: async (dataflowId, jobId, nodeId, abortController) =>
        await axios.get(
            `/dataflows/${dataflowId}/job/${jobId}/node/${nodeId}/analysis`,
            {
                signal: abortController?.signal,
            }
        ),
    getRunLogs: async (
        { dataflowId, jobId, jobNodeId, query },
        abortController,
        page = 1,
        limit
    ) =>
        await axios.get(
            `/dataflows/${dataflowId}/job/${jobId}/job-node/${jobNodeId}/logs?page=${page}` +
                (limit ? `&limit=${limit}` : '') +
                (query ? `&search_query=${query}` : ''),
            {
                signal: abortController?.signal,
            }
        ),

    isAddingNode: false,
    addNode: async (dataflowId, newNode) => {
        set({ isAddingNode: true });
        return await axios
            .post(`/dataflows/${dataflowId}/node`, newNode)
            .then(res =>
                set({
                    dataflow: res.data.data,
                    isAddingNode: false,
                })
            )
            .catch(e => {
                console.error(e);
                set({ isAddingNode: false });
            });
    },

    deleteNode: async (dataflowId, nodeId) =>
        await axios.delete(`/dataflows/${dataflowId}/node/${nodeId}`),

    addDataflowEdges: async (dataflowId, data) =>
        await axios
            .post(`/dataflows/${dataflowId}/edge/`, data)
            .catch(err => console.error(err)),

    deleteDataflowEdges: async (dataflowId, edgeId) =>
        await axios
            .delete(`/dataflows/${dataflowId}/edge/${edgeId}`)
            .catch(err => console.error(err)),

    updateDataflowNode: async (dataflowId, nodeId, data) =>
        await axios
            .put(`/dataflows/${dataflowId}/node/${nodeId}`, data)
            .then(res => {
                set({ dataflow: res?.data?.data, loading: false });
                return res;
            })
            .catch(err => console.error(err)),

    refreshDataflow: async (dataflowId, nodeId) =>
        await axios
            .post(`/dataflows/${dataflowId}/node/${nodeId}/refresh`)
            .then(res =>
                set(
                    produce(state => {
                        state.dataflow.last_run_status = res?.data?.data;
                    })
                )
            )
            .catch(err => console.error(err)),

    reRunDataflow: async dataflowId =>
        await axios
            .post(`/dataflows/${dataflowId}/re-run`)
            .then(res =>
                set(
                    produce(state => {
                        state.dataflow.last_run_status = res?.data?.data;
                    })
                )
            )
            .catch(err => console.error(err)),
    abortDataflow: async dataflowId =>
        await axios
            .post(`/dataflows/${dataflowId}/abort`)
            .then(res =>
                set(
                    produce(state => {
                        state.dataflow.last_run_status = res?.data?.data;
                    })
                )
            )
            .catch(err => console.error(err)),
    getColumnInfo: async (dataflowId, jobId, params) =>
        await axios.get(
            `/dataflows/${dataflowId}/job/${jobId}/column-info?${params}`
        ),

    removeNodeFunction: null,

    setRemoveNodeFunction: fn => set({ removeNodeFunction: fn }),

    addNodeFunction: null,

    setAddNodeFunction: fn => set({ addNodeFunction: fn }),

    onEditFunction: null,

    setOnEditFunction: fn => set({ onEditFunction: fn }),

    // Data Type Node Specific
    getColumnTypes: async (dataflowId, jobId, nodeId) =>
        await axios.get(
            `/dataflows/${dataflowId}/job/${jobId}/node/${nodeId}/data-types`
        ),

    // Data Source Node Specific
    dataConnections: [],
    setDataConnections: dataConnections => set({ dataConnections }),

    getCustomNodes: async () =>
        await axios.get('/dataflows/shared_node_template'),

    createCustomNode: async (
        nodeType,
        subType,
        dataflowNodeConfigId,
        templateName
    ) =>
        await axios.post('/dataflows/shared_node_template/', {
            node_type: nodeType,
            sub_type: subType,
            dataflow_node_config_id: dataflowNodeConfigId,
            template_name: templateName,
        }),

    updateCustomNode: async (
        id,
        { sharedNodeId, config, templateName, node_type, sub_type }
    ) =>
        await axios.put(`/dataflows/shared_node_template/${id}`, {
            shared_node_id: sharedNodeId,
            config,
            node_type,
            sub_type,
            template_name: templateName,
        }),

    // Custom Runtime

    getCustomRuntimes: async ({
        query = '',
        pythonVersion = '',
        page = 1,
        limit = 10,
    } = {}) =>
        await axios.get('/dataflows/custom_runtime', {
            params: {
                search: query,
                python_version: pythonVersion,
                page,
                limit,
                sort: 'desc_created',
            },
        }),

    getCustomRuntimeById: async customRuntimeId =>
        await axios.get(`/dataflows/custom_runtime/${customRuntimeId}`),

    customeRuntimes: [],
    setCustomRuntimes: customRuntimes => set({ customRuntimes }),

    createCustomRuntime: async (
        { name, python_version, requirements_file, packages } = {
            name: '',
            python_version: '',
            requirements_file: '',
            packages: '',
        }
    ) =>
        await axios.post('/dataflows/custom_runtime', {
            name,
            python_version,
            requirements_file,
            packages,
        }),

    deleteCustomRuntime: async customRuntimeId =>
        await axios.delete(`/dataflows/custom_runtime/${customRuntimeId}`),

    getAllPackages: async (
        { page, limit, search } = { page: 1, limit: 10, search: '' }
    ) =>
        await axios.get('/dataflows/custom_runtime/packages/', {
            params: {
                page,
                limit,
                search,
            },
        }),

    addPackageToCustomRuntime: async (customRuntimeId, packages) =>
        await axios.post(
            `/dataflows/custom_runtime/${customRuntimeId}/install/`,
            {
                packages,
            }
        ),

    removePackageFromCustomRuntime: async (customRuntimeId, packages) =>
        await axios.post(
            `/dataflows/custom_runtime/${customRuntimeId}/uninstall/`,
            {
                packages,
            }
        ),

    getSourceNode: current_node_id => {
        const edge = get().dataflow?.edges?.find(
            edge => edge?.target_node_id === current_node_id
        );
        const sourceNode = get().dataflow?.nodes?.find(
            node => node?.dataflow_node_id === edge?.source_node_id
        );
        return sourceNode;
    },

    getRunHistory: async (dataflowId, page, limit) => {
        await axios
            .get(
                `dataflows/${dataflowId}/run-history/?page=${page || 1}&limit=${
                    limit || get().priorRunMeta?.limit
                }`
            )
            .then(res => {
                set({
                    priorRunData: res?.data?.data,
                    priorRunMeta: {
                        limit: res?.data?.meta?.limit,
                        page: res?.data?.meta?.page,
                        total_records: res?.data?.meta?.total_records,
                    },
                });
            })
            .catch(err =>
                console.error(
                    "Couldn't show run histories of the Dataflow",
                    err
                )
            );
    },

    getRunHistoryById: async (dataflowId, runId) => {
        return await axios
            .get(`dataflows/${dataflowId}/job/${runId}`)
            .then(res => res.data?.data)
            .catch(err =>
                console.error(
                    "Couldn't show run histories of the specific Dataflow",
                    err
                )
            );
    },

    // Dataflow Node Template Library
    getCustomNodeTemplates: async ({ page = 1, sort = null }) =>
        await axios.get('/dataflows/node-library', { params: { page, sort } }),

    addNewCustomNodeTemplate: async newCustomNodeTemplate =>
        await axios.post('dataflows/node-library', newCustomNodeTemplate),

    getCustomNodeTemplateById: async customNodeTemplateId =>
        await axios.get(`/dataflows/node-library/${customNodeTemplateId}`),

    getCustomNodeTemplateByVersionId: async (customNodeTemplateId, versionId) =>
        await axios.get(
            `dataflows/node-library/${customNodeTemplateId}/version/${versionId}`
        ),

    editCustomNodeTemplateDetails: async (templateId, { name, description }) =>
        await axios.put(`/dataflows/node-library/${templateId}`, {
            name,
            description,
        }),

    addNewVersionToTemplate: async (
        templateId,
        { name, description, config }
    ) =>
        await axios.post(`/dataflows/node-library/${templateId}`, {
            name,
            description,
            config,
        }),

    deleteCustomNodeTemplateById: async templateId =>
        await axios.delete(`dataflows/node-library/${templateId}`),
    codeDiff: [],
    setCodeDiff: diff => set({ codeDiff: diff || [] }),
    compareVersionsData: [],
    setCompareVersionData: compareVersionsData => set({ compareVersionsData }),
    compareDataflowVersion: async (
        dataflowId,
        sourceVersionId,
        destinationVersionId
    ) => {
        await axios
            .post(`dataflows/${dataflowId}/versions/compare/`, {
                source_version_id: sourceVersionId,
                destination_version_id: destinationVersionId,
            })
            .then(res => set({ compareVersionsData: res?.data?.data }))
            .catch(err => console.error(err));
    },
}));
export default useDFAStore;
