import { Descriptions } from 'antd';
import CommonAutoTriggerDetails from './CommonAutoTriggerDetails';

export default function SharedContentTriggerDetails({
    config,
    showSensorValues,
}) {
    const automatic = { ...config?.config?.automatic?.config };
    return (
        <CommonAutoTriggerDetails
            config={config}
            showSensorValues={showSensorValues}
        >
            <Descriptions.Item label="Content Path" span={12}>
                {automatic?.inbound_resource_path?.pretty_path}
            </Descriptions.Item>
            <Descriptions.Item label="Content Pattern" span={12}>
                {automatic?.content_name_pattern}
            </Descriptions.Item>
        </CommonAutoTriggerDetails>
    );
}
