import React from 'react';
import { Card, Table } from 'antd';

const columns = [
    {
        title: 'Previous Name',
        dataIndex: 'old',
        key: 'old',
    },
    {
        title: 'New Name',
        dataIndex: 'new',
        key: 'new',
    }
];

const cleanDataSource = config => {
    const configs = config?.rename_columns;
    if (!Array.isArray(configs)) return [];
    return configs?.reduce((acc, current) => {
        acc?.push({
            old: current?.old,
            new: current?.new,
        });
        return acc;
    }, []);
};

export default function ColumnRename({ config }) {
    return (
        <Card size="small">
            <Table
                bordered={true}
                style={{ width: '100%' }}
                dataSource={cleanDataSource(config)}
                columns={columns}
                size="small"
                pagination={{
                    hideOnSinglePage: true,
                }}
            />
        </Card>
    );
}
