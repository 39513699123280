import { Input } from 'antd';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { featureRoutesList } from '../../Routes';

function AutoCompleteComponent() {
    const navigate = useNavigate();
    const inputRef = useRef();
    const [value, setValue] = useState('');

    return (
        <Input.Search
            size="medium"
            placeholder="Search"
            ref={inputRef}
            value={value}
            onChange={e => setValue(e.target.value)}
            allowClear
            onSearch={value => {
                if (!value) return;
                setValue('');
                navigate(
                    `${featureRoutesList?.SEARCH}/content-search/?search=${value}`
                );
                inputRef?.current.blur();
            }}
        />
    );
}
export default AutoCompleteComponent;
