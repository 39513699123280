import React, { useEffect, useMemo } from 'react';
import { Descriptions, Divider } from 'antd';
import { renderKeyValue } from 'utils/utils';
import { removeUnderscore } from 'utils/utils';
import useDFAStore from 'store/dataflowAuth';
import useDataConnectionsStore from 'store/dataConnections';

export default function FTPSource({ config }) {
    let ftp = { ...config?.config?.ftp };
    ftp['file_path'] = ftp?.file_path?.map(files => {
        return files?.path;
    });

    const { dataConnections, setDataConnections } = useDFAStore(state => ({
        dataConnections: state.dataConnections,
        setDataConnections: state.setDataConnections,
    }));

    const getAllDataConnections = useDataConnectionsStore(
        state => state.getAllDataConnections
    );

    useEffect(() => {
        if (!dataConnections?.data?.data?.connection_list?.length) {
            (async () =>
                await getAllDataConnections(1, 100, ['FTP', 'SFTP'])?.then(
                    setDataConnections
                ))();
        }
    }, []);

    const dataConnectionName = useMemo(
        () =>
            dataConnections?.data?.data?.connection_list?.filter(
                connection => connection?._id === ftp['data_source']
            )?.[0]?.data_connection_name,
        [dataConnections?.data?.data?.connection_list?.length]
    );

    ['data_source']?.forEach(key => {
        delete ftp?.[key];
    });

    return (
        <>
            <Descriptions
                title=""
                bordered
                column={1}
                size={'small'}
                layout={'horizontal'}
            >
                <Descriptions.Item label="Data Source" span={24}>
                    {dataConnectionName}
                </Descriptions.Item>
                {Object.entries(ftp)?.map(entry => {
                    return (
                        <>
                            {!(entry?.[1] === 'object') && (
                                <Descriptions.Item
                                    label={
                                        <b>{removeUnderscore(entry?.[0])}</b>
                                    }
                                >
                                    {Array.isArray(entry?.[1]) ? (
                                        entry?.[1]?.map((val, index) => {
                                            return (
                                                <>
                                                    {typeof val === 'object' ? (
                                                        <>
                                                            {index > 0 ? (
                                                                <Divider />
                                                            ) : null}
                                                            {renderKeyValue(
                                                                val
                                                            )}
                                                        </>
                                                    ) : (
                                                        <ul
                                                            style={{
                                                                paddingInlineStart:
                                                                    '15px',
                                                            }}
                                                        >
                                                            <li>{val}</li>
                                                        </ul>
                                                    )}
                                                </>
                                            );
                                        })
                                    ) : typeof entry?.[1] === 'object' &&
                                      entry?.[1] !== null ? (
                                        <>
                                            {Object.entries(entry?.[1])?.map(
                                                obj => {
                                                    return (
                                                        <>
                                                            <b>{obj?.[0]}</b>:{' '}
                                                            {obj?.[1]}
                                                            <br></br>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </>
                                    ) : (
                                        <>{entry?.[1]}</>
                                    )}
                                </Descriptions.Item>
                            )}
                        </>
                    );
                })}
            </Descriptions>
        </>
    );
}
