import React, { useState } from 'react';
import {
    AppstoreOutlined,
    ControlOutlined,
    MessageOutlined,
} from '@ant-design/icons';
import { MdEngineering, MdOutlineQueryStats } from 'react-icons/md';
import { Button, Card, Dropdown, Row, Col, Space, theme } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserStore } from '../store/user';
const { useToken } = theme;

export default function AppStore() {
    const userRole = useUserStore(state => state.userRole);
    const release = useUserStore(state => state.release);
    const { userPersona, setUserPersona } = useUserStore(state => ({
        userPersona: state.userPersona,
        setUserPersona: state.setUserPersona,
    }));
    const { token } = useToken();
    const [open, setOpen] = useState(false);
    const currentButtonStyle = {
        color: token.colorPrimary,
        borderColor: token.colorPrimary,
    };
    const navigate = useNavigate();
    const location = useLocation();
    const inAdminPanel = !!location?.pathname?.match(/^\/admin/);

    const handleClick = e => {
        const persona = e.target.attributes['data-persona'].value;
        if (persona !== 'admin') {
            if (inAdminPanel) navigate('/');
            setUserPersona(persona);
        } else if (persona === 'admin') {
            navigate('/admin');
        }
        setOpen(false);
    };
    const items = [
        {
            key: '1',
            label: (
                <Button
                    style={{
                        height: '4em',
                        width: '8em',
                        ...(inAdminPanel ? currentButtonStyle : {}),
                    }}
                    data-persona="admin"
                    onClick={handleClick}
                    disabled={userRole !== 'admin'}
                    size="large"
                >
                    <Row
                        justify="center"
                        align="middle"
                        style={{ pointerEvents: 'none' }}
                    >
                        <Col>
                            <ControlOutlined style={{ fontSize: '2em' }} />
                        </Col>
                        <Col span={24}>Admin Panel</Col>
                    </Row>
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button
                    style={{
                        height: '4em',
                        width: '8em',
                        ...(!inAdminPanel && userPersona === 'default'
                            ? currentButtonStyle
                            : {}),
                    }}
                    data-persona="default"
                    onClick={handleClick}
                    size="large"
                >
                    <Row
                        justify="center"
                        align="middle"
                        style={{ pointerEvents: 'none' }}
                    >
                        <Col>
                            <MessageOutlined style={{ fontSize: '2em' }} />
                        </Col>
                        <Col span={24}>Virtual Assist</Col>
                    </Row>
                </Button>
            ),
        },
        ...(release?.PERSONAS?.TOOL_ENGINEER
            ? [
                  {
                      key: '3',
                      label: (
                          <Button
                              style={{
                                  height: '4em',
                                  width: '8em',
                                  ...(!inAdminPanel &&
                                  userPersona === 'tool_engineer'
                                      ? currentButtonStyle
                                      : {}),
                              }}
                              data-persona="tool_engineer"
                              onClick={handleClick}
                              size="large"
                          >
                              <Row
                                  justify="center"
                                  align="middle"
                                  style={{ pointerEvents: 'none' }}
                              >
                                  <Col>
                                      <MdEngineering
                                          style={{ fontSize: '2em' }}
                                      />
                                  </Col>
                                  <Col span={24}>Tool Engineer</Col>
                              </Row>
                          </Button>
                      ),
                  },
              ]
            : []),
        ...(release?.PERSONAS?.DATA_SCIENTIST
            ? [
                  {
                      key: '4',
                      label: (
                          <Button
                              style={{
                                  height: '4em',
                                  width: '8em',
                                  ...(!inAdminPanel &&
                                  userPersona === 'data_scientist'
                                      ? currentButtonStyle
                                      : {}),
                              }}
                              data-persona="data_scientist"
                              onClick={handleClick}
                              size="large"
                          >
                              <Row
                                  justify="center"
                                  align="middle"
                                  style={{ pointerEvents: 'none' }}
                              >
                                  <Col>
                                      <MdOutlineQueryStats
                                          style={{ fontSize: '2em' }}
                                      />
                                  </Col>
                                  <Col span={24}>Data Scientist</Col>
                              </Row>
                          </Button>
                      ),
                  },
              ]
            : []),
    ];
    return (
        <Dropdown
            open={open}
            onOpenChange={setOpen}
            menu={{
                items,
            }}
            dropdownRender={menu => (
                <Card
                    size="small"
                    title="Apps"
                    hoverable
                    style={{ maxWidth: '21em' }}
                >
                    <Space size={[8, 8]} wrap>
                        {[
                            menu.props.items.map(item => (
                                <React.Fragment key={item.key}>
                                    {item.label}
                                </React.Fragment>
                            )),
                        ]}
                    </Space>
                </Card>
            )}
        >
            <Button shape="circle" size="large" icon={<AppstoreOutlined />} />
        </Dropdown>
    );
}
