import { Descriptions } from 'antd';
import { useEffect, useMemo } from 'react';
import useDataConnectionsStore from 'store/dataConnections';
import useDFAStore from 'store/dataflowAuth';
import CommonAutoTriggerDetails from './CommonAutoTriggerDetails';

export default function FtpTriggerDetails({ config, showSensorValues }) {
    const automatic = { ...config?.config?.automatic?.config };
    const defaults = config?.params?.[0]?.default;

    const getAllDataConnections = useDataConnectionsStore(
        state => state.getAllDataConnections
    );
    const { setDataConnections, dataConnections } = useDFAStore(state => ({
        dataConnections: state.dataConnections,
        setDataConnections: state.setDataConnections,
    }));

    useEffect(() => {
        (() => {
            if (!dataConnections?.data?.data?.connection_list?.length) {
                getAllDataConnections().then(setDataConnections);
            }
        })();
    }, []);

    const dataConnectionName = useMemo(
        () =>
            dataConnections?.data?.data?.connection_list?.filter(
                connection => connection._id === automatic?.connection_id
            )?.[0]?.data_connection_name,
        [dataConnections?.data?.data?.connection_list?.length]
    );

    return (
        <CommonAutoTriggerDetails
            config={config}
            showSensorValues={showSensorValues}
        >
            <Descriptions.Item label={'Connection Type'} span={12}>
                {automatic?.connection_type}
            </Descriptions.Item>
            <Descriptions.Item label={'Connection Name'} span={12}>
                {dataConnectionName}
            </Descriptions.Item>
            <Descriptions.Item label={defaults?.[0]?.label} span={12}>
                {automatic?.[defaults?.[0]?.field]?.split('_')?.join(' ')}
            </Descriptions.Item>
            <Descriptions.Item label={defaults?.[1]?.label} span={12}>
                {automatic?.[defaults?.[1]?.field]}
            </Descriptions.Item>
            <Descriptions.Item label={defaults?.[2]?.label} span={12}>
                {automatic?.[defaults?.[2]?.field]}
            </Descriptions.Item>
            <Descriptions.Item label={defaults?.[3]?.label} span={12}>
                {Boolean(automatic?.[defaults?.[3]?.field])?.toString()}
            </Descriptions.Item>
        </CommonAutoTriggerDetails>
    );
}
