import { Layout, Col, Row, Space, Card } from 'antd';
import React from 'react';
import GlobalSearch from './GlobalSearch';
import SideMenu from './SideMenu';
import UserIcon from './UserIcon';
import AppStore from './AppStore';
import { useUserStore } from '../store/user';
import lavorroLogo from 'assets/img/Lavorro_Logo.png';
import NotificationsIcon from './NotificationsIcon';
import PageTitle from 'common/PageTitle';

const { Header, Content } = Layout;
export default function App({ children, getMenuItem }) {
    const { projectName, releaseVersion, releaseDate, release, userPersona } =
        useUserStore(state => ({
            projectName: state.projectName,
            releaseVersion: state.releaseVersion,
            releaseDate: state.releaseDate,
            release: state.release,
            userPersona: state.userPersona,
        }));
    return (
        <Layout>
            <Header
                className="header"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingInline: '2em',
                }}
            >
                <Row
                    justify="center"
                    align="middle"
                    style={{
                        maxWidth: '150px',
                        minWidth: 'min(150px, 25%)',
                    }}
                >
                    <img src={lavorroLogo} alt="logo" />
                    <Col
                        style={{
                            color: 'white',
                            lineHeight: 'normal',
                        }}
                    >
                        <Space
                            style={{
                                fontSize: '.75em',
                                gap: '.75em',
                            }}
                        >
                            {[projectName, releaseVersion, releaseDate]
                                .filter(value => !!value)
                                .map((value, index) => (
                                    <span key={index}>{value}</span>
                                ))}
                        </Space>
                    </Col>
                </Row>
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1em',
                    }}
                >
                    <GlobalSearch />
                    <AppStore />
                    <UserIcon />
                    {!!release?.NOTIFICATIONS &&
                        userPersona === 'tool_engineer' && (
                            <NotificationsIcon />
                        )}
                </span>
            </Header>
            <Layout style={{ height: '100vh', overflow: 'hidden' }}>
                <SideMenu getMenuItem={getMenuItem} />
                <Content
                    className="site-layout-background"
                    style={{
                        padding: '24px 24px 24px 0px',
                        margin: 0,
                        marginBottom: '5em',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                    }}
                >
                    <Card
                        id="page-title-parent"
                        style={{
                            height: 'fit-content',
                        }}
                        bodyStyle={{ minHeight: 'calc(100vh - 120px)' }}
                    >
                        <PageTitle />
                        {children}
                    </Card>
                </Content>
            </Layout>
        </Layout>
    );
}
