import create from 'zustand';
import axios from 'services/api';
import { message } from 'antd';
import { convertParamsIntoParamString } from 'utils/utils';

const initialStoreState = {
    isNoteModalOpen: false,
    isNotesViewer: false,
    isEdited: false,
    showConfirmModal: false,
    notesItem: [],
    notesInfo: [],
    content: '',
    htmlContent: '',
    title: '',
    _id: '',
    editor: false,
    page_no: 1,
    page: 1,
    limit: 10,
    totalItems: 0,
    createNewNotes: false,
    createdNoteInfo: {},
    noteCategories: [],
    titleChanged: false,
    isCreate: true,
    note: {},
    loading: false,
};

export const useNotesStore = create((set, get) => ({
    ...initialStoreState,
    resetStore: () => {
        set({
            ...initialStoreState,
        });
    },
    setNote: note => {
        set({ note });
    },
    setLoading: loading => {
        set({ loading });
    },
    setIsCreate: isCreate => {
        set({ isCreate });
    },
    setNoteCategories: noteCategories => {
        set({ noteCategories });
    },
    setTitleChanged: titleChanged => {
        set({ titleChanged });
    },
    setIsNoteModalOpen: isNoteModalOpen => {
        set({ isNoteModalOpen });
    },
    setCreateNewNotes: value => {
        set({ createNewNotes: value });
    },
    toggleEditor: () => {
        set(state => ({ editor: !state.editor }));
    },
    setNoteEditor: value => {
        set({ editor: value });
    },
    setViewerModal: value => {
        set({ isNotesViewer: value });
    },
    startEditing: () => {
        set({ isEdited: true });
    },
    notEditing: () => {
        set({ isEdited: false });
    },
    toggleConfirmModal: () => {
        set(state => ({ showConfirmModal: !state.showConfirmModal }));
    },
    setTitle: value => {
        set({ title: value });
    },
    setNoteId: value => {
        set({ _id: value });
    },
    setContent: value => {
        set({ content: value });
    },
    setNotesItem: value => {
        set({ notesItem: value });
    },
    setPage: async val => {
        set({
            page: val,
        });
        await get().notesList();
        get().setLoading(false);
    },

    notesList: async (
        search_query = '',
        page = get().page,
        limit = get().limit,
        sort = 'desc_created',
        created__gte = '',
        created__lte = '',
        updated__gte = '',
        updated__lte = ''
    ) => {
        let params = {
            search_query,
            sort,
            page,
            limit,

            created__gte,
            created__lte,
            updated__gte,
            updated__lte,
        };

        await axios
            .get(`/notes/${convertParamsIntoParamString({ ...params })}`)
            .then(res => {
                set({
                    notesItem: res.data.data ? res.data.data : [],
                    totalItems: res.data.meta.total_records,
                    page_no: 1,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message));
    },
    notesSearch: async val =>
        await axios
            .get(`/notes?search_query=${val}`)
            .then(res => {
                set({
                    page_no: 1,
                    notesItem: res.data.data ? res.data.data : [],
                    totalItems: res.data.meta.total_records,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    notesListWithPage: async () =>
        await axios
            .get(`/notes?page=${get().page_no + 1}`)
            .then(res => {
                set({
                    notesItem: get().notesItem.concat(res.data.data),
                    page_no: get().page_no + 1,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    getNotesHtml: async url =>
        await axios
            .get(url)
            .then(res => {
                set({
                    htmlContent: res.data,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    getNotesContent: async id =>
        await axios
            .get(`/notes/${id}`)
            .then(res => {
                set({
                    htmlContent: res?.data?.data?.content,
                    note: res?.data?.data,
                });
                localStorage.setItem('note_content', res?.data?.data?.content);
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    setNotesHtml: value => {
        set({ htmlContent: value });
    },
    createNote: async data =>
        await axios
            .post(`/notes/`, data)
            .then(res => {
                message.success('Notes created');

                set({ title: '', createdNoteInfo: res.data.data });
                get().notesList();
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    saveNote: async method => {
        let data = {
            content: localStorage.getItem('note_content'),
            title: get().title,
            categories: get().noteCategories,
        };

        if (method) {
            data['method'] = method;
        }

        await axios
            .put(`/notes/${get()._id}`, data)
            .then(res => {
                message.success('Notes saved');
                set({
                    title: '',
                    notesHtml: localStorage.getItem('note_content'),
                    note: res?.data?.data,
                });

                setTimeout(() => {
                    get().notesList();
                }, 1000);
            })
            .catch(err => console.error(err?.response?.data?.error_message));
    },
    deleteNote: async () =>
        await axios
            .delete(`/notes/${get()._id}/`)
            .then(res => {
                message.success('Notes deleted');
                get().notesList();
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
}));
