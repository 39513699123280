import React from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import rehypePrism from 'rehype-prism-plus';
import rehypeRewrite from 'rehype-rewrite';
import './style.css';
import { useThemeStore } from 'store/theme';
import { Typography } from 'antd';

const getEditorClassNames = (readOnly, prefix, suffix) => {
    const classes = [];
    if (readOnly) classes.push('read-only');
    if (!readOnly && prefix) classes.push('prefixed-code');
    if (!readOnly && suffix) classes.push('suffixed-code');
    if (!prefix && !suffix) classes.push('plain-code');

    return classes.join(' ');
};

export default function CustomCodeEditor({
    readOnly = false,
    value,
    placeholder,
    onChange,
    unknownKeywords = [],
    prefix = '',
    suffix = '',
    language = 'python',
    minHeight = 'fit-content',
    fontSize = 12,
    copyable = true,
}) {
    const theme = useThemeStore(state => state.theme);
    return (
        <div style={{ position: 'relative' }}>
            {copyable && (
                <Typography.Text
                    copyable={{
                        text: value,
                    }}
                    style={{
                        position: 'absolute',
                        right: 0,
                        paddingTop: '.75em',
                        paddingRight: '1.5em',
                        zIndex: 2,
                    }}
                />
            )}
            {!readOnly && prefix && (
                <CodeEditor
                    data-color-mode={theme}
                    className="prefix-code"
                    value={prefix}
                    language={language}
                    padding={0}
                    readOnly={true}
                    style={{
                        fontSize,
                    }}
                />
            )}
            <CodeEditor
                data-color-mode={theme}
                className={getEditorClassNames(readOnly, prefix, suffix)}
                value={value}
                language={language}
                padding={0}
                placeholder={placeholder}
                onChange={evn => {
                    onChange?.(evn.target.value);
                }}
                minHeight={minHeight}
                style={{
                    fontSize,
                }}
                readOnly={readOnly}
                rehypePlugins={[
                    [rehypePrism, { ignoreMissing: true }],
                    [
                        rehypeRewrite,
                        {
                            rewrite: (node, _, parent) => {
                                if (
                                    unknownKeywords.includes(
                                        node?.value?.trim()
                                    )
                                ) {
                                    parent.properties.className.push(
                                        'error-code'
                                    );
                                }
                            },
                        },
                    ],
                ]}
            />
            {!readOnly && suffix && (
                <CodeEditor
                    data-color-mode={theme}
                    className="suffix-code"
                    value={suffix}
                    language={language}
                    padding={0}
                    readOnly={true}
                    style={{
                        fontSize,
                    }}
                />
            )}
        </div>
    );
}
