import React from 'react';
import { Descriptions, Divider } from 'antd';
import { renderKeyValue } from 'utils/utils';

export default function FilePath({ config }) {
    let filePathConfig = { ...config?.file_path };

    return (
        <>
            <Descriptions
                title=""
                bordered
                column={1}
                size={'small'}
                layout={'horizontal'}
            >
                {Object.entries(filePathConfig)?.map(entry => {
                    return (
                        <>
                            {!(entry?.[1] === 'object') && (
                                <Descriptions.Item label={<b>{entry?.[0]}</b>}>
                                    {Array.isArray(entry?.[1]) ? (
                                        entry?.[1].map(val => {
                                            return (
                                                <>
                                                    {typeof val === 'object' ? (
                                                        <>
                                                            {renderKeyValue(
                                                                val
                                                            )}
                                                            <Divider />
                                                        </>
                                                    ) : (
                                                        <ul>
                                                            <li>{val}</li>
                                                        </ul>
                                                    )}
                                                </>
                                            );
                                        })
                                    ) : typeof entry?.[1] === 'object' &&
                                      entry?.[1] !== null ? (
                                        <></>
                                    ) : (
                                        <>{entry?.[1]}</>
                                    )}
                                </Descriptions.Item>
                            )}
                        </>
                    );
                })}
            </Descriptions>
        </>
    );
}
