import React from 'react';

import ExternalApi from './ExternalApi';
import DynamicContentDetails from './DynamicContentDetails';
import DynamicDestinationTable from './DynamicDestinationTable';

export default function DynamicDestination({ config }) {
    const subType = config?.params?.[0]?.sub_type;
    const configs = config?.config;

    switch (subType) {
        case 'external_api':
            return <ExternalApi config={configs} />;
        case 'content':
            return <DynamicContentDetails config={config} />;
        case 'data_table':
            return <DynamicDestinationTable config={config} />;
        default:
            return <></>;
    }
}
