import { lazy, useEffect, useState } from 'react';
import withAuth from 'components/Auth';
import { useRoutes } from 'react-router-dom';
import {
    DEFAULT_ROUTE,
    HOME_ROUTE,
    SEARCH_ROUTE,
    ESCALATE_ROUTE,
    HISTORY_ROUTE,
    HEALTH_CHECK_ROUTE,
    RESOURCES_ROUTE,
    UPLOAD_FILES_PATH,
    DASHBOARDS_PATH,
    EXPLORE_ROUTE,
    NOTES_PATH,
    GLOSSARY_PATH,
    MODULES_PATH,
    FAQ_PATH,
    GUIDED_SOP_PATH,
    DATA_FLOW_PATH,
    DATAFLOW_PATH,
    CONNECTIONS_PATH,
    DATA_TABLES_PATH,
    RECIPE_GEN_PATH,
    CATEGORY_MANAGEMENT_ROUTE,
    INBOUND_RESOURCES_PATH,
    TRASH_PATH,
    WORKBENCH_PATH,
} from './constants/RouteConstants';
import Home from './components/Home';
const BrowseContent = lazy(() => import('./components/BrowseContent'));
const FileUpload = lazy(() => import('./components/FileUpload'));
const History = lazy(() => import('./components/History'));
const Notes = lazy(() => import('./components/Notes'));
const Escalate = lazy(() => import('./components/Escalate'));
const Explore = lazy(() => import('./components/Explore'));
const HealthCheck = lazy(() => import('./components/HealthCheck'));
import Login from './components/Login';
import { useUserStore } from 'store/user';
import Jobs from 'components/DFA/Dataflow/Jobs';
import PriorRunDetails from 'components/DFA/Dataflow/Data/PriorRuns/PriorRunDetails';
import JobDetails from 'components/DFA/Dataflow/Jobs/JobDetails';
const NoteDetails = lazy(() => import('components/Notes/NoteDetails'));
const PageViewer = lazy(() => import('common/ContentViewer/PageViewer'));
const InlineDashboard = lazy(() =>
    import('components/AdvanceAnalytics/InlineDashboard')
);
const ModuleDetails = lazy(() =>
    import('components/ContentModule/ModuleDetails')
);
const DFA = lazy(() => import('./components/DFA'));
const Dataflow = lazy(() => import('./components/DFA/Dataflow'));
const UserManagement = lazy(() => import('./components/Admin/UserManagement'));
const NotFound = lazy(() => import('./components/NotFound'));
const Glossary = lazy(() => import('./components/Glossary'));
const ContentModule = lazy(() => import('./components/ContentModule'));
const FAQ = lazy(() => import('./components/FAQ'));
const Library = lazy(() => import('./components/Library'));
const SharedWithMe = lazy(() => import('./components/SharedWithMe'));
const TroubleshootAuthoring = lazy(() =>
    import('./components/TroubleshootAuthoring')
);
const TroubleshootWorkflow = lazy(() =>
    import('./components/TroubleshootAuthoring/Workflow')
);
const DataFlow = lazy(() => import('./components/DataFlow'));
const DataConnections = lazy(() => import('./components/DataConnections'));
const ViewDataConnection = lazy(() =>
    import('./components/DataConnections/ViewDataConnection')
);
const DataTables = lazy(() => import('./components/DataTables'));
const ViewDataTable = lazy(() =>
    import('./components/DataTables/ViewDataTable')
);
const Dashboards = lazy(() => import('./components/Admin/Dashboards'));
const CategoryAuthoring = lazy(() => import('./components/CategoryAuthoring'));
const Trash = lazy(() => import('./components/Trash'));
const Workbench = lazy(() => import('./components/Workbench'));
const WorkbenchAuthoring = lazy(() =>
    import('./components/Workbench/Authoring')
);
const NodeTemplateLibrary = lazy(() =>
    import('./components/DFA/NodeTemplates')
);

const CustomNodeTemplate = lazy(() =>
    import('./components/DFA/NodeTemplates/CustomNodeTemplate')
);

const routeMap = {
    HOME: {
        path: '/',
        element: withAuth(Home),
        children: [
            { index: true, element: withAuth(Home) },
            { path: DEFAULT_ROUTE, element: withAuth(Home) },
            { path: HOME_ROUTE, element: withAuth(Home) },
        ],
    },
    SEARCH: {
        path: SEARCH_ROUTE,
        element: withAuth(BrowseContent),
        children: [
            {
                path: ':tab',
                element: withAuth(BrowseContent),
                children: [
                    {
                        path: ':searchQuery',
                        element: withAuth(BrowseContent),
                    },
                ],
            },
        ],
    },
    ESCALATE: {
        path: ESCALATE_ROUTE,
        element: withAuth(Escalate),
    },
    UPLOAD_FILES: {
        path: UPLOAD_FILES_PATH,
        element: withAuth(FileUpload),
    },
    HEALTH_CHECK: {
        path: HEALTH_CHECK_ROUTE,
        element: withAuth(HealthCheck),
    },

    DASHBOARDS: {
        path: DASHBOARDS_PATH,
        children: [
            { index: true, element: withAuth(Dashboards) },
            {
                path: ':dashboardId',
                element: withAuth(InlineDashboard),
            },
        ],
    },
    EXPLORE: {
        path: EXPLORE_ROUTE,
        element: withAuth(Explore),
    },
    HISTORY: {
        path: HISTORY_ROUTE,
        element: withAuth(History),
    },
    NOTES: {
        path: NOTES_PATH,
        children: [
            { index: true, element: withAuth(Notes) },
            {
                path: ':noteId',
                element: withAuth(NoteDetails),
            },
        ],
    },
    GLOSSARY: {
        path: GLOSSARY_PATH,
        children: [
            { index: true, element: withAuth(Glossary) },
            {
                path: ':glossary',
                element: withAuth(Glossary),
                children: [
                    {
                        path: ':anchor',
                        element: withAuth(Glossary),
                    },
                ],
            },
        ],
    },
    MODULES: {
        path: MODULES_PATH,

        children: [
            { index: true, element: withAuth(ContentModule) },
            {
                path: ':moduleId',
                element: withAuth(ModuleDetails),
            },
            {
                path: ':moduleId/:contentId',
                element: withAuth(PageViewer),
            },
        ],
    },
    FAQ: {
        path: FAQ_PATH,
        children: [
            { index: true, element: withAuth(FAQ) },
            { path: ':type', element: withAuth(FAQ) },
        ],
    },
    GUIDED_SOP: {
        path: GUIDED_SOP_PATH,
        children: [
            {
                index: true,
                element: withAuth(TroubleshootAuthoring),
            },
            {
                path: ':scenarioId',
                element: withAuth(TroubleshootWorkflow),
            },
        ],
    },
    DATA_FLOW: {
        path: DATA_FLOW_PATH,
        children: [
            { index: true, element: withAuth(DataFlow) },
            {
                path: ':data-flow',
                element: withAuth(DataFlow),
            },
        ],
    },
    DATAFLOW: {
        path: DATAFLOW_PATH,
        children: [
            { index: true, element: withAuth(DFA) },
            {
                path: ':dataflowId',
                element: withAuth(Dataflow),
            },
            {
                path: 'jobs',
                element: withAuth(Jobs),
            },
            {
                path: 'jobs/:jobId',
                element: withAuth(JobDetails),
            },
            {
                path: 'node-templates',
                element: withAuth(NodeTemplateLibrary),
            },
            {
                path: 'node-templates/:customNodeTemplateId/:customNodeTemplateVersionId',
                element: withAuth(CustomNodeTemplate),
            },
        ],
    },
    RESOURCES: {
        path: RESOURCES_ROUTE,
        children: [
            { index: true, element: withAuth(Library) },
            {
                path: ':directory',
                element: withAuth(Library),
            },
        ],
    },
    INBOUND_RESOURCES: {
        path: INBOUND_RESOURCES_PATH,
        children: [
            { index: true, element: withAuth(SharedWithMe) },
            {
                path: `:connection`,
                element: withAuth(SharedWithMe),
            },
            {
                path: `:connection/:directory`,
                element: withAuth(SharedWithMe),
            },
        ],
    },
    CONNECTIONS: {
        path: CONNECTIONS_PATH,
        children: [
            { index: true, element: withAuth(DataConnections) },
            {
                path: ':dataConnectionId',
                element: withAuth(ViewDataConnection),
            },
        ],
    },
    DATA_TABLES: {
        path: DATA_TABLES_PATH,
        children: [
            { index: true, element: withAuth(DataTables) },
            {
                path: ':dataTableId',
                element: withAuth(ViewDataTable),
            },
        ],
    },
    CATEGORY_MANAGEMENT: {
        path: CATEGORY_MANAGEMENT_ROUTE,
        element: withAuth(CategoryAuthoring),
    },
    RECIPE_GEN: {
        path: RECIPE_GEN_PATH,
        children: [
            { index: true, element: withAuth(DataFlow) },
            {
                path: ':data-flow',
                element: withAuth(DataFlow),
            },
        ],
    },
    TRASH: {
        path: TRASH_PATH,
        children: [
            { index: true, element: withAuth(Trash) },
            {
                path: ':directory',
                element: withAuth(Trash),
            },
        ],
    },
    WORKBENCH: {
        path: WORKBENCH_PATH,
        children: [
            {
                index: true,
                element: withAuth(Workbench),
            },
            { path: ':workbenchId', element: withAuth(WorkbenchAuthoring) },
        ],
    },
};

const allFeatureRoutesList = {};

const getSubFeaturePath = (subFeature, feature) => {
    return routeMap[feature]?.path?.concat(routeMap[subFeature]?.path);
};

const getRoutes = featureFlags =>
    Object.keys(featureFlags)?.reduce((routesAcc, featureFlag) => {
        if (!routeMap[featureFlag]) return routesAcc;
        if (featureFlags[featureFlag] instanceof Object) {
            const subFeatureFlags = featureFlags[featureFlag];
            const subFeatureRoutes = Object.keys(subFeatureFlags).reduce(
                (subFeatureRoutesAcc, subFeatureFlag) => {
                    if (!subFeatureFlags[subFeatureFlag])
                        return subFeatureRoutesAcc;
                    const subFeaturePath = getSubFeaturePath(
                        subFeatureFlag,
                        featureFlag
                    );
                    allFeatureRoutesList[subFeatureFlag] = subFeaturePath;
                    return [
                        ...subFeatureRoutesAcc,
                        {
                            ...routeMap[subFeatureFlag],
                            path: subFeaturePath,
                        },
                    ];
                },
                []
            );
            allFeatureRoutesList[featureFlag] = routeMap[featureFlag].path;
            return [...routesAcc, ...subFeatureRoutes, routeMap[featureFlag]];
        }
        if (routeMap[featureFlag]) {
            allFeatureRoutesList[featureFlag] = routeMap[featureFlag].path;
            return [...routesAcc, routeMap[featureFlag]];
        }
        return routesAcc;
    }, []);

const commonRoutes = [
    {
        path: 'admin',
        children: [
            {
                index: true,
                element: withAuth(UserManagement),
            },
            {
                path: 'user-management',
                element: withAuth(UserManagement),
            },
        ],
    },
    {
        path: 'login',
        element: withAuth(Login),
    },
    {
        path: '*',
        element: withAuth(NotFound),
    },
];

export default function Routes() {
    const release = useUserStore(state => state.release);
    const [routes, setRoutes] = useState(commonRoutes);

    useEffect(() => {
        if (!Object.keys(release?.MENU || release || {}).length) {
            const featureFlags =
                JSON.parse(localStorage.getItem('release'))?.MENU ??
                JSON.parse(localStorage.getItem('release')) ??
                {};
            setRoutes([...getRoutes(featureFlags), ...commonRoutes]);
        } else {
            setRoutes([
                ...getRoutes(release?.MENU || release || {}),
                ...commonRoutes,
            ]);
        }
    }, [
        Object.keys(release?.MENU || release || {}).length,
        localStorage.getItem('release'),
    ]);
    return useRoutes(routes);
}

export const featureRoutesList = allFeatureRoutesList;
