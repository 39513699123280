import $ from 'jquery';
import { Tooltip, Typography, Tag, message } from 'antd';
const { Text } = Typography;
import {
    FilePdfOutlined,
    FileImageOutlined,
    VideoCameraOutlined,
    FileWordOutlined,
    BookOutlined,
    QuestionCircleOutlined,
    ApartmentOutlined,
    ToolOutlined,
    FormOutlined,
    AreaChartOutlined,
    LoadingOutlined,
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ClockCircleOutlined,
    CaretRightOutlined,
    SyncOutlined,
    FileUnknownOutlined,
    ApiOutlined,
    TableOutlined,
    FolderOutlined,
    FileTextOutlined,
    FileOutlined,
    BranchesOutlined,
} from '@ant-design/icons';
import Config from 'config/Config';
import fileDownload from 'js-file-download';

export let fileIcon = {
    pdf: (
        <Tooltip title="Pdf">
            <FilePdfOutlined />
        </Tooltip>
    ),
    image: (
        <Tooltip title="Image">
            <FileImageOutlined />
        </Tooltip>
    ),
    video: (
        <Tooltip title="Video">
            <VideoCameraOutlined />
        </Tooltip>
    ),
    docs: (
        <Tooltip title="Document">
            <FileWordOutlined />
        </Tooltip>
    ),
    glossary: (
        <Tooltip title="Glossary">
            <BookOutlined />
        </Tooltip>
    ),
    faq: (
        <Tooltip title="Faq">
            <QuestionCircleOutlined />
        </Tooltip>
    ),
    module: (
        <Tooltip title="Module">
            <ApartmentOutlined />
        </Tooltip>
    ),
    ts_scenario: (
        <Tooltip title="Guided SOP">
            <ToolOutlined />
        </Tooltip>
    ),
    notes: (
        <Tooltip title="Notes">
            <FormOutlined />
        </Tooltip>
    ),
    dashboard: (
        <Tooltip title="Dashboard">
            <AreaChartOutlined />
        </Tooltip>
    ),
    other: (
        <Tooltip title="Other">
            <FileUnknownOutlined />
        </Tooltip>
    ),
    modules: (
        <Tooltip title="Modules">
            <ApartmentOutlined />
        </Tooltip>
    ),
    data_connection: (
        <Tooltip title="Data Connection">
            <ApiOutlined />
        </Tooltip>
    ),
    data_table: (
        <Tooltip title="Data Table">
            <TableOutlined />
        </Tooltip>
    ),
    dataflow: (
        <Tooltip title="Dataflow">
            <BranchesOutlined style={{ rotate: '90deg' }} />
        </Tooltip>
    ),
};

export function getCurrentTime() {
    var t = new Date(),
        e = t.getHours(),
        i = t.getMinutes(),
        s = e >= 12 ? 'PM' : 'AM';
    e %= 12;
    e = e ? e : 12;
    i = i < 10 ? '0' + i : i;
    var n = e + ':' + i + ' ' + s;
    return n;
}

export function getScrollEvent() {
    if (
        $('.msg_container_base').scrollTop() +
            $('.msg_container_base').outerHeight() >=
        $('.msg_container_base')[0].scrollHeight
    ) {
        $('.scroll-to-read').addClass('hide');
    }
}

export function getFormattedDate(date) {
    return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        'T' +
        date.toLocaleTimeString().substring(0, 5)
    );
}

export function getUTCDateTime(localDate) {
    let year_month = localDate.split('-');
    let day_time = year_month[2].split('T');
    let hour_min = day_time[1].split(':');

    let yr = year_month[0];
    let mn = year_month[1];
    let dd = day_time[0];
    let hr = hour_min[0];
    let minutes = hour_min[1];

    //console.log(yr, mn, dd,"T",hr,":",minutes)

    let localDateTime = new Date(yr, mn - 1, dd, hr, minutes);

    let utc_yr = localDateTime.getUTCFullYear();
    let utc_mn = localDateTime.getUTCMonth() + 1;
    let utc_dd = localDateTime.getUTCDate();
    let utc_hr = localDateTime.getUTCHours();
    let utc_minutes = localDateTime.getUTCMinutes();

    if (utc_dd < 10) {
        utc_dd = '0' + utc_dd;
    }
    if (utc_mn < 10) {
        utc_mn = '0' + utc_mn;
    }

    if (utc_minutes < 10) {
        utc_minutes = '0' + utc_minutes;
    }

    if (utc_hr < 10) {
        utc_hr = '0' + utc_hr;
    }

    //console.log(localDateTime," ", utc_yr+"-"+utc_mn+"-"+utc_dd+"T"+utc_hr+":"+utc_minutes)

    return (
        utc_yr + '-' + utc_mn + '-' + utc_dd + 'T' + utc_hr + ':' + utc_minutes
    );
}

export function getSignedURL(url) {
    return `${url}?access-token=${localStorage.getItem('token')}`;
}

export function jobStatusOld(status) {
    if (status == 'INIT') {
        return (
            <Tooltip title="Initialised">
                <CaretRightOutlined className="margin_left" />
            </Tooltip>
        );
    } else if (status == 'QUEUED') {
        return (
            <Tooltip title="Queued">
                <Text type="warning">
                    <ClockCircleOutlined className="margin_left" />
                </Text>
            </Tooltip>
        );
    } else if (status == 'RUNNING') {
        return (
            <Tooltip title="Running">
                <Text type="success">
                    <LoadingOutlined className="margin_left" />
                </Text>
            </Tooltip>
        );
    } else if (status == 'STOPPED') {
        return (
            <Tooltip title="Stopped">
                <Text type="danger">
                    <ExclamationCircleOutlined className="margin_left" />
                </Text>
            </Tooltip>
        );
    } else if (status == 'COMPLETED') {
        return (
            <Tooltip title="Completed">
                <Text type="success">
                    <CheckCircleOutlined className="margin_left" />
                </Text>
            </Tooltip>
        );
    } else if (status == 'FAILED') {
        return (
            <Tooltip title="Failed">
                <Text type="danger">
                    <CloseCircleOutlined className="margin_left" />
                </Text>
            </Tooltip>
        );
    }
}

export function jobStatus(status) {
    if (status == 'INIT') {
        return (
            <Tag icon={<CaretRightOutlined />} color="default">
                INITIALIZED
            </Tag>
        );
    } else if (status == 'QUEUED') {
        return (
            <Tag icon={<ClockCircleOutlined />} color="warning">
                {status}
            </Tag>
        );
    } else if (
        status == 'RUNNING' ||
        status == 'PROCESSING' ||
        status === 'IN_PROGRESS'
    ) {
        return (
            <Tag icon={<SyncOutlined spin />} color="processing">
                {status}
            </Tag>
        );
    } else if (status == 'STOPPED') {
        return (
            <Tag icon={<ExclamationCircleOutlined />} color="error">
                {status}
            </Tag>
        );
    } else if (
        status == 'COMPLETED' ||
        status == 'CREATED' ||
        status == 'READY'
    ) {
        return (
            <Tag icon={<CheckCircleOutlined />} color="success">
                {status}
            </Tag>
        );
    } else if (status == 'FAILED' || status == 'ABORTED') {
        return (
            <Tag icon={<CloseCircleOutlined />} color="error">
                {status}
            </Tag>
        );
    }

    return <Tag icon={<ExclamationCircleOutlined />}>Unavailable</Tag>;
}

export function checkFeature(menu) {
    if (localStorage.getItem('release')) {
        if (
            menu.toLowerCase() == 'dashboards' ||
            menu.toLowerCase() == 'explore'
        ) {
            return (
                JSON.parse(localStorage.getItem('release'))['ANALYTICS']?.[
                    menu.toLocaleUpperCase()
                ] == true
            );
        }
        return (
            JSON.parse(localStorage.getItem('release'))[
                menu.replaceAll('-', '_').toLocaleUpperCase()
            ] == true
        );
    }
}

export function removeHTMLTags(htmlString) {
    if (!htmlString) return '';
    return htmlString.replace(/(<([^>]+)>)|(&nbsp;)/gi, '');
}

export function extractValuesByKeys(object, keys) {
    const resultantObject = {};

    function recursiveExtract(obj) {
        for (const key in obj) {
            if (keys.includes(key)) {
                resultantObject[key] ??= obj[key];
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                recursiveExtract(obj[key]);
            }
        }
    }

    recursiveExtract(object);

    keys.forEach(key => {
        if (!(key in resultantObject)) {
            resultantObject[key] = null;
        }
    });

    return resultantObject;
}

export function buildNestedList(list, idKey, parentKey) {
    function buildNestedListHelper(parentId = null) {
        const nestedList = [];
        list.forEach(item => {
            if (item[parentKey] == parentId) {
                const children = buildNestedListHelper(item[idKey]);
                nestedList.push({ ...item, children });
            }
        });
        return nestedList;
    }
    return buildNestedListHelper();
}

export const renderKeyValue = value => {
    return Object.entries(value).map(entry => {
        return (
            <p>
                <b>{entry[0]}</b>: {entry[1]}
            </p>
        );
    });
};

/* removeUnderscore: this_is_underscore ---> This Is Underscore */
export function removeUnderscore(str) {
    let frags = str.split('_');

    for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

// the current APIs don't support array parameters
export const convertParamsIntoParamString = filters => {
    const criteria = Object.keys(filters);
    const paramString = criteria.reduce((value, criterion) => {
        const filterValue = filters[criterion];
        if (!filterValue) return value;
        if (Array.isArray(filterValue)) {
            return [value, ...filterValue].join(`&${criterion}=`);
        } else {
            return [value, filterValue].join(`&${criterion}=`);
        }
    }, '?');
    return paramString || '';
};

export function deepConcatObjects(obj1, obj2) {
    if (obj1 == null || obj2 == null) {
        return obj1 || obj2;
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj2;
    }

    const result = Array.isArray(obj1) ? [] : {};
    for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            result[key] = deepConcatObjects(obj1[key], obj2[key]);
        }
    }

    for (const key in obj2) {
        if (obj2.hasOwnProperty(key) && !result.hasOwnProperty(key)) {
            result[key] = deepConcatObjects(obj1[key], obj2[key]);
        }
    }

    return result;
}
export const downloadFile = (filePath, fileName = '', notify = false) => {
    const downloadUrl = (
        Config?.API_URL === '/api'
            ? [document.location.origin, 'api', filePath]
            : [Config?.API_URL, filePath]
    ).join('/');

    if (notify) message.info('Download initiated');
    fetch(downloadUrl)
        .then(res => res.blob())
        .then(blob => {
            fileDownload(blob, fileName || filePath?.split('/').at(-1));
        })
        .then(() => notify && message.success('Download completed'))
        .catch(() => notify && message.error('Download failed'));
};

export const arePropsEqual = (prevProps, currentProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(currentProps);
};

export const trimTagCharacterReferences = a => {
    if (typeof a != 'string') return a;
    return trimTagCharacterReferencesHelper1(
        a.replaceAll(/&lt[;]?[a-z]+?\/&gt[;]?/g, '')
    );
};

const trimTagCharacterReferencesHelper1 = a => {
    const b = trimTagCharacterReferencesHelper2(a);
    if (b == a) return a;
    return trimTagCharacterReferencesHelper2(b);
};

const trimTagCharacterReferencesHelper2 = a => {
    return a.replaceAll(
        /&lt[;]?([a-z]+?)&gt[;]?(.*?)&lt[;]?\/\1&gt[;]?/g,
        '$2'
    );
};

export const copyText = async text => {
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
    } else {
        const textArea =
            copyText.textArea ||
            (copyText.textArea = document.createElement('textarea'));
        textArea.value = text;
        // textArea.style.visibility = 'hidden';
        textArea.style.position = 'absolute';
        textArea.style.left = '-999999px';
        document.body.prepend(textArea);
        textArea.select();
        document.execCommand('copy');
    }
};

export const MapIcon = ({ contentType }) => {
    const icons = {
        pdf: <FilePdfOutlined />,
        image: <FileImageOutlined />,
        video: <VideoCameraOutlined />,
        dashboard: <AreaChartOutlined />,
        other: <FileUnknownOutlined />,
        folder: <FolderOutlined />,
        file: <FileOutlined />,
        notes: <FileTextOutlined />,
        faq: <QuestionCircleOutlined />,
        module: <ApartmentOutlined />,
        glossary: <BookOutlined />,
        data_connection: <ApiOutlined />,
        data_table: <TableOutlined />,
        ts_scenario: <ToolOutlined />,
        dataflow: <BranchesOutlined style={{ rotate: '90deg' }} />,
    };
    return icons[contentType] || <></>;
};

export const typeText = type => {
    const mappedTypes = { ts_scenario: 'Guided SOP' };
    const acronymList = { pdf: 'PDF', faq: 'FAQ', ts: 'TS' };
    if (mappedTypes[type]) return mappedTypes[type];
    return type
        .split('_')
        ?.map(i => {
            if (acronymList[i]) return acronymList[i];
            return i.charAt(0)?.toUpperCase() + i.slice(1);
        })
        .join(' ');
};

export const convertSecondsToTimeString = timeInSeconds => {
    if (!timeInSeconds && timeInSeconds != 0) return timeInSeconds;
    timeInSeconds = Number(timeInSeconds);
    var h = Math.floor(timeInSeconds / 3600);
    var m = Math.floor((timeInSeconds % 3600) / 60);
    var s = Math.floor((timeInSeconds % 3600) % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
};
