import React from 'react';
import { Dropdown, Switch, Button } from 'antd';
import { useThemeStore } from '../store/theme';
import { useUserStore } from '../store/user';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function UserIcon() {
    const { toggleTheme } = useThemeStore(state => ({
        toggleTheme: state.toggleTheme,
    }));
    const name = useUserStore(state => state.name);
    const resetUserStore = useUserStore(state => state.resetUserStore);
    const navigate = useNavigate();
    function logOut() {
        resetUserStore();
        localStorage.clear();
        navigate('/login');
    }

    const items = [
        {
            key: 'logout',
            label: (
                <Button
                    icon={<LogoutOutlined />}
                    type="text"
                    size="large"
                    block={true}
                >
                    Logout
                </Button>
            ),
            onClick: logOut,
        },
        {
            key: 'toggleTheme',
            label: (
                <Button type="text" size="large" block={true}>
                    <Switch
                        checkedChildren="Light"
                        unCheckedChildren="Dark"
                        onChange={() => {
                            toggleTheme();
                        }}
                    />
                </Button>
            ),
        },
    ];
    return (
        <Dropdown menu={{ items }} placement="bottom">
            <Button
                shape="circle"
                size="large"
                type="primary"
                icon={
                    <>
                        {name
                            ?.split(' ')
                            ?.map(value => value?.at(0)?.toUpperCase())
                            .join('')}
                    </>
                }
            />
        </Dropdown>
    );
}
