import {
    FileDoneOutlined,
    FileOutlined,
    LineChartOutlined,
    SwapOutlined,
    BarChartOutlined,
    CaretRightOutlined,
    BranchesOutlined,
    AimOutlined,
} from '@ant-design/icons';
import React from 'react';
import { getBezierPath } from 'reactflow';
import TemplateNode from './TemplateNode';

const icons = {
    DATA_SOURCE: <FileOutlined />,
    DATA_TYPE: <div>#,#</div>,
    DATA_NORMALIZE: <LineChartOutlined />,
    DATA_TRANSFORM: <SwapOutlined />,
    CUSTOM_TRANSFORM: <SwapOutlined />,
    DATA_JOIN: (
        <div>
            <div className="circle"></div>
            <div className="circle circle-right"></div>
        </div>
    ),
    DATA_DESTINATION: <FileDoneOutlined />,
    DATA_INSPECT: <BarChartOutlined />,
    DATA_TRIGGER: <CaretRightOutlined />,
    DYNAMIC_SOURCE: <BranchesOutlined />,
    DYNAMIC_DESTINATION: <AimOutlined />,
};

export const DataSource = ({ data, id, dragging, selected }) => (
    <TemplateNode
        source={true}
        target={true}
        title={data?.name}
        id={id}
        dragging={dragging}
        nodeData={data}
    >
        {icons['DATA_SOURCE']}
    </TemplateNode>
);
export const DataType = ({ data, id, dragging, selected }) => (
    <TemplateNode
        target={true}
        source={true}
        title={data?.name}
        id={id}
        dragging={dragging}
        nodeData={data}
    >
        {icons['DATA_TYPE']}
    </TemplateNode>
);
export const DataNormalize = ({ data, id, dragging, selected }) => (
    <TemplateNode
        target={true}
        source={true}
        title={data?.name}
        id={id}
        dragging={dragging}
        nodeData={data}
    >
        {icons['DATA_NORMALIZE']}
    </TemplateNode>
);
export const DataTransform = ({ data, id, dragging, selected }) => (
    <TemplateNode
        target={true}
        source={true}
        title={data?.name}
        id={id}
        dragging={dragging}
        nodeData={data}
    >
        {icons['DATA_TRANSFORM']}
    </TemplateNode>
);
export const CustomTransform = ({ data, id, dragging, selected }) => (
    <TemplateNode
        target={true}
        source={true}
        title={data?.name}
        id={id}
        dragging={dragging}
        nodeData={data}
    >
        {icons['CUSTOM_TRANSFORM']}
    </TemplateNode>
);
export const DataJoin = ({ data, id, dragging, selected }) => (
    <TemplateNode
        target={true}
        source={true}
        title={data?.name}
        id={id}
        dragging={dragging}
        nodeData={data}
    >
        {icons['DATA_JOIN']}
    </TemplateNode>
);
export const DataDestination = ({ data, id, dragging }) => {
    return (
        <TemplateNode
            target={true}
            source={true}
            title={data?.name}
            id={id}
            dragging={dragging}
            nodeData={data}
        >
            {icons['DATA_DESTINATION']}
        </TemplateNode>
    );
};
export const DataInspect = ({ data, id, dragging }) => {
    return (
        <TemplateNode
            target={true}
            source={true}
            title={data?.name}
            id={id}
            dragging={dragging}
            nodeData={data}
        >
            {icons['DATA_INSPECT']}
        </TemplateNode>
    );
};

export const DataTrigger = ({ data, id, dragging }) => {
    return (
        <TemplateNode
            source={true}
            title={data?.name}
            id={id}
            dragging={dragging}
            nodeData={data}
        >
            {icons['DATA_TRIGGER']}
        </TemplateNode>
    );
};

export const DynamicSource = ({ data, id, dragging }) => {
    return (
        <TemplateNode
            target={true}
            source={true}
            title={data?.name}
            id={id}
            dragging={dragging}
            nodeData={data}
        >
            {icons['DYNAMIC_SOURCE']}
        </TemplateNode>
    );
};
export const DynamicDestination = ({ data, id, dragging }) => {
    return (
        <TemplateNode
            target={true}
            source={true}
            title={data?.name}
            id={id}
            dragging={dragging}
            nodeData={data}
        >
            {icons['DYNAMIC_DESTINATION']}
        </TemplateNode>
    );
};

export function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    markerEnd,
}) {
    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <text>
                <textPath
                    href={`#${id}`}
                    style={{ fontSize: 12 }}
                    startOffset="50%"
                    textAnchor="middle"
                >
                    {data?.text}
                </textPath>
            </text>
        </>
    );
}
