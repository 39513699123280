import React, { useState, useRef, useEffect } from 'react';
import {
    Card,
    Spin,
    Typography,
    Modal,
    Button,
    message,
    Space,
    Input,
    Popconfirm,
} from 'antd';
import useDFAStore from 'store/dataflowAuth';
import {
    ArrowsAltOutlined,
    DownloadOutlined,
    SearchOutlined,
} from '@ant-design/icons';

const WAIT_TIME = 500;

export default function useRunLogs(dataflowId) {
    const getRunLogs = useDFAStore(state => state.getRunLogs);
    const [logsData, setRunLogsData] = useState({});
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState({ dataflowId });
    const abortControllerRef = useRef(new AbortController());

    useEffect(async () => {
        if (!config.jobId || !config.jobNodeId) return setRunLogsData({});
        setLoading(true);
        setRunLogsData({});
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
        await getRunLogs(
            {
                ...config,
            },
            abortControllerRef.current
        )
            .then(res => {
                setRunLogsData(res?.data);
            })
            .catch(e => {
                setRunLogsData({});
            });
        setLoading(false);
    }, [config.jobId, config.jobNodeId, config.query]);

    const setRunLogsConfig = async ({ query, ...newConfig }) => {
        setConfig(prev => ({ ...prev, ...newConfig, query }));
    };

    const onLoadMore = async (loadAll = false) => {
        const meta = logsData.meta || {};
        if (loadAll) {
            const loadedLogs = await getRunLogs(
                {
                    dataflowId: config.dataflowId,
                    jobId: config.jobId,
                    jobNodeId: config.jobNodeId,
                },
                null,
                1,
                meta.total_records
            )
                .then(res => {
                    setRunLogsData(res?.data);
                    return res?.data?.data;
                })
                .catch(e => {
                    setRunLogsData({});
                });
            return loadedLogs;
        } else {
            setLoading(true);
            abortControllerRef.current.abort();
            abortControllerRef.current = new AbortController();
            const loadedLogs = await getRunLogs(
                { ...config },
                abortControllerRef.current,
                meta.page + 1
            )
                .then(res => {
                    setRunLogsData(prev => ({
                        data: [...prev.data, ...res?.data?.data],
                        meta: res?.data?.meta,
                    }));
                    return res?.data?.data;
                })
                .catch(e => {
                    setRunLogsData({});
                });
            setLoading(false);
            return loadedLogs;
        }
    };

    return [
        setRunLogsConfig,
        onLoadMore,
        logsData,
        { loading, config },
        RunLogs,
    ];
}

function RunLogs({
    data: nestedData,
    loading,
    loadMore,
    minified = false,
    setConfig,
    config,
}) {
    const data = nestedData?.data || [];
    const meta = nestedData?.meta || {};
    const [isModalOn, setIsModalOn] = useState(false);

    const downloadLogs = async format => {
        const completeLogs =
            meta.page == meta.total_pages ? data : await loadMore(true);
        if (format == 'text') {
            const logsInString = completeLogs
                .map(logFragment => logFragment.message)
                .join('\n');
            const element = document.createElement('a');
            const file = new Blob([logsInString], {
                type: 'text/plain',
            });
            element.href = URL.createObjectURL(file);
            element.download = `logs.txt`;
            document.body.appendChild(element);
            message.info('Download started');
            element.click();
        } else if (format == 'json') {
            const element = document.createElement('a');
            const file = new Blob([JSON.stringify(completeLogs)], {
                type: 'application/json',
            });

            element.href = URL.createObjectURL(file);
            element.download = `logs.json`;
            document.body.appendChild(element);
            message.info('Download started');
            element.click();
        }
    };

    const LogsCard = ({ renderedInModal = false }) => {
        const [searchText, setSearchText] = useState(config.query);
        const [debounceTimer, setDebounceTimer] = useState(null);

        useEffect(() => {
            return () => {
                if (debounceTimer) clearTimeout(debounceTimer);
            };
        }, []);

        return (
            <Spin
                spinning={loading}
                style={{ position: 'relative', top: '2rem' }}
            >
                <Card
                    size="small"
                    style={{
                        filter: 'invert(1)',
                        maxHeight: renderedInModal
                            ? 'calc(100vh - 250px)'
                            : 'calc(100vh - 450px)',
                        width: renderedInModal
                            ? 'calc(80vw - 48px)'
                            : 'initial',
                        overflow: 'scroll',
                    }}
                    bodyStyle={{
                        overflow: 'scroll',
                        paddingTop: '2rem',
                        position: 'relative',
                    }}
                >
                    {!!data.length && (
                        <Space
                            style={{
                                position: 'absolute',
                                top: 8,
                                right: 20,
                            }}
                        >
                            <Input
                                value={searchText}
                                onChange={e => {
                                    setSearchText(e.target.value);
                                    if (debounceTimer)
                                        clearTimeout(debounceTimer);
                                    const newTimer = setTimeout(() => {
                                        setConfig({ query: e.target.value });
                                        setDebounceTimer(null);
                                    }, WAIT_TIME);
                                    setDebounceTimer(newTimer);
                                }}
                                suffix={<SearchOutlined />}
                                placeholder="Type to search"
                            />
                            <Popconfirm
                                title="Select download format"
                                description=""
                                okText="JSON"
                                okButtonProps={{ type: 'default' }}
                                onConfirm={() => downloadLogs('json')}
                                cancelText="Text"
                                cancelButtonProps={{ type: 'default' }}
                                onCancel={() => downloadLogs('text')}
                            >
                                <Button icon={<DownloadOutlined />} />
                            </Popconfirm>
                            {!renderedInModal && (
                                <Button
                                    icon={<ArrowsAltOutlined />}
                                    onClick={() => {
                                        setIsModalOn(true);
                                    }}
                                />
                            )}
                        </Space>
                    )}

                    <pre
                        style={{
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                            background: 'inherit',
                        }}
                    >
                        <Typography.Paragraph
                            ellipsis={
                                minified && !renderedInModal
                                    ? {
                                          rows: 10,
                                          suffix: (
                                              <Typography.Link
                                                  onClick={() =>
                                                      setIsModalOn(true)
                                                  }
                                              >
                                                  &nbsp;Expand to view
                                              </Typography.Link>
                                          ),
                                          expandable: false,
                                      }
                                    : false
                            }
                        >
                            {data.length
                                ? data.map(logFragment => {
                                      return (
                                          <>
                                              {logFragment.message}
                                              <br />
                                          </>
                                      );
                                  })
                                : !loading && 'No logs available'}
                        </Typography.Paragraph>
                        {meta.page < meta.total_pages && (
                            <Typography.Link onClick={() => loadMore()}>
                                ...load more
                            </Typography.Link>
                        )}
                    </pre>
                </Card>
            </Spin>
        );
    };
    return (
        <>
            <LogsCard />
            <Modal
                open={isModalOn}
                width={'80vw'}
                centered
                onOk={() => setIsModalOn(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                closable={false}
            >
                <LogsCard renderedInModal />
            </Modal>
        </>
    );
}
