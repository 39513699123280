import { Card, Table } from 'antd';
import React from 'react';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
];

const cleanDataSource = config => {
    const configs = config?.config?.column_type_config;
    if (!Array.isArray(configs)) return [];
    return configs?.reduce((acc, current) => {
        acc?.push({
            name: current?.name,
            type: current?.type,
        });
        return acc;
    }, []);
};

export default function DataType({ config }) {
    return (
        <Card size="small">
            <Table
                bordered={true}
                style={{ width: '100%' }}
                dataSource={cleanDataSource(config)}
                columns={columns}
                size="small"
                pagination={{
                    hideOnSinglePage: true,
                }}
            />
        </Card>
    );
}
