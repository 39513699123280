import create from 'zustand';

export const useRouteStore = create(set => ({
    routeKey: '',
    regenerateRouteKey: () =>
        set({ routeKey: Math.random().toFixed(6).toString() }),
}));

export const useURLStore = create((set, get) => ({
    lastLoadedUrlParams: {},

    setLastLoadedUrlParams: search => {
        const urlParams = search
            .slice(search[0] == '?' ? 1 : 0)
            .split('&')
            .reduce((acc, param) => {
                const [key, value] = param.split('=');
                if (acc[key]) {
                    if (Array.isArray(acc[key])) {
                        return {
                            ...acc,
                            [key]: [...acc[key], value],
                        };
                    } else
                        return {
                            ...acc,
                            [key]: [acc[key], value],
                        };
                } else
                    return {
                        ...acc,
                        [key]: value,
                    };
            }, {});
        set({ lastLoadedUrlParams: urlParams });
    },
}));
