import React, { useEffect, useState } from 'react';
import { Typography, Breadcrumb, theme } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { featureRoutesList } from 'Routes';
import { useLibraryStore } from 'store/library';
import { useTrashStore } from 'store/trash';
import useDFAStore from 'store/dataflowAuth';
import useGeneralStore from 'store/general';
import { useTSAuthoringStore } from 'store/tsAuthoring';
import { useGlossaryStore } from 'store/glossary';
import * as routeConstants from 'constants/RouteConstants';
import { useDashboardStore } from 'store/dashboards';
import { useContentModuleStore } from 'store/contentModule';
import useContentViewerStore from 'store/contentViewer';
import { useViewedHistoryStore } from 'store/viewedHistory';
import HtmlParser from 'html-react-parser';
import { useNotesStore } from 'store/notes';
import { useWorkbenchStore } from 'store/workbench';

const findFeatureMatch = path => {
    if (path == '/' || path == '/home') return 'HOME';
    const featureKeys = Object.keys(featureRoutesList);
    let featureMatched = '';
    let featureMatchScore = 1;
    for (let i = 0; i < featureKeys.length; i++) {
        const feature = featureKeys[i];
        const featureRoute = featureRoutesList[feature];
        if (path.startsWith(featureRoute)) {
            if (featureRoute.length > featureMatchScore) {
                featureMatchScore = featureRoute.length;
                featureMatched = feature;
            }
        }
        if (path == featureRoutesList[featureMatched]) break;
    }
    // the following block of code is to match to a feature which does not exist in the featureRoutes fetched from lucy-be
    // the condition matching featureMatched with 'SEARCH' is a placeholder until bookmarks is decidedly placed somewhere
    if (!featureMatched || featureMatched == 'SEARCH') {
        const routeMatch = Object.keys({ ...routeConstants }).find(
            key => routeConstants[key] == path
        );
        if (routeMatch) {
            featureMatched = routeMatch.split('_').slice(0, -1).join('_');
        }
    }
    return featureMatched;
};

const featureToTitleMapping = {
    GUIDED_SOP: 'Troubleshooting',
    FAQ: 'FAQ',
    DATAFLOW: 'Dataflows',
};
const capitalizedTitle = feature => {
    if (featureToTitleMapping[feature]) return featureToTitleMapping[feature];
    return feature
        ?.split('_')
        ?.map(
            word => word[0]?.toUpperCase() + word?.substring(1)?.toLowerCase()
        )
        ?.join(' ');
};

const PageTitle = () => {
    const token = theme.useToken().token;
    const navigate = useNavigate();
    const { pathname = '', search = '', hash = '' } = useLocation();
    const { ancestors, sharedByConnection } = useLibraryStore(state => ({
        ancestors: state.ancestors,
        sharedByConnection: state.sharedByConnection,
    }));
    const { ancestors: trashAncestors } = useTrashStore(state => ({
        ancestors: state.ancestors,
    }));

    const { dataflowList, dataflow, dataflowjob, dataflowjobs } = useDFAStore(
        state => ({
            dataflowList: state.dataflowList,
            dataflow: state.dataflow,
            dataflowjob: state.dataflowjob,
            dataflowjobs: state.dataflowjobs,
        })
    );
    const note = useNotesStore(state => state.note);
    const { dashboard } = useDashboardStore(state => ({
        dashboard: state.dashboard,
    }));

    const { module, module_items_metadata } = useContentModuleStore(state => ({
        module: state.module,
        module_items_metadata: state.module_items_metadata,
    }));

    const { content } = useContentViewerStore(state => ({
        content: state.content,
    }));
    const scenario = useTSAuthoringStore(state => state.scenario);
    const glossaryCollection = useGlossaryStore(
        state => state.glossaryCollection
    );

    const workbench = useWorkbenchStore(state => state.workbench);

    const [feature, setFeature] = useState('');
    const [lastVisitedPage, setLastVisitedPage] = useState(1);
    // const [featureState, setFeatureState] = useState('');
    const { finalHierarchy, setFinalHierarchy } = useGeneralStore(state => ({
        finalHierarchy: state.finalHierarchy,
        setFinalHierarchy: state.setFinalHierarchy,
    }));
    const [titleSuffix, setTitleSuffix] = useState([]);

    useEffect(() => {
        setFeature('');
        const featureMatched = findFeatureMatch(pathname);
        if (featureMatched) {
            setFeature(featureMatched);
            setFinalHierarchy([
                {
                    label: capitalizedTitle(featureMatched),
                    routeTo: featureRoutesList[featureMatched],
                },
            ]);
        } else {
            setTimeout(() => {
                const featureMatched = findFeatureMatch(pathname);
                setFeature(featureMatched);
                if (featureMatched) {
                    setFinalHierarchy([
                        {
                            label: capitalizedTitle(featureMatched),
                            routeTo: featureRoutesList[featureMatched],
                        },
                    ]);
                } else {
                    setFinalHierarchy([]);
                }
            }, 1);
        }
    }, [pathname]);

    useEffect(() => {
        setTitleSuffix([]);
        const baseRoute = featureRoutesList[feature] || pathname;
        const urlParams = search
            .slice(1)
            .split('&')
            .reduce((acc, param) => {
                const [key, value] = param.split('=');
                return {
                    ...acc,
                    [key]: value,
                };
            }, {});
        const action = [
            'create',
            'register',
            'add',
            'edit',
            'data_table_name',
            'data_connection_name',
        ].find(i => urlParams[i]);

        if (action)
            setTitleSuffix([
                {
                    label:
                        action === 'data_table_name' ||
                        action === 'data_connection_name'
                            ? `${decodeURIComponent(
                                  urlParams[action].replace(/\+/g, ' ')
                              )}`
                            : `${
                                  action[0].toUpperCase() + action.slice(1)
                              } ${decodeURIComponent(
                                  urlParams[action].replace(/\+/g, ' ')
                              )}`,
                    routeTo: `${baseRoute}/${search}`,
                },
            ]);

        const connectionHierarchy = [];
        if (urlParams?.['data_connection_name'] !== undefined) {
            const searchParam = `data_connection_name=${
                urlParams['data_connection_name']
            }&preview=${urlParams['preview'] || false}`;
            urlParams['path'] =
                decodeURIComponent(urlParams?.['path'] || '') || undefined;
            connectionHierarchy.push(
                ...[
                    {
                        label: `${decodeURIComponent(
                            urlParams?.['data_connection_name']?.replace(
                                /\+/g,
                                ' '
                            )
                        )}`,
                        routeTo: `${pathname}?${searchParam}`,
                    },
                    ...(urlParams?.path?.split('/') || [])?.map(
                        (name, index, arr) => ({
                            label: name,
                            routeTo: `${pathname}?${searchParam}&path=${arr
                                .slice(0, index + 1)
                                .join('/')}`,
                        })
                    ),
                ]
            );
        }
        setTitleSuffix(connectionHierarchy);
    }, [search]);

    useEffect(() => {
        if (!feature) return;
        if (feature != 'DATAFLOW') return;
        const baseRoute = featureRoutesList[feature] || pathname;

        const extraParams = pathname
            .split('/')
            .slice(baseRoute.split('/').length);
        const newHierarchy = [
            {
                label: capitalizedTitle(feature),
                routeTo: baseRoute,
            },
        ];
        if (extraParams?.[0]) {
            if (extraParams[0] == 'jobs') {
                newHierarchy.push({
                    label: 'Dataflow Jobs',
                    routeTo: `${baseRoute}/jobs`,
                });

                if (extraParams?.[1]) {
                    newHierarchy.push({
                        label: HtmlParser(
                            dataflowjob?.run_name || dataflowjob?.job_name
                        ),
                        routeTo: `${baseRoute}/jobs/${dataflowjob?.job_id}`,
                    });
                }
            } else if (extraParams[0] == 'node-templates') {
                newHierarchy.push({
                    label: 'Node Template Library',
                    routeTo: `${baseRoute}/${extraParams[0]}`,
                });
                if (extraParams?.[1]) {
                    newHierarchy.push({
                        label: extraParams?.[1],
                        routeTo: `${baseRoute}/${extraParams?.[0]}/${extraParams?.[1]}/${extraParams?.[2]}`,
                    });
                }
            } else {
                newHierarchy.push({
                    label: dataflow.name,
                    routeTo: `${baseRoute}/${dataflow.dataflow_id}`,
                });
            }
        }
        setFinalHierarchy(newHierarchy);
    }, [
        JSON.stringify(dataflow),
        JSON.stringify(dataflowjob),
        dataflowjobs?.data?.length,
        dataflowList?.length,
        pathname,
        feature,
    ]);

    useEffect(() => {
        console.log('enteredhere', pathname, feature);

        if (feature != 'DASHBOARDS') return;
        const baseRoute = featureRoutesList[feature] || pathname;
        const extraParams = pathname
            .split('/')
            .slice(baseRoute.split('/').length);
        if (extraParams[0]) {
            setFinalHierarchy([
                {
                    label: capitalizedTitle(feature),
                    routeTo: baseRoute,
                },
                {
                    label: dashboard.title,
                    routeTo: `${baseRoute}/${dashboard.doc_id}`,
                },
            ]);
        }
    }, [JSON.stringify(dashboard)]);

    useEffect(() => {
        if (feature != 'NOTES') return;
        const baseRoute = featureRoutesList[feature] || pathname;
        const extraParams = pathname
            .split('/')
            .slice(baseRoute.split('/').length);
        if (extraParams[0]) {
            setFinalHierarchy([
                {
                    label: capitalizedTitle(feature),
                    routeTo: baseRoute,
                },
                {
                    label: note.title,
                    routeTo: `${baseRoute}/${note.doc_id}`,
                },
            ]);
        }
    }, [JSON.stringify(note)]);

    useEffect(() => {
        if (feature != 'MODULES') return;
        const baseRoute = featureRoutesList[feature] || pathname;
        const extraParams = pathname
            .split('/')
            .slice(baseRoute.split('/').length);
        if (extraParams[0]) {
            const hierarchy = [
                {
                    label: capitalizedTitle(feature),
                    routeTo: baseRoute,
                },
                {
                    label: module?.title,
                    routeTo: `${baseRoute}/${module?.doc_id}`,
                },
            ];
            if (
                extraParams.length === 2 &&
                Object.keys(content || {})?.length !== 0
            ) {
                hierarchy.push({
                    label: HtmlParser(
                        content?.title ||
                            content?.heading ||
                            content?.term ||
                            content?.question ||
                            ''
                    ),
                    routeTo: `${baseRoute}/${module?.doc_id}/${content?.doc_id}`,
                });
            }
            setFinalHierarchy(hierarchy);
        }
    }, [module, content]);

    useEffect(() => {
        if (!feature) return;
        const baseRoute = featureRoutesList[feature] || pathname;
        const extraParams = pathname
            .split('/')
            .slice(baseRoute.split('/').length);
        if (feature == 'RESOURCES' && extraParams[0]) {
            setFinalHierarchy([
                {
                    label: capitalizedTitle(feature),
                    routeTo: baseRoute,
                },
                ...(ancestors || [])?.slice(1)?.map(parent => ({
                    label: parent?.title,
                    routeTo: `${baseRoute}/${parent?.content_id}`,
                })),
            ]);
        }
        if (feature == 'TRASH' && extraParams[0]) {
            setFinalHierarchy([
                {
                    label: capitalizedTitle(feature),
                    routeTo: baseRoute,
                },
                ...(trashAncestors || [])?.slice(1)?.map(parent => ({
                    label: parent?.title,
                    routeTo: `${baseRoute}/${parent?.content_id}`,
                })),
            ]);
        }
        if (feature == 'INBOUND_RESOURCES' && extraParams[0]) {
            setFinalHierarchy([
                {
                    label: capitalizedTitle(feature),
                    routeTo: baseRoute,
                },
                {
                    label: sharedByConnection.data_connection_name,
                    routeTo: `${baseRoute}/${sharedByConnection._id}`,
                },
                ...(ancestors || [])?.map(parent => ({
                    label: parent.title,
                    routeTo: `${baseRoute}/${sharedByConnection._id}/${parent?.content_id}`,
                })),
            ]);
        }
    }, [
        JSON.stringify(ancestors),
        JSON.stringify(trashAncestors),
        JSON.stringify(sharedByConnection),
    ]);

    useEffect(() => {
        if (!feature) return;
        const baseRoute = featureRoutesList[feature] || pathname;
        const extraParams = pathname
            .split('/')
            .slice(baseRoute.split('/').length);
        if (feature == 'GUIDED_SOP' && extraParams[0]) {
            setFinalHierarchy([
                {
                    label: capitalizedTitle(feature),
                    routeTo: baseRoute,
                },
                {
                    label: scenario.data?.title,
                    routeTo: `${baseRoute}/${scenario.data?._id}`,
                },
            ]);
        }
    }, [JSON.stringify(scenario)]);

    useEffect(() => {
        if (!feature) return;
        const baseRoute = featureRoutesList[feature] || pathname;
        const extraParams = pathname
            .split('/')
            .slice(baseRoute.split('/').length);
        if (feature == 'GLOSSARY' && extraParams[0]) {
            setFinalHierarchy([
                {
                    label: capitalizedTitle(feature),
                    routeTo: baseRoute,
                },
                {
                    label: glossaryCollection.title,
                    routeTo: `${baseRoute}/${glossaryCollection.glossary_group_id}`,
                },
            ]);
        }
    }, [JSON.stringify(glossaryCollection)]);

    useEffect(() => {
        if (feature != 'WORKBENCH') return;
        const baseRoute = featureRoutesList[feature] || pathname;
        const extraParams = pathname
            .split('/')
            .slice(baseRoute.split('/').length);
        if (extraParams[0]) {
            setFinalHierarchy([
                {
                    label: capitalizedTitle(feature),
                    routeTo: baseRoute,
                },
                {
                    label: workbench.title,
                    routeTo: `${baseRoute}/${workbench.workbench_id}`,
                },
            ]);
        }
    }, [JSON.stringify(workbench)]);

    const breadcrumbHierarchy = [...finalHierarchy, ...titleSuffix];

    return (
        <div id="page-title-header">
            <Typography.Title
                level={3}
                style={{ margin: 0, maxWidth: '60vw' }}
                ellipsis={{
                    rows: 1,
                    tooltip: {
                        title: breadcrumbHierarchy.at(-1)?.label,
                    },
                }}
            >
                {breadcrumbHierarchy.at(-1)?.label}
            </Typography.Title>
            {breadcrumbHierarchy.length > 1 && (
                <Breadcrumb>
                    {breadcrumbHierarchy.map((node, index, arr) => (
                        <Breadcrumb.Item key={index}>
                            <Typography.Link
                                onClick={() => {
                                    navigate(node.routeTo);
                                }}
                                disabled={!arr[index + 1]}
                                style={{
                                    color: !arr[index + 1]
                                        ? token.colorTextBase
                                        : token.colorLink,
                                    maxWidth: '50ch',
                                }}
                                ellipsis={{ rows: 1 }}
                            >
                                {node.label}
                            </Typography.Link>
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
            )}
        </div>
    );
};

export default PageTitle;
