import { Button, theme, Descriptions, Space } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import Config from 'config/Config';
import React, { useEffect, useState } from 'react';
import { useLibraryStore } from 'store/library';

export default function FileName({ config }) {
    const values = config?.config;
    const defaults = config?.params?.[0];
    const getChildren = useLibraryStore(state => state?.getChildren);
    const [path, setPath] = useState('');

    useEffect(() => {
        (async () =>
            await getChildren(values?.parent_id, ['folder'])?.then(res =>
                setPath(
                    res?.data?.data?.hierarchy
                        ?.map(parent => parent?.title)
                        .join('/') + '/'
                )
            ))();
    }, [values?.[defaults?.[0]?.field]]);

    return (
        <Descriptions bordered column={1} size={'small'} layout={'horizontal'}>
            <Descriptions.Item label="Path">
                {!!path ? path : <LoadingOutlined />}
            </Descriptions.Item>
            <Descriptions.Item label={defaults?.name}>
                <Space>
                    {values?.[defaults?.label]}
                    {config?.output?.output_file_path && (
                        <a
                            rel="noreferrer"
                            href={
                                new URL(
                                    config?.output?.output_file_path,
                                    Config?.API_URL === '/api'
                                        ? document?.location?.origin
                                        : Config?.API_URL
                                )
                            }
                            target="_blank"
                        >
                            <Button
                                icon={<DownloadOutlined />}
                                type="text"
                                size="small"
                                style={{
                                    color: theme?.useToken()?.token?.colorLink,
                                }}
                            />
                        </a>
                    )}
                </Space>
            </Descriptions.Item>
            <Descriptions.Item label="Method">
                {values?.method}
            </Descriptions.Item>
        </Descriptions>
    );
}
