import create from 'zustand';
import { message } from 'antd';
import axios from 'services/api';
import { useUserStore } from './user';
import { createRef } from 'react';

const EXIT_CUSTOM_INTENT_WAIT_TIME = 5; // in minutes

const addTime = node => {
    if (!node.data.time) {
        node.data.time = new Date().toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
    }
    return node;
};

export const useChatStore = create((set, get) => ({
    isChatOpen: false,
    customIntent: '',
    customIntentLabel: '',
    customIntentDetails: {},
    lastUserAuthoredMessage: '',
    trailingMessageTimer: null,
    exitCustomIntentTimer: null,
    previousQuery: null,
    setPreviousQuery: query => set({ previousQuery: query }),
    previousResponse: null,
    setPreviousResponse: response => set({ previousResponse: response }),

    toggleChat: flag => {
        set(state => ({ isChatOpen: flag ?? !state.isChatOpen }));
        get().inputRef?.current?.focus();
    },

    onLogin: () =>
        setTimeout(() => {
            get().clearConversation();
            get().addLucyMessage(
                addTime({
                    data: {
                        intent: 'conversational',
                        chat_answer: `Welcome ${
                            useUserStore.getState().username
                        }, how may I assist you?`,
                        reply: [],
                        author: 'lucy',
                    },
                })
            );
            get().toggleChat();
        }, 1000),

    conversation: [
        addTime({
            data: {
                intent: 'conversational',
                chat_answer: `Welcome ${
                    useUserStore.getState().username
                }, how may I assist you?`,
                reply: [],
                author: 'lucy',
            },
        }),
    ],

    setCustomChatIntent: (newIntent = {}, newIntentDetails = {}) => {
        const {
            customIntent: prevValue,
            customIntentLabel: prevLabel,
            addInfoMessage,
            addLucyMessage,
            toggleChat,
            trailingMessageTimer,
            exitCustomIntentTimer,
            exitCustomChatIntent,
            previousQuery,
            previousResponse,
        } = get();
        const { label: newIntentlabel, value: newIntentValue } = newIntent;
        const {
            data_connection_id,
            data_table,
            code_assist_language,
            runtime_data_store,
            dataflow_id,
            dataflow_job_id,
            dataflow_node_id,
            data_packet_id,
            data_packet_name,
            additional_info,
        } = newIntentDetails;
        if (trailingMessageTimer) clearTimeout(trailingMessageTimer);
        if (exitCustomIntentTimer) clearTimeout(exitCustomIntentTimer);
        set({
            customIntent: newIntentValue || '',
            customIntentLabel: newIntentValue && newIntentlabel,
            trailingMessageTimer: null,
            exitCustomIntentTimer: setTimeout(
                exitCustomChatIntent,
                EXIT_CUSTOM_INTENT_WAIT_TIME * 60 * 1000
            ),
        });
        toggleChat(true);
        if (newIntentValue != prevValue) {
            if (prevValue) {
                addInfoMessage(`<b>${prevLabel}</b> has been disabled.`);
            }
            if (newIntentValue) {
                addInfoMessage(
                    `You have enabled <b>${newIntentlabel}</b> intent. You can disable this by changing the selection in the dropdown above.`
                );
            }
        }
        if (newIntentValue == 'code_assist') {
            set({
                customIntentDetails: {
                    code_assist_language: code_assist_language,
                },
            });
        } else if (newIntentValue == 'data_table_chat') {
            if (data_table) {
                set({
                    customIntentDetails: {
                        data_table_details: [data_table],
                        data_connection_id: data_connection_id,
                    },
                });
                addLucyMessage(
                    addTime({
                        data: {
                            intent: newIntentValue,
                            connectionId: data_connection_id,
                            chat_answer: `You have selected “${data_table.data_table_name}” as your subject content. Please mention your query.`,
                            reply: [],
                            author: 'lucy',
                        },
                    })
                );
            } else {
                addLucyMessage(
                    addTime({
                        data: {
                            intent: newIntentValue,
                            chat_answer: '',
                            reply: [],
                            author: 'lucy',
                        },
                    })
                );
            }
        } else if (newIntentValue === 'output_dps_chat') {
            set({
                customIntentDetails: {
                    runtime_data_store,
                    dataflow_id,
                    dataflow_job_id,
                    dataflow_node_id,
                    data_packet_id,
                    data_packet_name,
                },
            });
            addLucyMessage(
                addTime({
                    data: {
                        intent: newIntentValue,
                        chat_answer: `You have selected “${data_packet_name}” ${additional_info} as your subject content. Please mention your query.`,
                        reply: [],
                        author: 'lucy',
                    },
                })
            );
        } else if (newIntentValue === 'image_interpreter') {
            console.log(newIntentDetails);
            set({
                customIntentDetails: {
                    content_id: newIntentDetails?.content_id,
                    context_image_file_name:
                        newIntentDetails?.context_image_file_name || null,
                    previous_query: null,
                    previous_response: null,
                },
            });
            addInfoMessage(
                `You are analysing the <b>${newIntentDetails?.title}</b>.`
            );
        }
    },

    setCustomIntentDetails: data => set({ customIntentDetails: data }),

    exitCustomChatIntent: () => {
        const { customIntentLabel: prevLabel, addInfoMessage } = get();
        set({
            customIntent: '',
            customIntentLabel: '',
            customIntentDetails: {},
        });
        addInfoMessage(
            `Due to inactivity for a long time, <b>${prevLabel}</b> has been disabled.`
        );
    },

    addMessage: messageNode => {
        const {
            trailingMessageTimer,
            exitCustomIntentTimer,
            exitCustomChatIntent,
            customIntent,
        } = get();
        if (trailingMessageTimer) clearTimeout(trailingMessageTimer);
        if (exitCustomIntentTimer) clearTimeout(exitCustomIntentTimer);
        set(state => ({
            conversation: [...state.conversation, addTime(messageNode)],
            lastUserAuthoredMessage: messageNode.data.reply.data,
            trailingMessageTimer: null,
            exitCustomIntentTimer: customIntent
                ? setTimeout(
                      exitCustomChatIntent,
                      EXIT_CUSTOM_INTENT_WAIT_TIME * 60 * 1000
                  )
                : null,
        }));
    },

    addInfoMessage: message =>
        set(state => ({
            conversation: [
                ...state.conversation,
                addTime({
                    data: {
                        message,
                    },
                }),
            ],
        })),

    addLucyMessage: messageNode =>
        set(state => ({
            conversation: [...state.conversation, addTime(messageNode)],
        })),

    clearConversation: () => set({ conversation: [] }),

    getResponse: async (query, expected_intent, query_id) => {
        const typingResponse = {
            data: {
                intent: 'typing',
                author: 'lucy',
            },
        };
        set(state => ({
            conversation: [...state.conversation, typingResponse],
        }));
        const { customIntent, customIntentDetails, addLucyMessage } = get();

        await axios
            .post('/lucy/chat-response/', {
                query,
                username: localStorage.getItem('username'),
                expected_intent: expected_intent || customIntent,
                expected_intent_details: customIntentDetails,
                query_id,
            })
            .then(res => {
                res.data.data.author = 'lucy';

                set(state => ({
                    conversation: [
                        ...state.conversation.slice(0, -1),
                        {
                            ...addTime(res.data),
                            state: {
                                customIntentDetails: state.customIntentDetails,
                            },
                        },
                    ],
                }));
                get().getChatHistory();
                if (customIntent == 'data_table_chat') {
                    const newTimer = setTimeout(() => {
                        addLucyMessage(
                            addTime({
                                data: {
                                    intent: 'data_table_chat',
                                    confirmIntentDetails: true,
                                    connectionId:
                                        customIntentDetails.data_connection_id,
                                    chat_answer:
                                        'If you have any more questions or need further details, feel free to ask! If you want to change the scope of your search then select one of the options below.',
                                    author: 'lucy',
                                },
                            })
                        );
                    }, 5000);
                    set({ trailingMessageTimer: newTimer });
                } else if (customIntent == 'output_dps_chat') {
                    const newTimer = setTimeout(() => {
                        addLucyMessage(
                            addTime({
                                data: {
                                    intent: 'output_dps_chat',
                                    chat_answer:
                                        'If you have any more questions or need further details, feel free to ask! Or if you want to change the intent of this conversation you can do so by changing the selection in the dropdown above.',
                                    author: 'lucy',
                                },
                            })
                        );
                    }, 5000);
                    set({ trailingMessageTimer: newTimer });
                } else if (customIntent == 'image_interpreter') {
                    set({
                        customIntentDetails: {
                            ...customIntentDetails,
                            previous_query: query,
                            previous_response: res?.data?.data?.chat_answer,
                        },
                    });
                }
            })
            .catch(err => {
                message.error("Can't reach Lucy at the moment");
                set(state => ({
                    conversation: [...state.conversation.slice(0, -1)],
                }));
                console.error(err);
            });
    },

    recordUserIntent: async (query_id, entity) =>
        await axios
            .post('/lucy/intent-feedback/', {
                query_id,
                expected_entity: entity,
            })
            .catch(err => message.error("Can't reach Lucy at the moment.")),

    chatHistory: {},
    getChatHistory: async abortController =>
        await axios
            .get(
                `virtual-assist/query-history/${
                    useUserStore.getState().userId
                }/?page=1&limit=10&sort=desc_created`,
                {
                    signal: abortController?.signal,
                }
            )
            .then(res => set({ chatHistory: res.data }))
            .catch(err => {
                if (!useUserStore.getState().userId) return;
                if (err?.message !== 'canceled') {
                    message.error("Couldn't fetch chat history");
                }
                console.error(err);
            }),
    inputRef: createRef(),
}));
