import React from 'react';

import CustomTransform from './CustomTransform';
import DataMerge from './DataMerge';
import DataConcat from './DataConcat';
import RowFilters from './RowFilters';
import RowSort from './RowSort';
import RowFillNa from './RowFillNa';
import RowDropDuplicates from './RowDropDuplicates';
import ColumnRename from './ColumnRename';
import DataResampling from './DataResampling';
import ColumnFilterAndSort from './ColumnFilterAndSort';
import ColumnsFillNa from './ColumnsFillNa';
import ColumnAddUpdate from './ColumnAddUpdate';
import ColumnFindAndReplace from './ColumnFindAndReplace';
import Split from './Split';

export default function DataTransform({ config }) {
    const subType = config?.params?.[0]?.sub_type;
    const configs = config?.config;
    switch (subType) {
        case 'custom':
        case 'lavorro_transform':
            return <CustomTransform config={config} />;
        case 'merge':
            return <DataMerge config={configs} />;
        case 'concat':
            return <DataConcat config={configs} />;
        case 'row_filter':
            return <RowFilters config={configs} />;
        case 'row_sort':
            return <RowSort config={configs} />;
        case 'row_fill_na':
            return <RowFillNa config={configs} />;
        case 'column_fill_na':
            return <ColumnsFillNa config={configs} />;
        case 'row_drop_dup':
            return <RowDropDuplicates config={configs} />;
        case 'rename_columns':
            return <ColumnRename config={configs} />;
        case 'data_resampling':
            return <DataResampling config={configs} />;
        case 'filter_columns':
            return <ColumnFilterAndSort config={configs} />;
        case 'add_n_update_columns':
            return <ColumnAddUpdate config={configs} />;
        case 'find_n_replace_columns':
            return <ColumnFindAndReplace config={configs} />;
        case 'split':
            return <Split config={configs} />;
        default:
            return <></>;
    }
}
