import create from 'zustand';
import axios from 'services/api';
import { convertParamsIntoParamString } from 'utils/utils';
import useDataConnectionsStore from './dataConnections';
// import useDataConnectionsStore from './dataConnections';

export const FIXED_LIMIT = 20;
export const INITIAL_LOAD_PAGECOUNT = 3;

export const useLibraryStore = create((set, get) => ({
    contents: [],
    ancestors: [],
    limit: 0,
    page: 0,
    total: 0,
    contentTypes: {},
    filters: {},
    sharedByConnection: {},
    errorMessage: '',
    contentId: '',
    selectedId: '',

    setContentId: contentId => {
        set({ contentId });
    },

    setSelectedId: selectedId => {
        set({ selectedId });
    },

    resetLibrary: () => {
        set({
            contents: [],
            ancestors: [],
            sharedByConnection: {},
        });
    },

    getContents: async (
        contentId = '',
        filters = {},
        shared_connection_id,
        returnResponse = false
    ) =>
        await axios
            .get(
                `/library/${
                    filters.query ? 'search' : 'explore-contents/' + contentId
                }${convertParamsIntoParamString({
                    page: 1,
                    limit: INITIAL_LOAD_PAGECOUNT * FIXED_LIMIT,
                    shared_connection_id,
                    ...filters,
                })}`
            )
            .then(res =>
                returnResponse
                    ? res
                    : set({
                          contents: res.data.data.contents || res.data.data,
                          contentTypes: res.data.data.content_types,
                          ancestors: res.data.data.hierarchy,
                          page: INITIAL_LOAD_PAGECOUNT,
                          limit: FIXED_LIMIT,
                          total: res.data.meta?.total_records,
                          filters: filters,
                      })
            )
            .catch(err => {
                set({ contents: [] });
                console.error(err?.response?.data?.error_message || err);
            }),

    setContents: contents => {
        set({ contents });
    },

    setErrorMessage: errorMessage => {
        set({ errorMessage });
    },

    setSharedByConnection: async connectionId => {
        if (get().sharedByConnection._id == connectionId) return;
        const { dataConnections, getDataConnectionDetails } =
            useDataConnectionsStore.getState();
        let connectionDetails = dataConnections.find(
            item => item._id == connectionId
        );
        if (!connectionDetails) {
            connectionDetails = await getDataConnectionDetails(connectionId);
        }
        set({ sharedByConnection: connectionDetails });
    },

    loadMoreContent: async (contentId = '', pageRequested) => {
        const filters = get().filters;
        await axios
            .get(
                `/library/${
                    filters.query ? 'search' : 'explore-contents/' + contentId
                }${convertParamsIntoParamString({
                    page: pageRequested,
                    limit: FIXED_LIMIT,
                    ...filters,
                })}`
            )
            .then(res => {
                set(state => ({
                    contents: [
                        ...state.contents,
                        ...(res.data.data.contents || res.data.data),
                    ],
                    page: res.data.meta.page,
                }));
            })
            .catch(err =>
                console.error(err?.response?.data?.error_message || err)
            );
    },

    shareContent: async (content_id, connection_ids) =>
        await axios.post('/library/share', {
            content_id,
            connection_ids,
        }),

    getSharedContents: async (
        shared_connection_id,
        contentId = '',
        filters = {}
    ) =>
        await axios
            .get(
                `/shared-resources/connection/${shared_connection_id}/${
                    contentId ? 'content/' + contentId : ''
                }${convertParamsIntoParamString({
                    page: 1,
                    limit: INITIAL_LOAD_PAGECOUNT * FIXED_LIMIT,
                    ...filters,
                })}`
            )
            .then(res => {
                set({
                    contents: res.data.data.contents,
                    contentTypes: res.data.data.content_types,
                    ancestors: res.data.data.hierarchy,
                    page: INITIAL_LOAD_PAGECOUNT,
                    limit: FIXED_LIMIT,
                    total: res.data.meta.total_records,
                });
                return res;
            })
            .catch(err => {
                set({ contents: [] });
                console.error(err?.response?.data?.error_message || err);
            }),

    loadMoreSharedContent: async (
        shared_connection_id,
        contentId = '',
        filters = {},
        pageRequested
    ) => {
        await axios
            .get(
                `/shared-resources/connection/${shared_connection_id}/${
                    contentId ? 'content/' + contentId : ''
                }${convertParamsIntoParamString({
                    page: pageRequested,
                    limit: FIXED_LIMIT,
                    ...filters,
                })}`
            )
            .then(res => {
                set(state => ({
                    contents: [...state.contents, ...res.data.data.contents],
                    page: res.data.meta.page,
                }));
                return res;
            })
            .catch(err =>
                console.error(err?.response?.data?.error_message || err)
            );
    },

    addFolder: async (folderName, contentId) =>
        await axios
            .post('/library/explore-contents/', {
                parent_id: contentId,
                title: folderName,
            })
            .then(() => {
                get().getContents(get().ancestors?.at(-1)?.content_id);
                set({ errorMessage: '' });
            })
            .catch(err => {
                console.log(err?.response?.data?.error_message || err);
            }),

    editDetails: async (contentId, payload) =>
        await axios
            .patch(`/library/${contentId}`, payload)
            .then(() => {
                get().getContents(get().ancestors?.at(-1)?.content_id);
                set({ errorMessage: '' });
            })
            .catch(err => {
                console.log(err?.response?.data?.error_message || err);
            }),

    moveToTrash: async contentId =>
        await axios
            .delete(`/library/explore-contents/${contentId}`)
            .then(() => get().getContents(get().ancestors?.at(-1)?.content_id)),

    moveContents: async (contentId, parentId, method) =>
        await axios
            .put(
                `/library/explore-contents/${contentId}?${
                    method ? `method=${method}` : ''
                }`,
                {
                    parent_id: parentId,
                }
            )
            .then(() => {})
            .catch(err => {
                return err?.response?.data?.error_message || err;
            }),

    checkTitle: async (folderName, contentId) =>
        await axios
            .get(
                `/library/check-title?title=${folderName}&parent_id=${contentId}`
            )
            .then(response => {
                return response?.data?.data?.is_taken;
            })
            .catch(err => {
                console.log(err?.response?.data?.error_message || err);
            }),

    getChildren: async (contentId = '', contentTypes = []) =>
        await axios
            .get(
                `/library/explore-contents/${contentId}?${contentTypes.reduce(
                    (acc, current) => acc + '&content_type=' + current,
                    ''
                )}`
            )
            .catch(err =>
                console.error(err?.response?.data?.error_message || err)
            ),

    getLimitedChildren: async ({
        contentId = '',
        contentTypes = [],
        page = 1,
        limit = INITIAL_LOAD_PAGECOUNT * FIXED_LIMIT,
        sortBy,
        customEndpoint,
        customUrlParams,
    }) =>
        await axios
            .get(
                `${
                    customEndpoint
                        ? customEndpoint
                        : '/library/explore-contents/' + contentId
                }?page=${page}&limit=${limit}${sortBy ? '&sort=' + sortBy : ''}
                ${contentTypes.reduce(
                    (acc, current) => acc + '&content_type=' + current,
                    ''
                )}
                ${
                    customUrlParams
                        ? Object.keys(customUrlParams || {}).reduce(
                              (acc, current) =>
                                  acc +
                                  '&' +
                                  current +
                                  '=' +
                                  customUrlParams[current],
                              ''
                          )
                        : ''
                }`
            )
            .catch(err =>
                console.error(err?.response?.data?.error_message || err)
            ),

    searchLibrary: async (
        query,
        contentTypes = [],
        page = 1,
        limit = INITIAL_LOAD_PAGECOUNT * FIXED_LIMIT,
        sortBy = ''
    ) =>
        await axios
            .get(
                `/library/search?query=${query}&page=${page}&limit=${limit}${
                    sortBy ? '&sort=' + sortBy : ''
                }
                ${contentTypes.reduce(
                    (acc, current) => acc + '&content_type=' + current,
                    ''
                )}`
            )
            .catch(err =>
                console.error(err?.response?.data?.error_message || err)
            ),

    addCategories: async data =>
        await axios
            .post('/category/content-categories-mapping/', data)
            .catch(err =>
                console.error(err?.response?.data?.error_message || err)
            ),

    removeCategories: async data =>
        await axios
            .put('/category/content-categories-mapping/', data)
            .catch(err =>
                console.error(err?.response?.data?.error_message || err)
            ),
}));
