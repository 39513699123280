import create from 'zustand';
import axios from 'services/api';
import { convertParamsIntoParamString } from 'utils/utils';

const initialViewStoreState = {
    registeredDataTables: [],
    currentDataTable: null,
    pagination: {
        current: 1,
        pageSize: 15,
        total: 0,
    },
};

const initialManageStoreState = {
    dataTablesCompact: [],
    dataTableDetails: null,
    openTablesForm: false,
    tablesFormType: 'create',
    dataTypes: [],
    analysisTypes: [],
};

const useDataTablesStore = create((set, get) => ({
    ...initialViewStoreState,

    ...initialManageStoreState,

    setCurrentDataTable: dataTable => {
        set({ currentDataTable: dataTable });
    },

    setOpenTablesForm: openTablesForm => {
        set({ openTablesForm: openTablesForm });
    },

    setTablesFormType: tablesFormType => {
        set({ tablesFormType: tablesFormType });
    },

    getRegisteredDataTables: async (
        search_query = '',
        page = get()?.pagination?.current,
        limit = get()?.pagination?.pageSize,
        sort = 'desc_created',
        created__gte = '',
        created__lte = '',
        updated__gte = '',
        updated__lte = ''
    ) => {
        let params = {
            search_query,
            sort,
            page,
            limit,

            created__gte,
            created__lte,
            updated__gte,
            updated__lte,
        };
        const response = await axios
            .get(
                `/data-connections/table/${convertParamsIntoParamString({
                    ...params,
                })}`
            )
            .then(res => {
                if (Array.isArray(res?.data?.data?.data_tables_list)) {
                    set({
                        registeredDataTables:
                            res?.data?.data?.data_tables_list ?? [],
                        pagination: {
                            ...get()?.pagination,
                            current: page,
                            pageSize: limit,
                            total: res?.data?.meta?.total_records ?? 0,
                        },
                    });
                }
                return res;
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },

    getDataTablesCompact: async data_connection_id => {
        const response = await axios
            .get(
                `/data-connections/connection-table-form-info/?to_retrieve=data_tables&data_connection_id=${data_connection_id}`
            )
            .then(res => {
                if (Array.isArray(res?.data?.data?.data_tables)) {
                    set({ dataTablesCompact: res.data.data.data_tables });
                } else {
                    set({ dataTablesCompact: [] });
                }
                return res;
            })
            .catch(err => {
                set({ dataTablesCompact: [] });
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },

    getDataTableDetails: async (data_table_name, data_connection_id) => {
        const response = await axios
            .get(
                `/data-connections/table/?data_table_name=${data_table_name}&data_connection_id=${data_connection_id}`
            )
            .then(res => {
                set({ dataTableDetails: res?.data?.data || null });
                return res;
            })
            .catch(err => {
                set({ dataTableDetails: null });
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },

    getDataTableView: async ({ tableId, payload }) =>
        axios
            .post(`data-connections/table/${tableId}`, payload)
            .then(res => res)
            .catch(err => err),

    getDataTypes: async data_connection_id => {
        const response = await axios
            .get(
                `/data-connections/connection-table-form-info/?to_retrieve=data_types&data_connection_id=${data_connection_id}`
            )
            .then(res => {
                set({ dataTypes: res?.data?.data?.data_types || [] });
                return res;
            })
            .catch(err => {
                set({ dataTypes: [] });
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },

    getAnalysisTypes: async () => {
        const response = await axios
            .get(
                `/data-connections/connection-table-form-info/?to_retrieve=analysis_type`
            )
            .then(res => {
                set({ analysisTypes: res?.data?.data?.data_types || [] });
                return res;
            })
            .catch(err => {
                set({ analysisTypes: [] });
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },

    deleteDataTable: async dataTableId => {
        const response = await axios
            .delete(`/data-connections/table/${dataTableId}`)
            .then(res => res)
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },

    manageDataTable: async payload => {
        const response = await axios
            .post(`/data-connections/table/`, payload)
            .then(res => res)
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
        return response;
    },

    resetViewTableStoreState: () => {
        set({ ...initialViewStoreState });
    },

    resetManageTableStore: () => {
        set({ ...initialManageStoreState });
    },
}));

export default useDataTablesStore;
